import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ModalPrivate({ show, onHide, onClick }) {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('event.private_user_modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('event.private_user_modal.message')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={onClick}>
          {t('buttons.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
