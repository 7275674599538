import { useCallback, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface PDFViewerProps {
  file: File
  width?: number
  height?: number
}

const PDFViewer = ({ file, width, height }: PDFViewerProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const onLoadSuccess = useCallback(() => {
    setIsLoaded(true)
  }, [isLoaded])

  const onLoadError = useCallback(() => {
    setIsLoaded(false)
  }, [isLoaded])

  if (!file) {
    return null
  }
  return (
    <>
      <Document
        loading="Loading PDF..."
        file={file!}
        onLoadSuccess={onLoadSuccess}
        onLoadError={onLoadError}
      >
        <Page pageNumber={1} width={width} height={height} />
      </Document>
      {!isLoaded && <div>PDF: {file.name} </div>}
    </>
  )
}

export default PDFViewer
