import civ from '../constants/civ'

export const onlyNumbers = string => +string?.replace(/\D/g, '')

export const formatAmount = (number, currency = false) =>
  Intl.NumberFormat(
    'en-US',
    currency && {
      style: 'currency',
      currency: 'USD',
      minimunFractionDigits: 2,
    },
  ).format(number)

export const numK = number =>
  number > 1000 ? `${Math.floor(number / 100) / 10}k` : number

export const feeValue = (number, fee) => {
  if (!fee) {
    return undefined
  }
  return fee.fee_kind === civ.fee_kind.percentage
    ? parseFloat((parseInt(number, 10) * (fee.value / 100)).toFixed(2))
    : fee.value
}

export const feeKind = (fee, hideEmpty = false) => {
  if (!fee) {
    return undefined
  }
  if (hideEmpty && (fee.fee_kind === civ.fee_kind.flat || fee.value === 0)) {
    return ''
  }
  return fee.fee_kind === civ.fee_kind.percentage
    ? `${fee.value}%`
    : formatAmount(fee.value, true)
}

export default {
  onlyNumbers,
  formatAmount,
  numK,
  feeValue,
  feeKind,
}
