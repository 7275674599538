import { InputGroup, Button } from 'react-bootstrap'
import React, { useState } from 'react'

import Icon from './icon'
import Styles from './password-input.module.scss'
import TextInput from './TextInput/TextInput'

interface PasswordInputProps {
  autoComplete?: string
  // className?: string
  id?: string
  isInvalid?: boolean
  name?: string
  onBlur?: () => void
  onChange?: () => void
  onFocus?: () => void
  placeholder?: string
  showPassword?: boolean
  showPasswordToggle?: boolean
  validationErrorContainerStyle?: React.CSSProperties
  value?: string
  autofocus?: boolean
}

export default function PasswordInput(props: PasswordInputProps) {
  const {
    autoComplete = 'new-password',
    // className,
    id,
    isInvalid,
    name = '',
    onBlur,
    onChange,
    onFocus,
    placeholder = '',
    showPassword = false,
    showPasswordToggle = true,
    validationErrorContainerStyle,
    value,
    autofocus,
  } = props

  const [showPasswordState, setShowPasswordState] = useState(showPassword)

  const handleShowPasswordToggle = () => {
    setShowPasswordState(!showPasswordState)
  }

  return (
    <>
      <InputGroup
        className={`${Styles.inputGroup}
                    ${isInvalid ? Styles.inputGroupInvalid : ''}`}
      >
        <TextInput
          autoFocus={autofocus}
          autoComplete={autoComplete}
          // className={className}
          hideValidationMessage
          id={id}
          isInvalid={isInvalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          type={showPasswordState ? 'text' : 'password'}
          value={value}
          inputContainerStyle={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            flexGrow: 1,
            width: 0,
            zIndex: 2,
          }}
          inputStyle={{
            borderBottomRightRadius: 0,
            borderRight: 0,
            borderTopRightRadius: 0,
            flexGrow: 1,
          }}
        />
        {showPasswordToggle && (
          <Button
            className={`${Styles.visibilityToggle}
                        ${isInvalid ? Styles.visibilityToggleInvalid : ''}`}
            onClick={handleShowPasswordToggle}
            variant="outline-secondary"
          >
            {showPasswordState ? (
              <Icon name="eye" />
            ) : (
              <Icon name="eye-slash" />
            )}
          </Button>
        )}
      </InputGroup>

      {!!isInvalid ? (
        <div
          className="invalid-feedback d-block"
          style={validationErrorContainerStyle}
        >
          {isInvalid}
        </div>
      ) : null}
    </>
  )
}
