import PropTypes from 'prop-types'

export default function VolunteerPill({ count, invalid = false, label }) {
  return (
    <span
      className={`volunteer-pill 
        ${count > 0 ? '' : 'empty'} 
        ${invalid ? 'invalid' : ''}
      `}
    >
      {count ?? 0} {label}
    </span>
  )
}

VolunteerPill.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
}
