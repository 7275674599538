import { useCallback, useEffect, useState } from 'react'

import useApi from './useApi'

export default function UseSettings() {
  const { getSettingPledgeExpirationDays } = useApi()

  const [pledgeExpirationDays, setPledgeExpirationDays] = useState<Number>()

  const fetchPledgeExpirationDays = useCallback(async () => {
    const { data } = await getSettingPledgeExpirationDays()
    setPledgeExpirationDays(data.civ_pledge_expiration_days)
    return data.civ_pledge_expiration_days
  }, [getSettingPledgeExpirationDays])

  const refresh = useCallback(async () => {
    await fetchPledgeExpirationDays()
  }, [fetchPledgeExpirationDays])

  useEffect(() => {
    refresh()
  }, [])

  return {
    pledgeExpirationDays,
    fetchPledgeExpirationDays,
    refresh,
  }
}
