import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../hooks/useCurrentUser'

const MAX_SHIFTS = 20

export default function ShiftRow({
  event,
  shift,
  task,
  tasks,
  shiftIndex,
  updateShiftProp,
  setTasks,
  createShift,
}) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()
  const [dates, setDates] = useState()

  const activeShifts = task?.shifts?.filter(shift => !shift._destroy) || []

  const validateStartDate = date => {
    let result = date
    // Verify the date portion
    if (
      moment(date).isBefore(
        moment(event?.event_start_date)
          .hour(moment(event?.event_start_date).hour())
          .minute(moment(event?.event_start_date).minute()),
      )
    ) {
      result = moment(event?.event_start_date)
    } else {
      // Verify the time portion
      if (
        moment(date).isBefore(
          moment(date)
            .hour(moment(event?.event_start_date).hour())
            .minute(moment(event?.event_start_date).minute()),
        )
      ) {
        result = moment(date)
          .hour(moment(event?.event_start_date).hour())
          .minute(moment(event?.event_start_date).minute())
          .toDate()
      }
    }
    return result
  }

  const deleteShift = React.useCallback(
    (taskPosition, shiftIndex) => {
      const newTasks = [...tasks]
      const index = newTasks.findIndex(task => task.position === taskPosition)
      const shift = newTasks[index].shifts[shiftIndex]
      newTasks[index].shifts.splice(shiftIndex, 1)
      newTasks[index].shifts = [
        ...newTasks[index].shifts,
        { ...shift, _destroy: true },
      ]
      setTasks(newTasks)
    },
    [event, tasks, setTasks],
  )

  const addShift = useCallback(
    (taskPosition, shiftIndex) => {
      const newTasks = [...tasks]
      const index = newTasks.findIndex(task => task.position === taskPosition)
      newTasks[index].shifts.splice(shiftIndex + 1, 0, createShift())
      setTasks(newTasks)
    },
    [event, tasks, setTasks],
  )

  useEffect(() => {
    const _shiftStart = moment(shift?.start_time)
    // const _shiftEnd = moment(shift?.end_time)
    const _eventStart = moment(event?.event_start_date)
    const _eventEnd = moment(event?.event_end_date)

    // Start - based on the range of the event only
    const minDateStart = _eventStart.toDate()
    const minTimeStart = _eventStart.toDate()
    const maxDateStart = _eventEnd.toDate()
    const maxTimeStart = _eventEnd.toDate()
    // End - Limit to the same day,
    // starting from shift start to end of the event time
    const minDateEnd = _shiftStart.toDate()
    const minTimeEnd = _shiftStart.toDate()
    const maxDateEnd = _shiftStart.toDate()
    const maxTimeEnd = _eventEnd.toDate()

    setDates({
      maxDateEnd,
      maxDateStart,
      maxTimeEnd,
      maxTimeStart,
      minDateEnd,
      minDateStart,
      minTimeEnd,
      minTimeStart,
    })
  }, [event, shift])

  return (
    <div className="shift col-12">
      <div className="row g-0 values">
        <div className="col-1" />
        <ReactDatePicker
          autoComplete="off"
          dateFormat={t('config.datetime_picker')}
          timeFormat="h:mm aa"
          timeCaption={t('event.time')}
          name="start_time"
          locale={locale}
          maxDate={dates?.maxDateStart}
          maxTime={dates?.maxTimeStart}
          minDate={dates?.minDateStart}
          minTime={dates?.minTimeStart}
          shouldCloseOnSelect
          selected={
            shift?.start_time ? moment(shift?.start_time).toDate() : null
          }
          showTimeSelect
          wrapperClassName="col-sub-3"
          className={`form-control shift-start ${
            shift?.errors?.includes('start_time') ? 'invalid' : ''
          }`}
          onChange={_date => {
            if (_date) {
              const date = validateStartDate(_date)

              updateShiftProp(
                task,
                shift,
                'start_time',
                moment(date).format('M/D/YYYY h:mm A'),
              )

              const endTime = moment(date)
                .hour(moment(event?.event_end_date).hour())
                .minute(moment(event?.event_end_date).minute())
                .format('M/D/YYYY h:mm A')

              updateShiftProp(task, shift, 'end_time', endTime)
            }
          }}
        />
        <ReactDatePicker
          autoComplete="off"
          dateFormat={t('config.datetime_picker')}
          timeFormat="h:mm aa"
          timeCaption={t('event.time')}
          name="end_time"
          locale={locale}
          maxDate={dates?.maxDateEnd}
          maxTime={dates?.maxTimeEnd}
          minDate={dates?.minDateEnd}
          minTime={dates?.minTimeEnd}
          shouldCloseOnSelect
          selected={shift?.end_time ? moment(shift?.end_time).toDate() : null}
          showTimeSelect
          wrapperClassName="col-sub-3"
          className={`form-control shift-end ${
            shift?.errors?.includes('end_time') ? 'invalid' : ''
          }`}
          onChange={date => {
            if (date) {
              if (
                moment(date).isSameOrBefore(moment(shift?.start_time)) ||
                moment(date).isAfter(moment(event?.event_end_date))
              ) {
                return
              }

              updateShiftProp(
                task,
                shift,
                'end_time',
                moment(date).format('M/D/YYYY h:mm A'),
              )
            }
          }}
        />
        <input
          className={`form-control shift-volunteers col-1 ${
            shift?.errors?.includes('volunteers') ? 'invalid' : ''
          }`}
          min={1}
          name="shift-volunteers"
          type="number"
          value={shift?.volunteers_needed}
          onChange={e => {
            updateShiftProp(task, shift, 'volunteers_needed', e.target.value)
          }}
        />
        <div className="shift-actions col-2 form-control">
          {activeShifts.length > 1 ? (
            <Button
              variant="link"
              onClick={() => {
                deleteShift(task?.position, shiftIndex)
              }}
            >
              {t('event.delete')}
            </Button>
          ) : (
            <div />
          )}
          {activeShifts.length < MAX_SHIFTS ? (
            <Button
              variant="link"
              onClick={() => {
                addShift(task?.position, shiftIndex)
              }}
            >
              {t('event.add')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
