import React from 'react'
import { Card, Col } from 'react-bootstrap'

export default function StatBox({ label, content }) {
  return (
    <Col md="6" lg="3">
      <Card>
        <Card.Body className="text-center">
          <Card.Header>{label}</Card.Header>
          <Card.Text className="mt-4 mb-3">{content}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}
