import { Alert } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import PasswordInput from '../../components/password-input'
import useApi from '../../hooks/useApi'
import useFacebook from '../../hooks/useFacebook'

interface LoginProps {
  isModal?: boolean
  onClose: () => void
}

export default function LoginForm(props: LoginProps) {
  const { login: loginWithFacebook } = useFacebook()
  const { locale, signInWithEmail } = useCurrentUser()
  const { signInWithFacebookResponse } = useApi()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [validationError, setValidationError] = useState('')

  const handleSubmit = useCallback(
    async (event: any) => {
      setIsLoading(true)
      event.preventDefault()
      const formData = new FormData(event.currentTarget)
      const response = await signInWithEmail({
        email: formData.get('email'),
        locale,
        password: formData.get('password'),
      })
      if (response.errors) {
        setIsLoading(false)
        setValidationError(t('devise.sessions.invalid_credentials'))
      } else {
        if (!!props?.isModal) {
          props.onClose()
        } else {
          const from =
            location.state?.from?.pathname ||
            new URLSearchParams(location.search).get('redirect_to') ||
            '/home'
          setTimeout(() => {
            navigate(from, { replace: true })
            setIsLoading(false)
          }, 500)
        }
      }
    },
    [location, navigate, signInWithEmail, t],
  )

  const handleFacebookLogin = useCallback(async () => {
    const fbResponse = await loginWithFacebook(locale)
    if (fbResponse) {
      signInWithFacebookResponse(fbResponse)
        .then(() => {
          const from = location.state?.from?.pathname || '/home'
          setTimeout(() => {
            window.location = from
          }, 500)
        })
        .catch(error => {
          console.log('signInWithFacebookResponse error', error)
        })
    }
  }, [loginWithFacebook, signInWithFacebookResponse, navigate, location])

  return (
    <>
      <h3>{t('devise.sessions.transform_your_community')}</h3>
      <h4>{t('devise.sessions.login')}</h4>
      <form onSubmit={handleSubmit}>
        {!!validationError?.length && (
          <Alert
            dismissible
            onClose={() => setValidationError('')}
            show
            variant="danger"
          >
            {validationError}
          </Alert>
        )}
        <div className="form-group">
          <input
            autoComplete="email"
            autoFocus
            className="form-control"
            name="email"
            placeholder={t('devise.sessions.email')}
            type="text"
          />
        </div>

        <div className="form-group">
          {/* @ts-ignore */}
          <PasswordInput
            autoComplete="current-password"
            name="password"
            placeholder={t('devise.sessions.password')}
          />
        </div>

        <button
          className="btn btn-primary btn-block spaced"
          disabled={isLoading}
          type="submit"
        >
          {t('devise.sessions.login')}
        </button>

        <div className="centered spaced">
          <p>
            {t('devise.sessions.no_account')}{' '}
            <Link className="cr-link" to={`/signup?locale=${locale}`}>
              {t('devise.sessions.create_account')}
            </Link>
            <br />
            <Link to={`/forgot-password?locale=${locale}`}>
              {t('devise.sessions.forgot')}
            </Link>
          </p>
          <input
            className="btn btn-primary btn-block spaced"
            disabled={isLoading}
            onClick={handleFacebookLogin}
            type="button"
            value={t('devise.sessions.fb_sign_in')}
          />
        </div>
      </form>
    </>
  )
}
