import 'moment/locale/es'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { isVideo } from '../../lib/files'

export default function GroupEventFeed({ event }) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()
  const startMoment = moment(event?.event_start_date).tz(moment.tz.guess())
  const endMoment = moment(event?.event_end_date).tz(moment.tz.guess())
  const todayMoment = moment()
    .hour(startMoment.hour())
    .minute(startMoment.minute())
    .second(0)
  const status =
    startMoment.diff(moment()) < 0 && todayMoment.diff(moment()) < 0
      ? t('feed.in_progress')
      : startMoment.fromNow()

  // start date or range dates
  const dates =
    startMoment.dayOfYear() === endMoment.dayOfYear()
      ? `${startMoment.format(t('config.date_format'))} · ${startMoment.format(
          t('config.time_format'),
        )}`
      : `${t('feed.my_groups.dates_to', {
          start: startMoment.format(t('config.date_format')),
          end: endMoment.format(t('config.short_date_day_format')),
        })} · ${startMoment.format(t('config.time_format'))}`

  // Media display
  let img = {
    src:
      locale === 'es'
        ? '/images/draft-media-placeholder-ES.png'
        : '/images/draft-media-placeholder-EN.png',
    alt: t('event.media_post_thumbnail'),
  }
  if (!!event?.media_attachments?.length) {
    img = {
      src: isVideo(event?.media_attachments[0])
        ? event?.media_attachments[0].thumbnail_url
        : event?.media_attachments[0].direct_upload_url,
      alt: t('event.media_post_video'),
    }
  }

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <Link to={`/events/${event?.slug ?? event.id}`}>
      <Card className="m-0 shadow-none events">
        <Row>
          <Col className="thumbnail">
            <Card.Img
              variant="top"
              className="rounded-start rounded-end-0"
              src={img.src}
              alt={img.alt}
            />
          </Col>
          <Col className="text">
            <Card.Body className="p-2 card_body">
              <Card.Text className="time w-100" as={'p'}>
                {status}
              </Card.Text>
              <Card.Text className="date" as={'p'}>
                {dates}
              </Card.Text>
              <Card.Title className="card_title">{event.title}</Card.Title>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Link>
  )
}
