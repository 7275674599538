import { Col, Row, Table } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import UseWallet from '../../../hooks/useWallet'
import styles from '../../events/sponsorship/sponsorship.module.scss'
import PledgeHeader from './PledgeHeader'
import Button from '../../../components/button'
import Icon from '../../../components/icon'
import CivChoise from '../../../components/civ-choice'
import { feeKind, feeValue, formatAmount } from '../../../lib/numbers'

interface SponsorPaymentFormProps {
  business?: any
  pledge?: any
  onSubmit: () => void
  addFundingSource: () => void
}

export default function SponsorPaymentForm(props: SponsorPaymentFormProps) {
  const { t } = useTranslation()

  const { bankAccount } = UseWallet(
    props.business.wallet_id,
    props.business.bank_account_id,
  )

  const adminFee =
    feeValue(props.pledge?.amount, props.pledge?.civillos_admin_fee) ?? 0

  const serviceFee =
    feeValue(props.pledge?.amount, props.pledge?.meta_admin_fee) ?? 0

  return (
    <>
      <div className="mb-3">
        <h2>
          <b>{t('sponsorship.pledgeModal.title')}</b>
        </h2>
      </div>
      <div className="mb-3">
        <div className={styles.section}>
          <PledgeHeader
            event={props.pledge.event}
            pledgeAmount={'' + props.pledge?.amount}
          />
        </div>
      </div>
      <div className="text-center mt-2 mb-3">
        <strong>{t('sponsorship.pledgeModal.volunteerPayForward')}</strong>
      </div>
      <div className="mb-3">
        <div className={styles.calculator}>
          <Table>
            <tbody>
              <tr className={styles.lineThick}>
                <td className={styles.tableRowKey}>
                  {t('sponsorship.pledgeModal.pledgeAmountTitle')}
                </td>
                <td className={styles.tableRowValue}>
                  {formatAmount(props.pledge?.amount ?? 0, true)}
                </td>
                {props.pledge.payment_status === 'paid' ? (
                  <td className={styles.tableRowIcon}>
                    <Link
                      to={`acknowledgement/${props.pledge?.civ_purchase_id}/print`}
                      target="_blank"
                    >
                      <Icon name={'download'} className={'info-circle'} />
                    </Link>
                  </td>
                ) : null}
              </tr>
              <tr className={styles.line}>
                <td className={styles.tableRowKey}>
                  {t('civ_wallet.civ_modal.transactionFee', {
                    fee: feeKind(props.pledge?.civillos_admin_fee, true),
                  })}
                </td>
                <td className={styles.tableRowValue}>
                  {formatAmount(adminFee, true)}
                </td>
                {props.pledge.payment_status === 'paid' ? (
                  <td className={styles.tableRowIcon}>
                    {adminFee > 0 ? (
                      <Link
                        to={`admin-fee-receipt/${props.pledge?.civ_purchase_id}/print`}
                        target="_blank"
                      >
                        <Icon name={'download'} className={'info-circle'} />
                      </Link>
                    ) : (
                      <Icon name={'download'} className="defaultText" />
                    )}
                  </td>
                ) : null}
              </tr>
              <tr className={styles.lineThick}>
                <td className={styles.tableRowKey}>
                  {t('civ_wallet.civ_modal.serviceFee', {
                    fee: feeKind(props.pledge?.meta_admin_fee, true),
                  })}
                </td>
                <td className={styles.tableRowValue}>
                  {formatAmount(serviceFee, true)}
                </td>
                {props.pledge.payment_status === 'paid' ? (
                  <td className={styles.tableRowIcon}>
                    {serviceFee > 0 ? (
                      <Link
                        to={`pledge-receipt/${props.pledge?.id}/print`}
                        target="_blank"
                      >
                        <Icon name={'download'} className={'info-circle'} />
                      </Link>
                    ) : (
                      <Icon name={'download'} className="defaultText" />
                    )}
                  </td>
                ) : null}
              </tr>
              <tr>
                <td className={styles.tableRowKey}>Total</td>
                <td className={styles.tableRowValue}>
                  {formatAmount(
                    props.pledge?.amount + adminFee + serviceFee,
                    true,
                  )}
                </td>
                {props.pledge.payment_status === 'paid' ? (
                  <td className={styles.tableRowIcon}>&nbsp;</td>
                ) : null}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.terms}>
          {t('dashboard.sponsorship.paymentModal.pledgeFinePrint')}
        </div>
      </div>

      {/* hide funding bank account */}
      {false && (
        <div className={styles.section}>
          <Row>
            <Col>{t('civ_wallet.civ_modal.payment.title')}</Col>
          </Row>
          {props.business.bank_account_id ? (
            <Row className="mt-2">
              <Col className={styles.terms}>
                <Trans
                  i18nKey="civ_wallet.civ_modal.payment.detail"
                  values={{
                    numbers: bankAccount?.bank_account_number_last4,
                  }}
                />
              </Col>
            </Row>
          ) : (
            <div className="mt-2 d-flex align-items-center flex-column">
              <Button
                className="text-right mb-4"
                onClick={props.addFundingSource}
              >
                <Icon name="plus" className="me-2" />
                {t('dashboard.sponsorship.paymentModal.addPaymentMethod')}
              </Button>
            </div>
          )}
        </div>
      )}

      {props.pledge.payment_status === 'pending' ? (
        <div className={styles.section}>
          <div className={styles.terms}>
            {t('dashboard.sponsorship.paymentModal.agreePayment')}
          </div>
        </div>
      ) : null}

      <div className={styles.section}>
        <div className="d-flex flex-column mt-4">
          {props.pledge.payment_status === 'pending' ? (
            <Button
              className="text-right"
              onClick={props.onSubmit}
              disabled={!props.business.bank_account_id}
            >
              {t('dashboard.sponsorship.paymentModal.payButton')}
            </Button>
          ) : (
            <>
              <div className={styles.actionDate}>
                {t(
                  `dashboard.sponsorship.paymentDate.${props.pledge.payment_status}`,
                  {
                    date: moment(props.pledge.updated_at).format(
                      t('config.date_format'),
                    ),
                  },
                )}
              </div>
              <Button
                className="text-right"
                onClick={props.onSubmit}
                disabled={true}
                variant={
                  props.pledge.payment_status !== 'paid' ? 'warning' : 'dark'
                }
              >
                {t(
                  `dashboard.sponsorship.paymentStatus.${props.pledge.payment_status}`,
                )}
              </Button>
            </>
          )}
        </div>
      </div>

      <div className={styles.section}>
        <CivChoise />
      </div>
    </>
  )
}
