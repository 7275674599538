import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Col } from 'react-bootstrap'

import Button from '../../components/button'
import Loading from '../../components/loading'
import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function ContactUsPage() {
  const { t } = useTranslation()
  const { sendFeedback } = useApi()
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  // currentScreen will be 0, 1, or 2. 0 is loading screen. 1 is request submission. 2 is message sent screen, reroute to home in 2 seconds.
  const [messageSent, setMessageSent] = useState(false)
  const [message, setMessage] = useState('')

  const changeMessage = ({ target }) => {
    setMessage(target.value)
  }

  const submitMessage = event => {
    event.preventDefault()
    sendFeedback({ name: currentUser.name, email: currentUser.email, message })
    setMessageSent(true)
    setTimeout(() => navigate('../../home'), 2500)
  }

  if (messageSent)
    return <div className="p-5 m-5">{t('ContactUsScreen.farewell')}</div>

  if (!currentUser) return <Loading show />

  return (
    <Container>
      <h1>{t('navigation_banner.contact_link')}</h1>
      <strong>{t('ContactUsScreen.share_disclaimer')}:</strong>
      <p>
        {t('ContactUsScreen.name')} {currentUser.name}
        <br />
        {t('ContactUsScreen.email')} {currentUser.uid}
      </p>
      <strong>{t('ContactUsScreen.message_question')}</strong>
      <Col lg="8">
        <textarea
          className="w-100 pb-5"
          onChange={changeMessage}
          value={message}
          maxLength={500}
          placeholder={t('ContactUsScreen.message_placeholder')}
        />
      </Col>
      <Col lg={{ span: 4, offset: 8 }}>
        <div className="d-flex justify-content-right mb-4">
          <Link to="../../home">
            <Button type="button" className="m-2">
              {t('users.edit.cancel')}
            </Button>
          </Link>
          <Button
            type="submit"
            variant="secondary"
            className="m-2"
            onClick={submitMessage}
            disabled={message === ''}
          >
            {t('ContactUsScreen.send')}
          </Button>
        </div>
      </Col>
    </Container>
  )
}
