import 'moment/locale/es'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { Card, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { isVideo } from '../../lib/files'

export default function ConnectionFeed({ event }) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()
  const startMoment = moment(event?.event_start_date).tz(moment.tz.guess())
  const status =
    startMoment.diff(moment()) < 0
      ? t('feed.in_progress')
      : startMoment.fromNow()

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <Link to={`/events/${event?.slug ?? event?.id}`}>
      <Card className="m-0 shadow-none connections">
        {(!!event?.media_attachments?.length && (
          <Carousel
            controls={event?.media_attachments?.length > 1}
            indicators={event?.media_attachments?.length > 1}
          >
            {event?.media_attachments?.map((imgLink, idx) => {
              return (
                <Carousel.Item
                  key={`${event?.id} ${imgLink?.id || idx}`}
                  className="card-img-top"
                >
                  {(isVideo(imgLink) && (
                    <video
                      className="card-img-top"
                      autoPlay
                      muted
                      loop
                      controls
                      poster={imgLink.thumbnail_url}
                      src={imgLink.direct_upload_url}
                    />
                  )) || (
                    <Card.Img
                      variant="top"
                      src={imgLink.direct_upload_url}
                      alt={t('event.media_post_video')}
                    />
                  )}
                </Carousel.Item>
              )
            })}
          </Carousel>
        )) || (
          <Card.Img
            variant="top"
            src={
              locale === 'es'
                ? '/images/draft-media-placeholder-ES.png'
                : '/images/draft-media-placeholder-EN.png'
            }
            alt={t('event.media_post_thumbnail')}
          />
        )}
        <Card.Body className="p-2 card_body">
          <Card.Text className="time">
            {`${startMoment.format(
              t('config.date_day_format'),
            )} · ${startMoment.format(t('config.time_format'))}`}
          </Card.Text>
          <Card.Title className="card_title">{event.title}</Card.Title>
          <Card.Text as={'span'}>
            <hr />
            {event.display_users?.length && (
              <ul>
                {event.display_users.map(user => (
                  <li key={`connection-${event?.id}-${user.name}`}>
                    {user.name}
                  </li>
                ))}
              </ul>
            )}
            <hr />
          </Card.Text>
          <Card.Text as={'span'} className="link-more">
            {t('feed.view_more')}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  )
}
