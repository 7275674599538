import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'

import useWallet from '../../hooks/useWallet'
import useApi from '../../hooks/useApi'
import CharityPickerItem, { Charity } from './CharityPickerItem'
import Button from '../button'
import Icon from '../icon'
import { formatAmount } from '../../lib/numbers'
import Badge from '../badge'

interface DonationModalProps {
  onHide: () => void
  onSave: (data: any) => void
  show: boolean
  walletId: number
}

interface CivSaveStatus {
  success?: boolean
  error?: any
}

const PAGE_SIZE = 20

export default function DonationModal(props: DonationModalProps) {
  const { t } = useTranslation()

  const [saveStatus, setSaveStatus] = useState<CivSaveStatus | null>(null)
  const [amount, setAmount] = useState<number | undefined>(undefined)
  const [selectedCharity, setSelectedCharity] = useState<number | null>(null)
  const [charities, setCharities] = useState<Array<Charity | any>>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [showResults, setShowResults] = useState<boolean>(false)
  const query = useRef('')

  const { charitiesDonate, charitiesSearch } = useApi()
  const { wallet, refresh } = useWallet(props.walletId)

  const handleSave = useCallback(async () => {
    charitiesDonate(selectedCharity, {
      amount: amount,
    })
      .then(response => {
        setSaveStatus({
          //@ts-ignore
          success: response.status === 200,
          // error: response.error
        })
        //@ts-ignore
        if (response.status === 200) {
          props.onSave({ ...wallet, balance: wallet.balance - (amount ?? 0) })
          refresh()
        }
      })
      .catch(error => {
        console.log(error)
        setSaveStatus({ success: false })
      })
  }, [amount, selectedCharity])

  const onHide = () => {
    props.onHide()
    setSaveStatus(null)
    setSelectedCharity(null)
    setAmount(0)
    setCurrentPage(1)
    setTotalCount(0)
    setTotalPages(0)
    setCharities([])
    query.current = ''
  }

  const fetchSearchResults = useCallback(async () => {
    if (query.current?.length) {
      let q = query.current
      // Remove any dashes if searching by EIN
      if (parseInt(q, 10)) {
        q = q.replace('-', '')
      }
      const { data } = await charitiesSearch({
        query: q,
        page: currentPage,
        perPage: PAGE_SIZE,
      })
      if (data) {
        if (data.results.length > 0) {
          setCharities(data.results)
          setTotalCount(data.total_count ?? 0)
          setTotalPages(data.total_pages ?? 1)
        } else {
          setCharities([])
        }
        setShowResults(true)
      }
    }
  }, [currentPage])

  const handleSearch = useCallback(() => {
    setCurrentPage(1)
    setTotalCount(0)
    setTotalPages(0)
    fetchSearchResults()
  }, [fetchSearchResults])

  useEffect(() => {
    fetchSearchResults()
  }, [currentPage, fetchSearchResults])

  const visiblePageNumbers = useMemo(() => {
    if (totalPages <= 7) {
      return Array.from(Array(totalPages).keys()).map(page => page + 1)
    }
    if (currentPage <= 4) {
      return [1, 2, 3, 4, 5, 6, 7]
    }
    if (currentPage > totalPages - 4) {
      return [
        totalPages - 6,
        totalPages - 5,
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ]
    }
    return [
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
    ]
  }, [currentPage, totalPages])

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      size={
        saveStatus === null || (!saveStatus?.success && saveStatus?.error)
          ? 'lg'
          : 'sm'
      }
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto p-0 badge-container">
          {saveStatus === null || (!saveStatus?.success && saveStatus?.error)
            ? t('civ_wallet.donation.modal.title')
            : t('civ_wallet.donation.modal.success.title')}
          <Badge />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {saveStatus === null || (!saveStatus?.success && saveStatus?.error) ? (
          <>
            {!saveStatus?.success && saveStatus?.error ? (
              <p className="text-center mx-auto w-75">
                <Alert key="danger" variant="danger">
                  {t('civ_wallet.donation.modal.failure')}
                </Alert>
              </p>
            ) : null}
            <Row className="mb-4 mx-auto w-50 fw-bold">
              <Col className="text-end">
                {t('civ_wallet.donation.modal.civBalance')}
              </Col>
              <Col className="text-center">{formatAmount(wallet.balance)}</Col>
            </Row>
            <Row className="mb-4 mx-auto w-100 fw-bold">
              <Col className="text-end align-self-center">
                {t('civ_wallet.donation.modal.civDonationAmount')}
              </Col>
              <Col className="text-center">
                <input
                  className={`form-control form-control-lg text-center w-50 ${
                    (amount ?? 0) > wallet.balance ? 'is-invalid' : ''
                  }`}
                  id="civ_amount"
                  name="civ_amount"
                  type="text"
                  value={amount}
                  placeholder="0"
                  onChange={e => {
                    setAmount(
                      e.target.value ? parseInt(e.target.value) : undefined,
                    )
                  }}
                />
              </Col>
            </Row>
            <Row className="mx-auto w-100">
              <Col className="col-9">
                <input
                  className="form-control form-control-sm"
                  id="charitySearch"
                  placeholder={t('civ_wallet.donation.modal.searchPlaceholder')}
                  onChange={e => {
                    setCharities([])
                    setShowResults(false)
                    query.current = e.target.value
                  }}
                />
              </Col>
              <Col className="col-3 d-grid">
                <Button
                  variant="primary"
                  onClick={handleSearch}
                  disabled={!query.current.length}
                  className="btn-sm"
                >
                  {t('buttons.search')}
                </Button>
              </Col>
            </Row>
            {showResults ? (
              <Row className="mx-auto w-100 ps-2 pt-2">
                <Col>
                  <div className="font-size-0_7">
                    {t('charities.search.search_results', {
                      range: charities.length
                        ? `${(currentPage - 1) * PAGE_SIZE + 1}–${
                            (currentPage - 1) * PAGE_SIZE + charities.length
                          }`
                        : '0',
                      total: totalCount,
                    })}{' '}
                    <b>{query.current}</b>
                  </div>
                </Col>
              </Row>
            ) : null}
            {charities.length > 0 ? (
              <>
                <div className="m-3 ps-2 pe-3 overflow-y-auto overflow-x-hidden max-height-list-modal">
                  <Row className="d-flex flex-column border-bottom">
                    {charities.map(c => (
                      <Col key={c.id}>
                        <CharityPickerItem
                          charity={c}
                          isSelected={c.id === selectedCharity}
                          onChange={id => setSelectedCharity(id)}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
                <Row className="w-75 mx-auto">
                  <Col className="p-0">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn btn-link text-decoration-none px-2"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        ＜ {t('charities.search.previous_button')}
                      </button>
                      <span className="flex-fill d-flex justify-content-center">
                        {!visiblePageNumbers.includes(1) ? (
                          <button
                            type="button"
                            className="btn ps-0 pe-2"
                            onClick={() =>
                              setCurrentPage(visiblePageNumbers[0] - 1)
                            }
                          >
                            <Icon name="ellipsis" />
                          </button>
                        ) : null}
                        {visiblePageNumbers.map(page => (
                          <button
                            key={page}
                            type="button"
                            className="btn px-2"
                            onClick={() => setCurrentPage(page)}
                            style={{
                              fontWeight:
                                page === currentPage ? 'bold' : 'normal',
                            }}
                          >
                            {page}
                          </button>
                        ))}
                        {!visiblePageNumbers.includes(totalPages) ? (
                          <button
                            type="button"
                            className="btn ps-2 pe-0"
                            onClick={() =>
                              setCurrentPage(
                                visiblePageNumbers[
                                  visiblePageNumbers.length - 1
                                ] + 1,
                              )
                            }
                          >
                            <Icon name="ellipsis" />
                          </button>
                        ) : null}
                      </span>

                      <button
                        type="button"
                        className="btn btn-link text-decoration-none px-2"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        {t('charities.search.next_button')} ＞
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}
          </>
        ) : (
          <>
            <p className="text-center fs-6">
              <Row>
                <Col className="fw-bold text-end">
                  {t('civ_wallet.donation.modal.civBalance')}
                </Col>
                <Col className="text-start">{formatAmount(wallet.balance)}</Col>
              </Row>
            </p>
            <p className="text-center success-icon">
              <Icon name="check-circle" />
            </p>
            <p className="text-center fs-6">
              <Trans
                i18nKey="civ_wallet.donation.modal.success.message"
                values={{
                  amount: formatAmount(amount),
                }}
              />
            </p>
            <p className="text-center">
              {charities.find(c => c.id === selectedCharity)?.organization_name}
            </p>
            <p className="text-center">
              {moment().format(t('config.date_format'))}
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-md-center">
        {saveStatus === null || (!saveStatus?.success && saveStatus?.error) ? (
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!amount || amount > wallet.balance || !selectedCharity}
            className="w-75"
          >
            <Icon name="gift" className="me-2" />
            {t('civ_wallet.donation.modal.button')}
          </Button>
        ) : (
          <Button variant="primary" onClick={onHide}>
            {t('civ_wallet.donation.modal.success.button')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
