import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ApiResponse } from '../../types'
import { restoreState, storeState } from '../../lib/localstorage'
import { sortCategoriesAlphabetically } from '../../lib/sort'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import CausePickerItem, { Cause } from './CausePickerItem'
import Icon from '../icon'
import useApi from '../../hooks/useApi'

interface CausePickerProps {
  onChange?: (selectedCauseIds: Array<string | number>) => void
  selectedCauseIds?: Array<string | number>
  title?: any
}

const CausePicker = (props: CausePickerProps) => {
  const { currentUser, locale, setCurrentUser } = useCurrentUser()
  const { getEventCategories, updateUser } = useApi()
  const { t } = useTranslation()

  const [canSave, setCanSave] = useState<boolean>(false)
  const [categories, setCategories] = useState(() =>
    restoreState('categories', []),
  )
  const [selectedCauseIds, setSelectedCauseIds] = useState<
    Array<string | number>
  >(props.selectedCauseIds ?? restoreState('selectedCauseIds', []))

  const fetchData = async () => {
    const { data: _categories } = await getEventCategories()
    if (_categories) {
      setCategories(sortCategoriesAlphabetically(_categories, locale))
    }
  }

  const onChangeItem = useCallback(
    (causeId: string) => {
      const updatedSelection = [...selectedCauseIds]
      const causeIndex = updatedSelection.indexOf(causeId)
      if (causeIndex !== -1) {
        updatedSelection.splice(causeIndex, 1)
      } else {
        updatedSelection.push(causeId)
      }
      setSelectedCauseIds(updatedSelection)
      setCanSave(true)

      if (props.onChange) {
        props.onChange(updatedSelection)
        return
      }
    },
    [selectedCauseIds],
  )

  const onSave = useCallback(async () => {
    setCanSave(false)

    const response: ApiResponse = await updateUser(currentUser?.id, {
      user: { category_ids: selectedCauseIds },
    })
    if (response.errors) {
      alert(response.errors?.full_messages?.[0])
    } else {
      setCurrentUser(response.data)
    }
  }, [selectedCauseIds])

  useEffect(() => {
    if (props.selectedCauseIds) return
    setSelectedCauseIds(currentUser?.categories?.map((c: any) => c.id) || [])
  }, [props.selectedCauseIds, currentUser?.categories])

  useEffect(() => {
    storeState('selectedCauseIds', selectedCauseIds || [])
  }, [selectedCauseIds])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="container">
      <div className="row form-row mb-4">
        {props.title ? (
          props.title
        ) : (
          <h1>
            <Icon name="hand-heart" /> {t('users.edit.causes.title')}
          </h1>
        )}
        <h6 className="block-description">{t('users.edit.causes.message')}</h6>
        <div className="row">
          {categories?.map((cause: Cause) => (
            <div className="col-lg-4 col-md-6" key={cause.id}>
              <CausePickerItem
                cause={cause}
                isSelected={selectedCauseIds.includes(cause.id)}
                onChange={onChangeItem}
              />
            </div>
          ))}
        </div>
      </div>
      {!props.onChange ? (
        <input
          className="btn btn-primary"
          disabled={!canSave}
          onClick={onSave}
          type="submit"
          value={t('users.edit.save')}
        />
      ) : null}
    </div>
  )
}

export default CausePicker
