import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import SortTable from '../../components/sortTable/sortTable'
import Icon from '../../components/icon'
import { feeKind, feeValue, formatAmount } from '../../lib/numbers'
import { downloadCSV } from '../../lib/files'
import civ from '../../constants/civ'

export default function MetaWallet({ setLoading }) {
  const { t } = useTranslation()
  const [transactions, setTransactions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [queryParams, setQueryParams] = useState({
    sort_by: 'created_at',
    sort_order: 'DESC',
  })

  const { getMetaWallet } = useApi()

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getMetaWallet(queryParams)
      setTransactions(
        response.data?.map(d => ({
          created_at: moment(d.created_at).format(t('config.date_format')),
          business_name: d.business_name,
          amount: formatAmount(d.amount),
          admin_fee_value: formatAmount(
            feeValue(d.amount, {
              fee_kind: d.meta_fee_kind,
              value: d.meta_fee_value,
            }),
            true,
          ),
          service_fee: feeKind({
            fee_kind: d.meta_fee_kind,
            value: d.meta_fee_value,
          }),
        })),
        setTotalAmount(
          response.data.reduce((acc, obj) => {
            return (
              acc +
              feeValue(obj.amount, {
                fee_kind: obj.meta_fee_kind,
                value: obj.meta_fee_value,
              })
            )
          }, 0),
        ),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getMetaWallet, queryParams])

  useEffect(() => {
    fetchData()
  }, [queryParams])

  const headers = [
    {
      title: t('accounting.meta.headers.date'),
      prop: 'created_at',
      isSortable: true,
    },
    {
      title: t('accounting.meta.headers.sponsor_name'),
      prop: 'business_name',
      isSortable: true,
    },
    {
      title: t('accounting.meta.headers.civ_pledged'),
      prop: 'amount',
      isSortable: true,
    },
    {
      title: t('accounting.meta.headers.fee_value'),
      prop: 'admin_fee_value',
      isSortable: true,
    },
    {
      title: t('accounting.meta.headers.service_fee'),
      prop: 'service_fee',
      isSortable: true,
    },
  ]

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  const downloadReport = () => {
    setLoading(true)
    getMetaWallet(queryParams, 'csv')
      .then(response => {
        const fileName = `meta_report_${moment().format(
          t('config.file_name_date_format'),
        )}.csv`
        downloadCSV(response.data, fileName)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
      })
  }

  return (
    <Accordion.Item eventKey="metaWallet" className="border event-pledge">
      <Accordion.Header className="border-top">
        <Row className="w-100 justify-content-between align-items-center">
          <Col className="text-start fw-semibold" lg="4">
            {t('accounting.meta.title')}
          </Col>
          <Col className="text-end pe-4">
            {transactions?.length ? (
              <>
                <span>
                  {t('accounting.meta.totalAmount', {
                    total: formatAmount(totalAmount, true),
                  })}
                </span>
                <div
                  className="btn btn-link p-0 text-decoration-none"
                  onClick={() => downloadReport()}
                >
                  {t('accounting.meta.downloadReport.label')}
                  <Icon name="download" />
                </div>
              </>
            ) : null}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body className="p-0">
        <SortTable
          body={transactions}
          headers={headers}
          onFetch={s => onFetch(s)}
          showPagination={false}
        />
      </Accordion.Body>
    </Accordion.Item>
  )
}
