import { Link } from 'react-router-dom'

const maxLenTitle = 30

export default function EventTitle({ event }) {
  return (
    <Link target="_blank" to={`/events/${event?.slug ?? event.id}`}>
      {event.title.length > maxLenTitle
        ? `${event.title.substring(0, maxLenTitle)}...`
        : event.title}
    </Link>
  )
}
