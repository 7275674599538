import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { charityAddress } from '../../lib/address'
import { formatPhoneNumberForDisplay } from '../../lib/phone'
import { formatEin, toCapitalizeWords } from '../../lib/strings'
import CharityStatusHistory from '../../components/Accounting/CharityStatusHistory'

const options = [
  'Verification in Progress',
  'Verified',
  'Withdrawn',
  'Suspended',
  'Fraud Investigation',
  'Unverified',
]

export default function CharityRegistrationModal({
  data,
  show,
  onHide,
  setRefresh,
}) {
  const { t } = useTranslation()
  const [charityData, setCharityData] = useState()
  const [newStatus, setNewStatus] = useState()
  const [history, setHistory] = useState([])
  const { updateCharityStatus } = useApi()
  const { currentUser } = useCurrentUser()

  const onDonate = () => {
    const params = { charity_registration: { status: newStatus } }
    updateCharityStatus(charityData.id, params)
      .then(response => {
        setHistory([
          ...history,
          {
            previous_status: charityData?.status,
            changed_at: new Date(),
            changed_by: currentUser.name,
            new_status: newStatus,
          },
        ])
        setCharityData({ ...charityData, status: newStatus })
        setRefresh(+new Date())
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (!!data) {
      setCharityData(data)
      setNewStatus(data?.status)
      setHistory([...data?.status_changes])
    }
  }, [data])

  if (!charityData) {
    return null
  }

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100 p-0">
          {t('accounting.charityRegistration.modal.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <Row className="d-flex flex-column">
              <Col className="fw-bold">
                {t('accounting.charityRegistration.modal.registrant', {
                  id: charityData?.id,
                })}
              </Col>
              <Col>{charityData?.user?.name}</Col>
              <Col>{charityData?.user?.email}</Col>
              <Col>{formatPhoneNumberForDisplay(charityData?.user?.phone)}</Col>
            </Row>
          </Col>
          <Col>
            <Row className="">
              <Col className="align-self-center text-end">
                <Trans
                  i18nKey="accounting.charityRegistration.modal.civic_rush_status"
                  values={{
                    status: '',
                  }}
                />
              </Col>
              <Col>
                <select
                  name="status"
                  id="status"
                  className="form-control"
                  onChange={e => setNewStatus(e?.target.value)}
                  defaultValue={data?.status}
                >
                  {options.map(s => (
                    <option value={s} key={s}>
                      {t(`crStatus.${s.toLowerCase().replaceAll(' ', '_')}`)}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="d-flex flex-column">
          <Col className="fw-bold">
            {toCapitalizeWords(charityData?.charity?.organization_name)}
          </Col>
          <Col>
            {t('accounting.charityRegistration.modal.ein', {
              number: formatEin(charityData?.charity?.ein),
            })}
          </Col>
          <Col>{charityAddress(charityData?.charity)}</Col>
          <Col>
            {formatPhoneNumberForDisplay(charityData?.charity?.contact_phone)}
          </Col>
          <Col>{charityData?.charity?.contact_email}</Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Trans
              i18nKey="accounting.charityRegistration.modal.irs_status"
              values={{
                status: 'in good standing',
              }}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-column">
          <Col className="fw-bold">
            {t('accounting.charityRegistration.modal.bank_account.title')}
          </Col>
          <Col>
            {t('accounting.charityRegistration.modal.bank_account.holder', {
              name: charityData?.bank_account?.bank_account_holder_name,
            })}
          </Col>
          <Col>
            {t('accounting.charityRegistration.modal.bank_account.routing', {
              number: charityData?.bank_account?.bank_account_number,
            })}
          </Col>
          <Col>
            {t('accounting.charityRegistration.modal.bank_account.number', {
              number: charityData?.bank_account?.bank_account_routing_number,
            })}
          </Col>
        </Row>
        {!!charityData?.voided_check_image_url ? (
          <Row className="my-3">
            <Col>
              <span className="fw-bold">
                {t('accounting.charityRegistration.modal.documentation.text')}
              </span>
              <a target="_blank" href={charityData?.voided_check_image_url}>
                {t(
                  'accounting.charityRegistration.modal.documentation.link.text',
                )}
              </a>
            </Col>
          </Row>
        ) : null}
        <hr />
        <Row>
          <Col>
            <Trans
              i18nKey="accounting.charityRegistration.modal.registration_request"
              values={{
                date: moment(charityData?.created_at).format(
                  t('config.date_format'),
                ),
              }}
            />
          </Col>
          <Col className="text-end">
            <Trans
              i18nKey="accounting.charityRegistration.modal.civic_rush_status"
              values={{
                status: t(
                  `crStatus.${(history?.length
                    ? history[0].previous_status
                    : charityData?.status
                  )
                    .toLowerCase()
                    .replaceAll(' ', '_')}`,
                ),
              }}
            />
          </Col>
        </Row>
        {!!history?.length
          ? history.map(s => <CharityStatusHistory status={s} />)
          : null}
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col className="text-center">
            <Button variant="secondary" onClick={onHide}>
              {t('accounting.charityRegistration.modal.buttons.close')}
            </Button>
          </Col>
          <Col className="text-center">
            <Button
              variant="primary"
              disabled={newStatus === charityData?.status}
              onClick={() => onDonate()}
            >
              {t('accounting.charityRegistration.modal.buttons.save')}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
