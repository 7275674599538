import { useEffect, useState } from 'react'

export function restoreState(key: string, defaultValue?: any) {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : defaultValue
}

export function storeState(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function removeState(key: string) {
  localStorage.removeItem(key)
}

export function removeAllState() {
  localStorage.clear()
}

/**
 * useStateCache - a wrapper around useState that stores the state in localStorage
 *
 * @param {string} key - the key to store the state under
 * @param {any?} defaultValue - the default value to use if no state is found
 * @returns {[any, function]} - the state and setState function
 */
export function useStateCache(key: string, defaultValue?: any) {
  const [state, setState] = useState(restoreState(key, defaultValue))

  useEffect(() => {
    storeState(key, state)
  }, [key, state])

  return [state, setState]
}
