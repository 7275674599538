import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import CharitySearchRow from './row'
import Styles from '../charities.module.scss'
import useApi from '../../../hooks/useApi'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import TextInput from '../../../components/TextInput/TextInput'

const PAGE_SIZE = 5

export default function CharitySearchList(props) {
  const { initialQuery = '' } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()

  const { charitiesSearch } = useApi()

  const [charities, setCharities] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [showResults, setShowResults] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const query = useRef(initialQuery)

  const onClickRegister = useCallback(charity => {
    if (!currentUser?.email_verified_at) {
      localStorage.setItem('force-verify-email', true)
      localStorage.setItem(
        'verify-email-message',
        t('users.edit.email_verify_charity'),
      )
      window.location = '/settings'
    } else {
      navigate(`/charities/register?ein=${charity.ein}`, {
        state: { charity },
      })
    }
  }, [])

  // Format charity api data to be compatible with the client
  const formatCharity = useCallback(
    charity => ({
      ...charity,
      addressLine1: charity.address_line_1,
      addressLine2: charity.address_line_2,
    }),
    [],
  )

  const fetchSearchResults = useCallback(async () => {
    if (query.current?.length) {
      let q = query.current
      // Remove any dashes if searching by EIN
      if (parseInt(q, 10)) {
        q = q.replace('-', '')
      }
      const { data } = await charitiesSearch({
        query: q,
        page: currentPage,
        perPage: PAGE_SIZE,
      })
      if (data) {
        if (data.results.length > 0) {
          setCharities(data.results.map(formatCharity))
          setTotalCount(data.total_count ?? 0)
          setTotalPages(data.total_pages ?? 1)
        } else {
          setCharities([])
        }
        setShowResults(true)
      }
    }
  }, [currentPage])

  const handleSearch = useCallback(
    e => {
      e.preventDefault()
      setCurrentPage(1)
      setTotalCount(0)
      setTotalPages(0)
      fetchSearchResults()
    },
    [fetchSearchResults],
  )

  useEffect(() => {
    fetchSearchResults()
  }, [currentPage, fetchSearchResults])

  const visiblePageNumbers = useMemo(() => {
    if (totalPages <= 7) {
      return Array.from(Array(totalPages).keys()).map(page => page + 1)
    }
    if (currentPage <= 4) {
      return [1, 2, 3, 4, 5, 6, 7]
    }
    if (currentPage > totalPages - 4) {
      return [
        totalPages - 6,
        totalPages - 5,
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ]
    }
    return [
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
    ]
  }, [currentPage, totalPages])

  return (
    <div className={Styles.searchList}>
      <form onSubmit={handleSearch} className={Styles.searchBar}>
        <TextInput
          clearable
          id="charitySearch"
          placeholder={t('charities.search.search_placeholder')}
          onChange={text => {
            setCharities([])
            setShowResults(false)
            query.current = text
          }}
          onClear={() => {
            setCharities([])
            setShowResults(false)
            query.current = ''
          }}
        />
        <button
          className="btn btn-primary"
          onClick={handleSearch}
          type="submit"
        >
          {t('charities.search.search_button')}
        </button>
      </form>

      {charities.length > 0 ? (
        <div className={Styles.listContainer}>
          <div>
            {t('charities.search.search_results', {
              range: `${(currentPage - 1) * PAGE_SIZE + 1}–${
                (currentPage - 1) * PAGE_SIZE + charities.length
              }`,
              total: totalCount,
            })}{' '}
            <b>{query.current}</b>
          </div>
          <div className={Styles.list}>
            {charities.map(charity => (
              <CharitySearchRow
                charity={charity}
                key={charity.organization_id ?? charity.id}
                onClickRegister={onClickRegister}
              />
            ))}
          </div>
          <div className={Styles.pagination}>
            <button
              type="button"
              className={`btn ${Styles.nextPrev}`}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ＜ {t('charities.search.previous_button')}
            </button>
            <span>
              {!visiblePageNumbers.includes(1) ? (
                <button
                  type="button"
                  className="btn"
                  onClick={() => setCurrentPage(visiblePageNumbers[0] - 1)}
                >
                  ⋯
                </button>
              ) : null}
              {visiblePageNumbers.map(page => (
                <button
                  key={page}
                  type="button"
                  className="btn"
                  onClick={() => setCurrentPage(page)}
                  style={{
                    fontWeight: page === currentPage ? 'bold' : 'normal',
                  }}
                >
                  {page}
                </button>
              ))}
              {!visiblePageNumbers.includes(totalPages) ? (
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    setCurrentPage(
                      visiblePageNumbers[visiblePageNumbers.length - 1] + 1,
                    )
                  }
                >
                  ⋯
                </button>
              ) : null}
            </span>

            <button
              type="button"
              className={`btn ${Styles.nextPrev}`}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {t('charities.search.next_button')} ＞
            </button>
          </div>
        </div>
      ) : (
        showResults &&
        query.current?.length > 0 && (
          <div className={Styles.searchListContainer}>
            Showing 0 results matching <b>{query.current}</b>
          </div>
        )
      )}
    </div>
  )
}

CharitySearchList.defaultProps = {
  initialQuery: '',
}

CharitySearchList.propTypes = {
  initialQuery: PropTypes.string,
}
