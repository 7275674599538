import ReactDOMServer from 'react-dom/server'
import { createCanvas, loadImage } from 'canvas'
import moment from 'moment-timezone'

import QR from '../components/QR'

export const currentStatus = event => {
  const startMoment = moment(event.event_start_date).tz(moment.tz.guess())
  const endMoment = moment(event.event_end_date).tz(moment.tz.guess())
  if (endMoment.diff(moment()) < 0) {
    return 'event.status.completed'
  }
  if (startMoment.diff(moment()) < 0) {
    return 'event.status.inProgress'
  }
  return 'event.status.upcoming'
}

export const mobileDeepLink = event => {
  return `civicrush://civicrush.com/events/${event.id}`
}

export const removeHTMLTags = text => {
  const regex = /(<([^>]+)>)/gi
  return text.replace(regex, '')
}

export const getTeam = event => {
  // All admins
  const admins = event?.admins.map(a => ({ id: a.id, name: a.name }))
  // All tasks leaders
  const leaders = event?.tasks
    .map(t => t?.task_leader_users.map(u => ({ id: u.id, name: u.name })))
    .flat()
  // Unique users
  return [...new Map([...admins, ...leaders].map(i => [i.id, i])).values()]
}

export const QRCodePNG = async ({ title, value, size }) => {
  // Renderizar el QR Code como un SVG
  const svgString = ReactDOMServer.renderToString(
    <QR title={title} value={value} size={size} svgOnly />,
  )
  // Crear un elemento canvas
  const canvas = createCanvas(size, size)
  const ctx = canvas.getContext('2d')

  // Convertir SVG a data URL
  const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
  const url = URL.createObjectURL(svgBlob)

  // Cargar la imagen en el canvas
  const image = await loadImage(url)
  ctx.drawImage(image, 0, 0)

  // Convertir el canvas a PNG
  const pngDataUrl = canvas.toDataURL('image/png')

  return pngDataUrl
}

export default {
  currentStatus,
  mobileDeepLink,
  removeHTMLTags,
  getTeam,
  QRCodePNG,
}
