import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'

import { CurrentUserProvider } from './hooks/useCurrentUser'
import useApi from './hooks/useApi'
import AccountEditPage from './pages/account/edit'
import AccountNewPage from './pages/account/new'
import BusinessEdit from './pages/business/business-edit'
import CharitiesIndexPage from './pages/charities/search'
import CharitiesRegisterPage from './pages/charities/register'
import CivicParticipation from './pages/account/civic-participation'
import ContactUsPage from './pages/account/contact-us'
import Dashboard from './pages/dashboard/dashboard'
import DashboardAccounting from './pages/accounting/dashboard'
import DashboardCharity from './pages/charities/dashboard/dashboard'
import DashboardOrganizer from './pages/organizer/dashboard'
import EngagementsPage from './pages/account/Engagements'
import EventsEditPage from './pages/events/edit'
import EventSponsorshipLandingPage from './pages/events/sponsorship'
import EventsShowPage from './pages/events/show'
import FeedPage from './pages/feed'
import ForgotPasswordPage from './pages/account/forgot-password'
import GroupEngagements from './pages/groups/group-engagements'
import GroupsNav from './pages/groups/groups-nav'
import GroupsShowPage from './pages/groups/show'
import HomePage from './pages/home'
import Layout from './components/layout'
import LoginPage from './pages/account/login'
import MyPosts from './pages/my-posts'
import PdfPreview from './pages/pdf/preview'
import ResetPasswordPage from './pages/account/reset-password'

export default function App() {
  const { currentUser, setCurrentUser, getCurrentUser } = useApi()
  const { i18n } = useTranslation()
  const params = useParams()

  const [refreshed, setRefreshed] = useState(false)

  // Refresh the current user on page load
  useEffect(() => {
    if (currentUser?.id && !refreshed) {
      getCurrentUser().then(response => {
        if (response.status === 200) {
          setCurrentUser(response.data)
          setRefreshed(true)
        }
      })
    }
  }, [currentUser?.id, refreshed])

  useEffect(() => {
    const language = params.locale || currentUser?.language || 'en'
    i18n.changeLanguage(language)
    moment.locale(language)
  }, [params.locale, currentUser?.language])

  return (
    <CurrentUserProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/">
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LoginPage logout />} />
            <Route path="signup" element={<AccountNewPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route exact path="groups" element={<GroupsNav />} />
            <Route path="groups">
              <Route path=":slug" element={<GroupsShowPage />} />
              <Route path=":slug/print" element={<GroupEngagements />} />
            </Route>
            <Route path="events">
              <Route element={<ProtectedRoute />}>
                <Route path="new" element={<EventsEditPage />} />
                <Route path=":slug/edit" element={<EventsEditPage />} />
              </Route>
              <Route
                path=":slug/sponsorship"
                element={<EventSponsorshipLandingPage />}
              />
              <Route path=":slug" element={<EventsShowPage />} />
              <Route
                path=":slug/print/:lang"
                element={<PdfPreview origin={'eventFlyer'} download />}
              />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="home" element={<HomePage />} />
              <Route path="settings" element={<AccountEditPage />} />
              <Route path="business-account" element={<BusinessEdit />} />
              <Route path="charities" element={<CharitiesIndexPage />} />
              <Route path="charities/search" element={<CharitiesIndexPage />} />
              <Route
                path="charities/register"
                element={<CharitiesRegisterPage />}
              />
              <Route
                path="civic-participation"
                element={<CivicParticipation />}
              />
              <Route path="dashboard" element={<Dashboard />} />
              <Route
                path="dashboard-organizer"
                element={<DashboardOrganizer />}
              />
              <Route path="dashboard-charity" element={<DashboardCharity />} />
              <Route path="my-posts" element={<MyPosts />} />
              <Route path="feed" element={<FeedPage />} />
              <Route path="engagements" element={<EngagementsPage />} />
              <Route path="contact-us" element={<ContactUsPage />} />
              <Route
                path="dashboard/acknowledgement/:id/print"
                element={<PdfPreview origin={'acknowledgement'} />}
              />
              <Route
                path="dashboard/pledge-receipt/:id/print"
                element={<PdfPreview origin={'pledge-receipt'} />}
              />
              <Route
                path="dashboard/admin-fee-receipt/:id/print"
                element={<PdfPreview origin={'admin-fee-receipt'} />}
              />
            </Route>
            <Route
              path="dashboard-accounting"
              element={<DashboardAccounting />}
            />
          </Route>
        </Route>
      </Routes>
    </CurrentUserProvider>
  )
}

function ProtectedRoute({ children }) {
  const { currentUser } = useApi()
  const location = useLocation()

  if (currentUser?.id) {
    return children || <Outlet />
  }
  return <Navigate to="/login" state={{ from: location }} replace />
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
}
