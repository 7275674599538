import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import moment from 'moment'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import Button from '../../components/button'
import Loading from '../../components/loading'
import Acknowledgement from './acknowledgement'
import PledgeReceipt from './pledgeReceipt'
import AdminFeeReceipt from './adminFeeReceipt'
import './preview.scss'
import { buildPDF } from '../../lib/files'

export default function PdfPreview({ origin, download = false }) {
  const { t } = useTranslation()
  const params = useParams()

  const { currentUser, locale } = useCurrentUser()
  const { business_id } = currentUser || {}

  const {
    getBusiness,
    getCivPurchase,
    getPledge,
    getBankAccount,
    getEvent,
    getEventAcceptedPledges,
  } = useApi()

  const [loading, setLoading] = useState(true)
  const [returnPath, setReturnPath] = useState()
  const [fileName, setFileName] = useState()
  const [documentPDF, setDocumentPDF] = useState()
  const [goBack, setGoBack] = useState(t('navigation_banner.dashboard'))

  const fetchData = useCallback(async () => {
    buildPDF({
      origin,
      business_id,
      params,
      dateFormat: t('config.file_name_date_format'),
      locale,
      getBusiness,
      getCivPurchase,
      getBankAccount,
      getPledge,
      getEvent,
      getEventAcceptedPledges,
    }).then(response => {
      setFileName(response.fileName)
      setDocumentPDF(response.documentPDF)
      setReturnPath(response.returnPath)
      setLoading(false)
    })
  }, [getBusiness, getCivPurchase, getBankAccount, getPledge, locale])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!!download && !loading && !!documentPDF && !!fileName) {
      pdf(documentPDF)
        .toBlob()
        .then(blob => {
          saveAs(blob, fileName)
          // history.back()
          window.close()
        })
    }
  }, [download, loading, documentPDF, fileName, returnPath])

  return loading ? (
    <Loading show />
  ) : (
    <div className="wrapper">
      {!!download ? (
        <span>{t('pdf.building')}</span>
      ) : (
        <>
          <div className="pdfDL">
            <Link to={returnPath}>
              <Button className="m-3">
                {t('pdf.backButton', { return: goBack })}
              </Button>
            </Link>
            <PDFDownloadLink document={documentPDF} fileName={fileName}>
              <Button>{t('pdf.downloadButton')}</Button>
            </PDFDownloadLink>
          </div>
          <PDFViewer
            fileName={fileName}
            title={fileName}
            className="pdfFrame"
            showToolbar={false}
          >
            {documentPDF}
          </PDFViewer>
        </>
      )}
    </div>
  )
}
