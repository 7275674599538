import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Icon from '../../components/icon'
import Block from '../../components/Dashboard/Block'
import { formatAmount } from '../../lib/numbers'
import BlockContent from '../../components/Dashboard/BlockContent'

export default function EventSponsorship({ totalEvents, totalPledge }) {
  const { t } = useTranslation()

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('organizer.eventSponsorship.title')}</h1>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          <Block>
            <BlockContent
              stat={
                <Trans
                  i18nKey="organizer.eventSponsorship.totalEvents"
                  values={{
                    total: formatAmount(totalEvents ?? 0),
                  }}
                />
              }
              statLabel={t('organizer.eventSponsorship.sponsored')}
            />
          </Block>
          <Block>
            <BlockContent
              stat={`${formatAmount(totalPledge ?? 0)} CIV`}
              statLabel={t('organizer.eventSponsorship.eventPayforward')}
            />
          </Block>
          {/* <Block>
            <BlockContent stat={'PLACEHOLDER'} statLabel={''} />
          </Block>
          <Block>
            <BlockContent
              stat={<Icon name={'star'} />}
              statLabel={'PLACEHOLDER'}
            />
          </Block> */}
        </div>
      </div>
    </>
  )
}
