import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ShiftRow from './shiftRow'
import Icon from '../icon'

const MAX_TASKS = 50

export default function TaskRow({
  activeTasks,
  createShift,
  createTask,
  deleteTask,
  event,
  index,
  invalidTaskLeaders,
  invalidTitle,
  onAdminPickerOpen,
  setTasks,
  task,
  tasks,
  updateShiftProp,
  updateTasks,
}) {
  const { t } = useTranslation()

  return (
    <div className="task col-12">
      <div className="row g-0 labels">
        <div className="col-1" />
        <div className="col-6">
          <label htmlFor="task-title">{t('event.taskTitle')}</label>
        </div>
        <div className="task-leader1 col-2">
          <label htmlFor="task-leader1">{t('event.taskLeader1')}</label>
        </div>
        <div className="task-leader2 col-2">
          <label htmlFor="task-leader2">{t('event.taskLeader2')}</label>
        </div>
      </div>
      <div className="row g-0 values">
        <div className="task-position col-1">
          <div>{(index ?? 0) + 1}</div>
          {activeTasks.length > 1 && (
            <Icon name={'bars'} className="grab-cursor" />
          )}
        </div>
        <input
          className={`form-control task-title col-6 ${
            invalidTitle ? 'invalid' : ''
          }`}
          name="task-title"
          value={task.title}
          onChange={e => {
            updateTasks(task.position, 'title', e.target.value)
          }}
        />
        <input
          className={`form-control task-leader1 task-leader-select col-2 ${
            invalidTaskLeaders.includes(task.task_leader_users?.[0]?.id)
              ? 'invalid'
              : ''
          }`}
          readOnly
          name="task-leader1"
          value={task.task_leader_users?.[0]?.name || ''}
          onChange={e => {
            updateTasks(task.position, 'taskLeader1', e.target.value)
          }}
          onClick={() => onAdminPickerOpen(task)}
        />
        <input
          className={`form-control task-leader2 task-leader-select col-2 ${
            invalidTaskLeaders.includes(task.task_leader_users?.[1]?.id)
              ? 'invalid'
              : ''
          }`}
          readOnly
          name="task-leader2"
          value={task.task_leader_users?.[1]?.name || ''}
          onChange={e => {
            updateTasks(task.position, 'taskLeader2', e.target.value)
          }}
          onClick={() => onAdminPickerOpen(task)}
        />
        <div className="task-actions col-1">
          {activeTasks.length > 1 && (
            <Button
              variant="link"
              onClick={() => {
                deleteTask(task.position)
              }}
            >
              <Icon name={'minus-circle'} />
            </Button>
          )}
          {index + 1 === activeTasks.length &&
            activeTasks.length < MAX_TASKS && (
              <Button
                variant="link"
                onClick={() => {
                  setTasks([...tasks, createTask(task.position)])
                }}
              >
                <Icon name={'plus-circle'} />
              </Button>
            )}
        </div>
      </div>
      <div className="row shifts">
        <div className="shift-labels col-12">
          <div className="row g-0 labels">
            <div className="col-1" />
            <div className="col-sub-3">
              <label htmlFor="shift-start">{t('event.shift_start')}</label>
            </div>
            <div className="col-sub-3">
              <label htmlFor="shift-end">{t('event.shift_end')}</label>
            </div>
            <div className="col-1">
              <label htmlFor="shift-volunteers">{t('event.volunteers')}</label>
            </div>
            <div className="col-3" />
          </div>
        </div>

        {task.shifts
          // eslint-disable-next-line no-underscore-dangle
          .filter(shift => !shift._destroy)
          .map((shift, shiftIndex) => (
            <ShiftRow
              event={event}
              shift={shift}
              task={task}
              tasks={tasks}
              shiftIndex={shiftIndex}
              updateShiftProp={updateShiftProp}
              setTasks={setTasks}
              createShift={createShift}
              key={`shift-${shiftIndex}`}
            />
          ))}
      </div>
    </div>
  )
}
