import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import moment from 'moment-timezone'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import Button from '../button'
import ModalSignUp from './TaskAndShift/modalSignUp'

export default function TaskAndShiftButton({ event, setRefresh }) {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)
  const [register, setRegister] = useState(false)

  const { currentUser, locale } = useCurrentUser()

  /*
    Hide button reason:
    - Don't have tasks & shifts
  */
  if (!event?.tasks?.length) {
    return null
  }

  useEffect(() => {
    moment.locale(locale)

    const signedUp = event.tasks
      ?.map(t =>
        t.shifts?.map(s => ({
          id: s.id,
          users: s.volunteers.map(v => v.user.id),
        })),
      )
      .flat()
      .filter(s => s.users.includes(currentUser?.id))
      .map(m => m.id)
    console.log()
    setRegister(!!signedUp.length)
  }, [locale, showModal, event])

  return (
    <div className="my-3 d-flex justify-content-center signup-event">
      {register ? (
        <div className="my-3 d-flex justify-content-center signup-event no-print">
          <Button id={`register-${currentUser?.id}`} disabled className="mx-2">
            {t('event.registered')}
          </Button>
          <Button
            id={`cancel-${currentUser?.id}`}
            onClick={() => setShowModal(true)}
            variant="link"
            className="p-0 text-decoration-none mx-2"
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      ) : (
        <Button onClick={() => setShowModal(true)} className="no-print">
          {t('event.tasks_and_shifts')}
        </Button>
      )}
      <ModalSignUp
        show={showModal}
        onClose={() => setShowModal(false)}
        event={event}
        setRefresh={setRefresh}
      />
    </div>
  )
}
