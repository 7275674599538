import { useTranslation } from 'react-i18next'

export default function MobilePlugs() {
  const { t } = useTranslation()
  return (
    <>
      <h1 className="text-center">
        <strong>{t('event.download_message')}</strong>
      </h1>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          columnGap: '1rem',
          justifyContent: 'center',
          marginBottom: '2rem',
        }}
      >
        <a
          href="https://apps.apple.com/us/app/civicrush-volunteer/id1382111745"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/badge_apple-store-english.png" alt="App Store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.civicrush&amp;hl=en_US"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/badge_google-play-english.png" alt="Google Play" />
        </a>
      </div>
    </>
  )
}
