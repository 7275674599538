/* eslint-disable no-underscore-dangle */
import { Button, Modal } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDatePicker from 'react-datepicker'

import './taskAndShift.scss'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import AdminPicker from './adminPicker'
import Icon from '../icon'
import TasksHeader from './tasksHeader'
import TasksFooter from './tasksFooter'
import TaskRow from './taskRow'
import ModalClear from './modalClear'
import ModalCancel from './modalCancel'

const sortTasksByPosition = tasks =>
  tasks?.sort((a, b) => {
    if (a?.position < b?.position) {
      return -1
    }
    if (a?.position > b?.position) {
      return 1
    }
    return 0
  }) || []

export default function TaskAndShiftForm({
  event,
  setEventProp,
  open,
  invalidTaskLeaders,
}) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()

  // const [event] = React.useState(pevent);

  const createShift = React.useCallback(
    () => ({
      start_time: null,
      end_time: null,
      volunteers_needed: '',
    }),
    [event],
  )
  const createTask = React.useCallback(
    (previousPosition = -1) => ({
      uuid: uuid(),
      position: previousPosition + 1,
      shifts: [createShift()],
      title: '',
      task_leader_users: [],
    }),
    [],
  )

  const initialTaskList = [createTask()]
  const [adminPickerOpen, setAdminPickerOpen] = React.useState(false)
  const [isCanceling, setIsCanceling] = React.useState(false)
  const [isClearing, setIsClearing] = React.useState(false)
  const [isValid, setIsValid] = React.useState(true)
  const [taskGettingAdmins, setTaskGettingAdmins] = React.useState(null)
  const [originalTasks, setOriginalTasks] = React.useState(() => {
    if (event.tasks.length > 0) {
      return [...event.tasks]
    }
    return initialTaskList
  })
  const [tasks, _setTasks] = React.useState(
    sortTasksByPosition(JSON.parse(JSON.stringify(originalTasks))),
  )

  const setTasks = React.useCallback(
    newTasks => {
      // sort by position
      newTasks.sort((a, b) => {
        if (a.position < b.position) {
          return -1
        }
        if (a.position > b.position) {
          return 1
        }
        return 0
      })

      // renumber positions
      const repositionedTasks = [...newTasks]
      for (let i = 0; i < newTasks.length; i += 1) {
        repositionedTasks[i].position = i
      }
      _setTasks(repositionedTasks)
    },
    [tasks],
  )

  const activeTasks = React.useMemo(
    () => tasks.filter(task => !task._destroy),
    [tasks],
  )

  React.useEffect(() => {
    if (event.tasks.length > 0) {
      setTasks(sortTasksByPosition(JSON.parse(JSON.stringify(event.tasks))))
    }
  }, [event])

  const onDragEnd = React.useCallback(
    result => {
      if (!result.destination) {
        return
      }
      if (result.destination.index === result.source.index) {
        return
      }
      const newTasks = [...tasks]
      const [reorderedTask] = newTasks.splice(result.source.index, 1)
      newTasks.splice(result.destination.index, 0, reorderedTask)
      setTasks(newTasks.map((task, index) => ({ ...task, position: index })))
    },
    [tasks],
  )

  const dirtyForm = React.useCallback(
    onlyCheckParity => {
      if (onlyCheckParity) {
        return JSON.stringify(originalTasks) !== JSON.stringify(tasks)
      }
      const firstTaskNotEmpty =
        tasks[0].title !== '' || tasks[0].shifts[0].volunteers_needed !== ''

      return tasks.length > 1 || firstTaskNotEmpty
    },
    [tasks],
  )

  const validateForm = React.useCallback(
    isDoneAvailable => {
      let valid = true
      // Validate tasks
      if (isDoneAvailable) {
        if (
          tasks.length === 0 || // no tasks
          !tasks[0].title || // first task has no title
          !tasks[0].shifts[0].volunteers_needed || // first tasks first shift has no volunteers needed
          parseInt(tasks[0].shifts[0].volunteers_needed, 10) < 1 // first tasks first shift has less than 1 volunteer needed
        ) {
          return false
        }
      } else {
        for (let tIdx = 0; tIdx < tasks.length; tIdx += 1) {
          const task = tasks[tIdx]
          if (task._destroy) {
            // if deleted task, skip
            continue
          }
          if (!task.title) {
            valid = false
          }
          for (let sIdx = 0; sIdx < task.shifts.length; sIdx += 1) {
            const shift = task.shifts[sIdx]
            if (shift._destroy) {
              // if deleted shift, skip
              continue
            }
            const errors = []
            if (
              !shift.volunteers_needed ||
              parseInt(shift.volunteers_needed, 10) < 1
            ) {
              errors.push('volunteers')
            }
            if (
              moment(shift.end_time).isSameOrBefore(moment(shift.start_time)) ||
              moment(shift.end_time).isSameOrBefore(
                moment(event.event_start_date),
              ) ||
              moment(shift.end_time).isAfter(moment(event.event_end_date))
            ) {
              errors.push('end_time')
            }
            if (
              moment(shift.start_time).isBefore(moment(event.event_start_date))
            ) {
              errors.push('start_time')
            }
            task.shifts[sIdx].errors = errors
            if (errors.length) {
              valid = false
            }
          }
        }
      }
      return valid
    },
    [tasks],
  )

  const onSave = React.useCallback(() => {
    if (!validateForm()) {
      setIsValid(false)
      return
    }
    const tasksAttributes = tasks.map(task => ({
      ...(task.id ? { id: task.id } : {}),
      title: task.title,
      task_leader_users: task.task_leader_users,
      position: task.position,
      _destroy: task._destroy,
      shifts: task.shifts.map(shift => ({
        ...(shift.id ? { id: shift.id } : {}),
        start_time: moment(shift.start_time).toDate(),
        end_time: moment(shift.end_time).toDate(),
        volunteers_needed: shift.volunteers_needed,
        _destroy: shift._destroy,
      })),
    }))
    setEventProp('tasks', tasksAttributes)
    open(false)
  }, [tasks, open, setEventProp])

  const clearTasks = React.useCallback(() => {
    setTasks(initialTaskList)
    setOriginalTasks(initialTaskList)
    setEventProp('tasks', [])
    setIsClearing(false)
  }, [tasks])

  const updateTasks = React.useCallback(
    (position, key, value) => {
      const newTasks = [...tasks]
      const index = newTasks.findIndex(task => task.position === position)
      newTasks[index][key] = value
      setTasks(newTasks)
      if (validateForm()) {
        setIsValid(true)
      }
    },
    [event, tasks, setTasks],
  )

  const updateShiftProp = (task, shift, key, value) => {
    const shifts = [...task.shifts]
    const index = shifts.findIndex(s => s === shift)
    shifts[index][key] = value
    updateTasks(task.position, 'shifts', shifts)
  }

  const closeTaskAndShift = React.useCallback(() => {
    if (dirtyForm(true)) {
      setIsCanceling(true)
    } else {
      open(false)
    }
  }, [event, tasks])

  const reorderTasks = React.useCallback(taskList =>
    taskList.map((task, index) => ({
      ...task,
      position: index,
    })),
  )

  const deleteTask = React.useCallback(
    taskPosition => {
      let newTasks = [...tasks]
      const index = newTasks.findIndex(task => task.position === taskPosition)
      const task = newTasks[index]
      newTasks.splice(index, 1)
      newTasks = [...newTasks, { ...task, _destroy: true }]
      setTasks(reorderTasks(newTasks))
    },
    [event, tasks, setTasks],
  )

  const onAddAdmin = React.useCallback(
    (task, leader) => {
      if (task.task_leader_users.length >= 2) {
        return
      }
      const newTasks = [...tasks]
      const index = newTasks.findIndex(_t => _t.position === task.position)
      newTasks[index].task_leader_users.push(leader)
      setTasks(newTasks)
      setTaskGettingAdmins(newTasks[index])
    },
    [taskGettingAdmins, tasks, adminPickerOpen],
  )

  const onRemoveAdmin = React.useCallback(
    (task, leader) => {
      const newTasks = [...tasks]
      const index = newTasks.findIndex(_t => _t.position === task.position)
      newTasks[index].task_leader_users = newTasks[
        index
      ].task_leader_users.filter(_leader => _leader.id !== leader.id)
      setTasks(newTasks)
      setTaskGettingAdmins(newTasks[index])
    },
    [taskGettingAdmins, tasks, adminPickerOpen],
  )

  const onAdminPickerOpen = task => {
    setTaskGettingAdmins(task)
    setAdminPickerOpen(true)
  }

  const onAdminPickerClose = () => {
    setAdminPickerOpen(false)
    setTaskGettingAdmins(null)
  }

  const onClearClose = React.useCallback(() => {
    setIsClearing(false)
  }, [setIsClearing])

  const onCancelClose = React.useCallback(() => {
    setIsCanceling(false)
  }, [setIsCanceling])

  return (
    <div className="container">
      <TasksHeader
        closeTaskAndShift={closeTaskAndShift}
        event={event}
        isValid={isValid}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tasklist">
          {dropProvided => (
            <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              {activeTasks.map((task, index) => {
                const id = task?.id || task?.uuid || 0
                const invalidTitle = !isValid && !task.title?.length
                return (
                  <Draggable
                    draggableId={id.toString() ?? index.toString()}
                    index={index}
                    key={`task-${id}`}
                  >
                    {(dragProvided, snapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        <div
                          className="row g-0"
                          style={{
                            transition: 'all 0.2s ease',
                            ...(snapshot.isDragging
                              ? {
                                  backgroundColor: '#FBFBFB',
                                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                                }
                              : {}),
                          }}
                        >
                          <TaskRow
                            activeTasks={activeTasks}
                            createShift={createShift}
                            createTask={createTask}
                            deleteTask={deleteTask}
                            event={event}
                            index={index}
                            invalidTaskLeaders={invalidTaskLeaders}
                            invalidTitle={invalidTitle}
                            onAdminPickerOpen={onAdminPickerOpen}
                            setTasks={setTasks}
                            task={task}
                            tasks={tasks}
                            updateShiftProp={updateShiftProp}
                            updateTasks={updateTasks}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <TasksFooter
        onClear={() => setIsClearing(true)}
        clearDisabled={!dirtyForm()}
        onDone={onSave}
        doneDisabled={!validateForm(true)}
        onCancel={closeTaskAndShift}
      />
      <AdminPicker
        admins={taskGettingAdmins?.task_leader_users}
        groups={event.is_private ? event.groups : undefined}
        isOpen={adminPickerOpen}
        onAddAdmin={leader => onAddAdmin(taskGettingAdmins, leader)}
        onClose={onAdminPickerClose}
        onRemoveAdmin={leader => onRemoveAdmin(taskGettingAdmins, leader)}
        showYourself
        searchTitle={t('event.add_task_leader_limit', { seats: 2 })}
      />
      <ModalCancel
        isCanceling={isCanceling}
        onCancelClose={onCancelClose}
        onCancelContinue={() => open(false)}
      />
      <ModalClear
        isClearing={isClearing}
        onClearClose={onClearClose}
        clearTasks={clearTasks}
      />
    </div>
  )
}

TaskAndShiftForm.propTypes = {
  event: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  setEventProp: PropTypes.func.isRequired,
  invalidTaskLeaders: PropTypes.array,
}
