import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { restoreState, storeState } from '../lib/localstorage'
import Button from '../components/button'
import EventCard from '../components/event-card'
import Loading from '../components/loading'
import Icon from '../components/icon'
import useApi from '../hooks/useApi'

export default function MyPosts() {
  const { getCurrentUserEvents } = useApi()
  const { t } = useTranslation()

  const [events, setEvents] = useState(() => restoreState('events', []))
  const [isLoading, setIsLoading] = useState(true)
  const [needs, setNeeds] = useState(() => restoreState('needs', []))

  const fetchData = async () => {
    const { data } = await getCurrentUserEvents()
    if (data) {
      const userEvents = data
        .filter(event => event.event_type !== 'need')
        .sort(
          (a, b) =>
            new Date(b.event_start_date).getTime() -
            new Date(a.event_start_date).getTime(),
        )
      setEvents(userEvents)
      const userNeeds = data
        .filter(event => event.event_type === 'need')
        .sort(
          (a, b) =>
            new Date(b.event_start_date).getTime() -
            new Date(a.event_start_date).getTime(),
        )
      setNeeds(userNeeds)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (needs.length || events.length) {
      setIsLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    storeState('events', events)
    storeState('needs', needs)
  }, [events, needs])

  if (isLoading) {
    return <Loading show={isLoading} />
  }

  return (
    <>
      <Container>
        <Row>
          <h1 className="fs-1 pt-4">{t('navigation_banner.my_posts')}</h1>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row>
          <Col>
            {!events.length && !needs.length && (
              <Row>
                <div className="col-lg-4">
                  <Card>
                    <Card.Body className="eventTypes">
                      <div className="typeIndicator btn-outline-danger">
                        <Icon name={'bullhorn'} />
                      </div>
                      <h5 className="card-title">
                        {t('event.needs_announce')}
                      </h5>
                      <p className="card-text">{t('event.report_need')}</p>
                      <Link to="/events/new?eventType=need">
                        <Button>{t('event.create_post')}</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-lg-4">
                  <Card>
                    <Card.Body className="eventTypes">
                      <div className="typeIndicator btn-outline-danger">
                        <Icon name={'hand-paper'} />
                      </div>
                      <h5 className="card-title">
                        {t('event.volunteer_opportunity')}
                      </h5>
                      <p className="card-text">{t('event.call_to_action')}</p>
                      <Link to="/events/new?eventType=vol">
                        <Button>{t('event.create_post')}</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-lg-4">
                  <Card>
                    <Card.Body className="eventTypes">
                      <div className="typeIndicator btn-outline-danger">
                        <Icon name={'users'} />
                      </div>
                      <h5 className="card-title">{t('event.events')}</h5>
                      <p className="card-text">{t('event.rally')}</p>
                      <Link to="/events/new">
                        <Button>{t('event.create_post')}</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            )}

            {!!events.length && (
              <>
                <Row>
                  <h2 className="text-start fw-light">
                    {t('my_posts.events_and_opportunities')}
                  </h2>
                </Row>
                <Row>
                  {events.map(event => (
                    <EventCard key={event.id} event={event} />
                  ))}
                </Row>
              </>
            )}
          </Col>
        </Row>
        {!!needs.length && (
          <Row className="mt-4">
            <Col>
              <Row>
                <h2 className="text-start fw-light">
                  {t('my_posts.needs_and_announcements')}
                </h2>
              </Row>
              <Row>
                {needs.map(need => (
                  <EventCard key={need.id} event={need} />
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}
