import { useState, useEffect } from 'react'

const useFocus = (): boolean => {
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const handleFocus = (): void => setIsFocused(true)
    const handleBlur = (): void => setIsFocused(false)

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return (): void => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  return isFocused
}

export default useFocus
