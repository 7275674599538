import React, { useCallback, useEffect, useRef, useState } from 'react'

import Icon from '../icon'
import styles from './TextInput.module.scss'

interface TextInputProps {
  append?: string
  autoComplete?: string
  autoFocus?: boolean
  className?: string
  clearable?: boolean
  hideValidationMessage?: boolean
  id?: string
  inputContainerStyle?: any
  inputStyle?: any
  isInvalid?: boolean
  maxLength?: number
  min?: number
  minLength?: number
  name?: string
  onBlur?: () => void
  onChange?: (value: string) => void
  onClear?: () => void
  onFocus?: () => void
  placeholder?: string
  prepend?: string
  type?: string
  validationErrorContainerStyle?: any
  value?: string
}

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const {
    append,
    autoComplete,
    autoFocus,
    className,
    clearable,
    hideValidationMessage,
    id,
    isInvalid,
    maxLength,
    min,
    minLength,
    name,
    onBlur,
    onChange,
    onClear,
    onFocus,
    placeholder,
    prepend,
    type,
    value,
  } = props

  const [inputValue, setInputValue] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus()
    }
  }, [autoFocus, inputValue])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target?.value
      setInputValue(value)
      onChange?.(value)
    },
    [onChange],
  )

  const handleClear = useCallback(() => {
    setInputValue('')
    onClear?.()
  }, [onClear])

  const handleBlur = useCallback(() => {
    onBlur?.()
  }, [onBlur])

  const handleFocus = useCallback(() => {
    onFocus?.()
  }, [onFocus])

  return (
    <>
      <div className={styles.inputContainer} style={props.inputContainerStyle}>
        {prepend ? (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        ) : null}
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={`form-control 
                      ${isInvalid ? 'is-invalid' : ''} ${className}`}
          id={id}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          ref={inputRef}
          style={props.inputStyle}
          type={type || 'text'}
          value={inputValue}
        />
        {clearable && inputValue.length > 0 && (
          <button
            className={`btn ${styles.clearButton}`}
            onClick={handleClear}
            type="button"
          >
            <Icon name="times" />
          </button>
        )}
        {append ? (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        ) : null}
      </div>
      {!!isInvalid && !hideValidationMessage ? (
        <div
          className="invalid-feedback d-block"
          style={props.validationErrorContainerStyle}
        >
          {isInvalid}
        </div>
      ) : null}
    </>
  )
}

export default TextInput
