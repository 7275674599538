import { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
  useCreateCheckboxHandlers,
} from 'react-bs-datatable'
import { useTranslation } from 'react-i18next'

import styles from './sortTable.module.scss'
import Icon from '../icon'

export default function SortTable({
  body = [],
  headers,
  onFetch,
  showFilter = false,
  showRowsPerPage = true,
  showPagination = true,
  alwaysShowPagination = false,
  rowsPerPageProp = 10,
  rowsPerPageOptions = [5, 10, 20, 50],
  totalRows,
  onCheckboxClick,
  resetCheckbox = false,
  rowStyle = 'regular-font',
  ...rest
}) {
  const { t } = useTranslation()
  const [sortState, setSortState] = useState({
    prop: null,
    order: 'asc',
  })
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageProp)
  const [maxPage, setMaxPage] = useState(
    Math.round(Math.ceil((totalRows || body.length) / rowsPerPage)),
  )

  const idPropName = headers.find(h => h.prop === 'checkbox')?.checkbox?.idProp

  const handleCheckboxChange = useCallback(nextState => {
    const {
      nextCheckboxState: { selected },
    } = nextState
    onCheckboxClick([...selected])
    // console.log('selected', selected)
  }, [])

  const onSortChange = useCallback(nextProp => {
    setSortState(nextProp)
  }, [])

  const onFilterChange = useCallback(text => {
    setFilter(text)
    setCurrentPage(1)
  }, [])

  const onPaginationChange = useCallback(nextPage => {
    setCurrentPage(nextPage)
  }, [])

  const onRowsPerPageChange = useCallback(rowsPerPage => {
    setRowsPerPage(rowsPerPage)
    setCurrentPage(1)
  }, [])

  // Simulate API hit.
  useEffect(() => {
    onFetch({ sortState, filter, currentPage, rowsPerPage })
    setMaxPage(Math.round(Math.ceil((totalRows || body.length) / rowsPerPage)))
  }, [sortState, filter, currentPage, rowsPerPage])

  return (
    <DatatableWrapper
      body={body}
      headers={headers.map(h => ({
        ...h,
        thProps: {
          className: h?.thProps?.className ?? styles.sortTableBg,
        },
      }))}
      checkboxProps={{
        onCheckboxChange: handleCheckboxChange,
      }}
      {...rest}
    >
      <Table className="m-0">
        <TableHeader
          controlledProps={{
            sortState,
            onSortChange,
          }}
          classes={{
            thead: styles.sortTableThead,
            tr: styles[rowStyle],
          }}
        />
        <TableBody
          classes={{
            tbody: styles.sortTableThead,
            td: styles.sortTableBg,
            tr: styles[rowStyle],
          }}
        />
      </Table>
      {(showFilter || showPagination) && (
        <Row className="mb-4">
          {showFilter && (
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter controlledProps={{ filter, onFilterChange }} />
            </Col>
          )}
          {showRowsPerPage && showPagination && (
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions
                controlledProps={{
                  onRowsPerPageChange,
                  rowsPerPageOptions,
                  rowsPerPage,
                }}
                labels={{
                  beforeSelect: t('table.rowsPerPage'),
                }}
              />
            </Col>
          )}
          {showPagination && (
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Pagination
                controlledProps={{ currentPage, maxPage, onPaginationChange }}
                labels={{
                  firstPage: <Icon name={'arrow-alt-to-left'} />,
                  prevPage: t('table.prevPage'),
                  nextPage: t('table.nextPage'),
                  lastPage: <Icon name={'arrow-alt-to-right'} />,
                }}
                alwaysShowPagination={alwaysShowPagination}
                classes={{
                  buttonGroup: 'pagination-button',
                }}
              />
            </Col>
          )}
          {!!idPropName ? (
            <Col xs={12} className="mt-2">
              <BulkCheckboxControl />
            </Col>
          ) : null}
        </Row>
      )}
      {!!idPropName ? (
        <Row>
          <Col xs={12}>
            <StrayResetSelectionButton
              idPropName={idPropName}
              resetCheckbox={resetCheckbox}
            />
          </Col>
        </Row>
      ) : null}
    </DatatableWrapper>
  )
}

function StrayResetSelectionButton({ idPropName, resetCheckbox }) {
  const { createBulkCheckboxClickHandler } = useCreateCheckboxHandlers()

  const removeAll = useRef(null)
  // Clear all checkboxes
  useEffect(() => {
    if (resetCheckbox) {
      removeAll.current.click()
    }
  }, [resetCheckbox])

  return (
    <div className="d-none">
      <button
        onClick={createBulkCheckboxClickHandler('add', {
          checkboxProp: 'checkbox',
          idProp: idPropName,
        })}
      >
        Add all to selection
      </button>
      <button
        onClick={createBulkCheckboxClickHandler('remove', {
          checkboxProp: 'checkbox',
          idProp: idPropName,
        })}
        ref={removeAll}
      >
        Reset selection
      </button>
    </div>
  )
}
