export const civ = {
  fee_kind: {
    percentage: 'percentage',
    flat: 'flat',
    id: null,
  },
  empty: {
    fee_kind: 'flat',
    value: 0,
    id: null,
  },
}

export default civ
