import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './feed.scss'

export default function GroupFeed({ group }) {
  const { t } = useTranslation()
  return (
    <Link to={`/groups/${group?.slug ?? group.id}`}>
      <div
        className="thumbnail position-relative"
        style={{ backgroundImage: `url(${group?.image_url})` }}
      >
        <div className="p-1 position-absolute bottom-0 start-0">
          <p className="fw-bold">{group?.name}</p>
          <p>
            {t('feed.my_groups.updates', {
              count: group?.activities_count ?? 0,
            })}
          </p>
        </div>
      </div>
    </Link>
  )
}
