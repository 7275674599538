import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Icon from '../icon'
import CivCard from './civ-card'

interface FundingCardProps {
  bankAccount: any
  data: any
  onClick: () => void
}

export default function FundingCard(props: FundingCardProps) {
  const { t } = useTranslation()

  return (
    <CivCard
      header={''}
      body={
        <Row className="fs-3">
          <Col className="text-center">
            <Icon name="landmark" className="business-title-icon" />
          </Col>
          <Col className="text-start ps-0">
            {props.bankAccount?.bank_account_number_last4
              ? `****${props.bankAccount?.bank_account_number_last4}`
              : t('civ_wallet.funding_card.placeholder')}
          </Col>
        </Row>
      }
      footer={''}
      buttonText={
        <>
          <Icon
            name={props.data?.bank_account_id ? 'edit' : 'plus'}
            className="business-title-icon"
          />
          {props.data?.bank_account_id
            ? t('civ_wallet.funding_card.editButton')
            : t('civ_wallet.funding_card.addButton')}
        </>
      }
      buttonOnClick={props.onClick}
      buttonDisabled={false}
    />
  )
}
