import moment from 'moment-timezone'
import { Trans } from 'react-i18next'

export const pledgeState = (pledge, translationKey) =>
  pledge.state === 'pending' ? (
    <Trans
      i18nKey={`${translationKey}.expireTime`}
      values={{
        time: pledge.business_days_until_expiration,
      }}
    />
  ) : (
    <Trans i18nKey={`organizer.sponsorPledge.status.${pledge.state}`} />
  )

export default {
  pledgeState,
}
