import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React from 'react'

export default function NotFound(props) {
  const { redirectTo, showAuthLinks, suggestAuth, type } = props
  const { t } = useTranslation()

  return (
    <>
      <p>
        <b>
          {suggestAuth
            ? t(`not-found.auth-${type}`)
            : t(`not-found.not-found-${type}`)}
        </b>
      </p>
      {showAuthLinks && (
        <p>
          <Link to={`/login?redirect_to=${redirectTo}`}>
            {t('not-found.sign-in')}
          </Link>{' '}
          {t('not-found.or')}{' '}
          <Link to={`/signup?redirect_to=${redirectTo}`}>
            {t('not-found.sign-up')}
          </Link>
        </p>
      )}
    </>
  )
}

NotFound.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  showAuthLinks: PropTypes.bool.isRequired,
  suggestAuth: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}
