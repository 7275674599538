import moment from 'moment'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'

export default function CharityStatusHistory({ status }) {
  const { t } = useTranslation()
  return (
    <>
      <hr />
      <Row>
        <Col>
          <Trans
            i18nKey="accounting.charityRegistration.modal.status_update"
            values={{
              date: moment(status?.changed_at).format(t('config.date_format')),
            }}
          />
        </Col>
        <Col className="text-center">
          <Trans
            i18nKey="accounting.charityRegistration.modal.updated_by"
            values={{
              name: status?.changed_by,
            }}
          />
        </Col>
        <Col className="text-end">
          <Trans
            i18nKey="accounting.charityRegistration.modal.civic_rush_status"
            values={{
              status: t(
                `crStatus.${status?.new_status
                  .toLowerCase()
                  .replaceAll(' ', '_')}`,
              ),
            }}
          />
        </Col>
      </Row>
    </>
  )
}
