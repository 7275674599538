import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'

import LoginForm from './LoginForm'

interface LoginModalProps {
  show: boolean
  onClose: (flag?: boolean) => void
  returnUrl?: string
}

export default function LoginModal(props: LoginModalProps) {
  useEffect(() => {
    if (props.returnUrl?.length) {
      localStorage.setItem('return-url', props.returnUrl)
    }
  }, [])

  return (
    <Modal show={props.show} onHide={() => props.onClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoginForm isModal onClose={props.onClose} />
      </Modal.Body>
    </Modal>
  )
}
