import { useNavigate, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import useApi from './useApi'
import useFocus from './useFocus'

export const CurrentUserContext = createContext()

export function CurrentUserProvider({ children }) {
  const {
    currentUser,
    getBusiness,
    getUser,
    isSessionValid,
    setCurrentUser,
    signInWithEmail,
    signOut,
  } = useApi()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const isFocused = useFocus()

  const [business, setBusiness] = useState(null)

  const fetchUser = useCallback(async () => {
    if (currentUser?.id) {
      const { data } = await getUser({ userId: currentUser?.id })
      setCurrentUser(data)
    }
  }, [getUser])

  const fetchBusiness = useCallback(async () => {
    if (currentUser?.business_id) {
      const { data } = await getBusiness(currentUser?.business_id)
      setBusiness(data)
    }
  }, [currentUser?.business_id])

  const locale = useMemo(
    () =>
      (
        searchParams.get('locale') ||
        currentUser?.language ||
        window.navigator?.language ||
        window.navigator?.userLanguage
      )?.split('-')?.[0] || 'en',
    [currentUser?.language, searchParams],
  )

  const betaFeatures = useMemo(
    () =>
      currentUser?.beta_features?.map(betaFeature => betaFeature.name) || [],
    [currentUser?.beta_features],
  )

  const hasBetaFeature = useCallback(
    feature => betaFeatures.includes(feature),
    [betaFeatures],
  )

  const context = useMemo(
    () => ({
      betaFeatures,
      business,
      currentUser,
      fetchBusiness,
      fetchUser,
      hasBetaFeature,
      locale,
      setCurrentUser,
      signInWithEmail,
      signOut,
    }),
    [
      betaFeatures,
      business,
      currentUser,
      fetchBusiness,
      fetchUser,
      hasBetaFeature,
      locale,
      setCurrentUser,
      signInWithEmail,
      signOut,
    ],
  )

  const onFocus = useCallback(async () => {
    // check if user session is still valid
    if (!!currentUser?.id && !(await isSessionValid())) {
      signOut()
      navigate('/login')
    }
  }, [currentUser, isSessionValid, signOut, navigate])

  useEffect(() => {
    if (isFocused) {
      onFocus()
    } else {
      // onblur
    }
  }, [isFocused])

  useEffect(() => {
    fetchBusiness()
  }, [fetchBusiness])

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  )
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useCurrentUser = () => useContext(CurrentUserContext)
