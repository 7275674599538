export function buildAddressString(location) {
  let address = ''
  if (location) {
    address = location.name
    if (
      location.formatted_address?.length > 0 &&
      location.formatted_address !== location.name
    ) {
      address = `${address}, ${location.formatted_address}`
    }
    // if (location.city?.length > 0 && location.city !== location.name) {
    //   address = `${address}, ${location.city}`;
    // }
    // if (location.state?.length > 0 && location.state !== location.name) {
    //   address = `${address}, ${location.state}`;
    // }
  }

  return address
}

export function formatLocation(
  location,
  appendState = false,
  appendZip = false,
) {
  if (location) {
    const l = []
    if (location.google_description) {
      return location.google_description
    }
    if (location.formatted_address) {
      l.push(location.formatted_address)
    }
    if (location.name && location.name !== location.formatted_address) {
      l.push(location.name)
    }
    if (appendState && location.state) {
      l.push(location.state)
    }
    if (appendZip && location.zip) {
      l.push(location.zip)
    }
    return l.join(', ')
  }
  return ''
}

export function charityAddress(address) {
  if (!address) {
    return ''
  }
  const l = []
  if (address.address_line_1) {
    l.push(address.address_line_1)
  }
  if (address.address_line_2) {
    l.push(address.address_line_2)
  }
  if (address.city) {
    l.push(address.city)
  }
  if (address.state) {
    l.push(address.state)
  }
  if (address.zip) {
    l.push(address.zip)
  }
  return l.join(', ')
}

export default {
  buildAddressString,
  formatLocation,
  charityAddress,
}
