import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { useEffect, useState } from 'react'
import useApi from '../../../hooks/useApi'

export default function ModalSignUp({
  show,
  onClose,
  onClick,
  event,
  setRefresh,
}) {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const [shiftCheck, setShiftCheck] = useState([])
  const [signedUpShifts, setSignedUpShifts] = useState([])
  const [disabledActions, setDisabledActions] = useState(false)

  const { updateEventVolunteers } = useApi()

  const renderShiftTime = shift => {
    const startTime = moment.tz(shift.start_time, moment.tz.guess())
    const endTime = moment.tz(shift.end_time, moment.tz.guess())
    let time = `${startTime.format('h:mm A')} ${t(
      'event_card.to',
    )} ${endTime.format('h:mm A')}`
    time = `${startTime.format(t('config.short_date_format'))} · ${time}`
    return time
  }

  const editShift = (shift_id, checked) => {
    if (checked) {
      setSignedUpShifts([...signedUpShifts, shift_id])
    } else {
      const myShifts = [...signedUpShifts]
      myShifts.splice(myShifts.indexOf(shift_id), 1)
      setSignedUpShifts([...myShifts])
    }
  }

  const onSignUp = () => {
    const leaderShifts = event.tasks
      .filter(l =>
        l.task_leader_users.map(tl => tl.id).includes(currentUser.id),
      )
      .map(t => t.shifts.map(s => s.id))
      .flat()

    const allShifts = event.tasks.reduce((s, task) => s.concat(task.shifts), [])
    const shifts = [...leaderShifts, ...signedUpShifts].map(id =>
      allShifts.find(s => s.id === id),
    )

    const conflict = shifts.reduce((isBad, shift) => {
      if (isBad) {
        return isBad
      }

      const startConflict = shifts.some(
        s =>
          s.id !== shift.id &&
          moment.tz(shift.start_time, moment.tz.guess()) >=
            moment.tz(s.start_time, moment.tz.guess()) &&
          moment.tz(shift.start_time, moment.tz.guess()) <
            moment.tz(s.end_time, moment.tz.guess()),
      )

      if (startConflict) {
        return true
      }

      return false
    }, false)

    if (conflict) {
      alert(t('event.tasks_and_shifts_modal.scheduleConflictMessage'))
      return
    }

    updateEventVolunteers(event.id, { shift_ids: signedUpShifts }).then(
      response => {
        if (response.success || response.status === 200) {
          setRefresh(+new Date())
          onClose()
        } else {
          alert(t('event.error_signup'))
        }
      },
    )
  }

  useEffect(() => {
    const allShifts = event.tasks
      ?.map(t =>
        t.shifts?.map(s => ({
          id: s.id,
          users: s.volunteers.map(v => v.user.id),
        })),
      )
      .flat()
    setShiftCheck(allShifts)
    setSignedUpShifts(
      allShifts.filter(s => s.users.includes(currentUser.id)).map(m => m.id),
    )
    const disabledUsers = event.admins?.map(a => a.id).concat(event.user.id)
    setDisabledActions(disabledUsers.includes(currentUser.id))
  }, [])

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">
          {t('event.tasks_and_shifts')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-4">
        {event.tasks.map(task => (
          <div key={task.id} className="task-box">
            <div className="task-title">
              {task.title}
              {task.task_leader_users.length && (
                <div>
                  <span className="fw-semibold">
                    {t('event.tasks_and_shifts_modal.task_leaders')}
                  </span>
                  <span>
                    {task.task_leader_users.map(l => l.name).join(', ')}
                  </span>
                </div>
              )}
            </div>
            {task.shifts.map(shift => (
              <div key={shift.id} className="shift-box">
                <div className="shift-text shift-time">
                  {renderShiftTime(shift)}
                </div>
                <div className="shift-text shift-numbers">
                  {`${shift.volunteers.length} ${t('event_card.of')} ${
                    shift.volunteers_needed
                  }`}
                </div>
                <div className="shift-check">
                  <input
                    type="checkbox"
                    checked={signedUpShifts.includes(shift.id)}
                    className="form-check-input chk-checkbox"
                    id={`shift-chk-${shift.id}`}
                    name={`shift-chk-${shift.id}`}
                    onChange={e => editShift(shift.id, e.target.checked)}
                    disabled={
                      disabledActions ||
                      task.task_leader_users.some(
                        l => l.id === currentUser.id,
                      ) ||
                      (shift.volunteers.length === shift.volunteers_needed &&
                        shift.volunteers.findIndex(
                          f => f.user?.id === currentUser.id,
                        ) === -1)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer className="mx-4">
        <Button onClick={onSignUp} disabled={disabledActions}>
          {t('event.tasks_and_shifts_modal.button')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
