import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import CharitySearchList from './list'
import Styles from '../charities.module.scss'
import UserCharitiesList from '../userCharitiesList'
import Badge from '../../../components/badge'

export default function CharitiesIndexPage() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  return (
    <div className="container">
      <div className={Styles.charityIndexPage}>
        <div className="row">
          <div className="col-lg-7">
            <h1 className="fs-1 m-0 badge-container">
              {t('charities.search.title')}
              <Badge />
            </h1>
            <small>{t('charities.search.message')}</small>
            <CharitySearchList initialQuery={searchParams.get('search')} />
          </div>
          <div className="col-lg-4 offset-lg-1">
            <UserCharitiesList />
          </div>
        </div>
      </div>
    </div>
  )
}
