import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Buffer } from 'buffer'

import Icon from '../../components/icon'
import useApi from '../../hooks/useApi'
import upload from '../../constants/upload'

export default function AvatarImage({
  imageUrl,
  mediaAttachmentType,
  onChange,
}) {
  const { i18n, t } = useTranslation()

  const [uploading, setUploading] = useState(false)
  const uploadImage = 'images/uploading-image.gif'

  const {
    getMediaAttachmentUploadSignedUrl,
    uploadFileToS3,
    createMediaAttachment,
  } = useApi()

  const hiddenFileInput = useRef(null)
  const handleClickAvatar = event => {
    hiddenFileInput.current.click()
  }

  const imageOnChange = e => {
    const file = e.target?.files?.[0]
    if (!file) {
      return
    }
    setUploading(true)
    const fileUpload = new Promise((resolve, reject) => {
      if (file.size >= upload.sizeLimit) {
        alert('File size must be less than 120MB')
        reject()
        return
      }
      const reader = new FileReader()
      reader.onload = loaded => {
        resolve({
          fileObject: file,
          originalFile: Buffer.from(
            loaded.target.result.replace(/^data:image\/\w+;base64,/, ''),
            'base64',
          ),
          direct_upload_url: loaded.target.result,
          uploading: true,
          uploadId:
            performance.now().toString(36) +
            Math.random().toString(36).substr(2),
          upload_content_type: file.type,
          upload_file_name: file.name,
          upload_file_size: file.size,
          media_attachment_type: mediaAttachmentType,
        })
      }
      reader.readAsDataURL(file)
    }).then(async fileResponse => {
      const signedUrlResponse = await getMediaAttachmentUploadSignedUrl({
        filename: fileResponse.upload_file_name,
      })
      const { data } = signedUrlResponse
      const uploadResponse = await uploadFileToS3(
        data.upload.url,
        fileResponse.fileObject,
        data.upload.content_type,
      )
      if (uploadResponse.status === 200) {
        const uploadData = { ...fileResponse }
        delete uploadData.uploading
        delete uploadData.uploadId
        delete uploadData.originalFile
        ;[uploadData.direct_upload_url] = data.upload.url?.split('?') ?? ''

        const createResponse = await createMediaAttachment({
          media_attachment: uploadData,
        })
        if (createResponse.status === 201) {
          onChange(createResponse.data)
        }
      }
      setUploading(false)
    })
  }

  return (
    <div className="square">
      <div className="avatar-camera-content">
        <button
          type="button"
          className="btn btn-link m-0 p-0"
          onClick={handleClickAvatar}
        >
          <Icon name="camera" className="avatar-camera-icon" />
        </button>
        <input
          id="picture"
          className="hidden"
          onChange={imageOnChange}
          ref={hiddenFileInput}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
        />
      </div>
      <div className="square-content">
        <img
          alt="Avatar"
          className="rounded-circle avatar-border bg-white"
          src={uploading ? uploadImage : imageUrl}
        />
      </div>
    </div>
  )
}
