import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Icon from '../icon'
import CivCard from './civ-card'

interface BalanceCardProps {
  origin: string
  wallet: any
  data: any
  onClick: () => void
}

export default function BalanceCard(props: BalanceCardProps) {
  const { t } = useTranslation()

  return (
    <CivCard
      header={t('civ_wallet.civ_card.top_left_corner')}
      body={
        <div className="flex-column">
          <Card.Title className="text-center fs-2">
            {!!props.wallet?.balance
              ? new Intl.NumberFormat('en-US').format(props.wallet?.balance)
              : t('civ_wallet.civ_card.placeholder')}
          </Card.Title>
          <Card.Subtitle className="text-center text-muted">
            {t('civ_wallet.civ_card.balance')}
          </Card.Subtitle>
        </div>
      }
      footer={t('civ_wallet.civ_card.bottom_right_corner')}
      buttonText={
        props.origin === 'user' ? (
          <>
            <Icon name="gift" className="business-title-icon" />
            {t('civ_wallet.donation.button')}
          </>
        ) : (
          <>
            <Icon name="box-heart" className="business-title-icon" />
            {t('civ_wallet.civ_card.button')}
          </>
        )
      }
      buttonOnClick={props.onClick}
      buttonDisabled={
        props.origin === 'user'
          ? !props.wallet?.balance
          : !props.data?.bank_account_id
      }
    />
  )
}
