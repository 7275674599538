import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import AccountSidebar from './sidebar'
import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function ResetPasswordPage() {
  const { updatePassword } = useApi()
  const { locale } = useCurrentUser()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    const formData = new FormData(event.currentTarget)
    const response = await updatePassword({
      newPassword: formData.get('password'),
      confirmPassword: formData.get('user[password_confirmation]'),
      resetPasswordToken: searchParams.get('reset_password_token'),
      email: formData.get('email'),
      locale,
    })
    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(response.errors.full_messages?.join('\n'))
      setIsLoading(false)
    } else {
      navigate('/login')
    }
  }

  return (
    <div className="container" id="loginForm">
      <div className="row" style={{ paddingBottom: '60px' }}>
        <div className="col-sm-6">
          <AccountSidebar />
        </div>
        <div className="col-sm-4 offset-sm-1" style={{ paddingTop: '60px' }}>
          <h2>{t('devise.passwords.edit.change')}</h2>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="user_password">
                {t('devise.passwords.edit.new')}
              </label>
              <br />
              <small>
                <em>{t('devise.passwords.edit.length', { chars: 6 })}</em>
              </small>
              <input
                name="password"
                id="user_password"
                type="password"
                autoFocus
                className="form-control"
              />
            </div>

            <div classNmae="form-group">
              <label htmlFor="user_password_confirmation">
                {t('devise.passwords.edit.confirm')}
              </label>
              <input
                name="user[password_confirmation]"
                id="user_password_confirmation"
                type="password"
                className="form-control"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block spaced"
              disabled={isLoading}
            >
              {t('devise.passwords.edit.change_it')}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
