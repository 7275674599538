import React from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input/input'

export function formatPhoneNumberForDisplay(phoneNumberString) {
  if (!phoneNumberString) {
    return ''
  }
  if (phoneNumberString.includes('+1')) {
    return formatPhoneNumberIntl(phoneNumberString)
  }
  return `+1 ${phoneNumberString}`
}

export function formatPhoneNumberForSave(phoneNumberString) {
  return phoneNumberString.replace('+1', '').replace(/\D/g, '')
}

export default {
  formatPhoneNumberForDisplay,
  formatPhoneNumberForSave,
}
