import { Modal } from 'react-bootstrap'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'

import useApi from '../../../../hooks/useApi'
import Badge from '../../../../components/badge'
import Button from '../../../../components/button'
import Icon from '../../../../components/icon'
import PledgeHeader from '../../../../components/SponsorPledgeModal/PledgeHeader'
import SponsorPledgeForm from './SponsorPledgeForm'
import SponsorMessageForm from './SponsorMessageForm'
import styles from '../sponsorship.module.scss'
import civ from '../../../../constants/civ'
import { feeValue, formatAmount } from '../../../../lib/numbers'
import UseSettings from '../../../../hooks/useSettings'

interface SponsorPledgeModalProps {
  business?: any
  event?: any
  onClose: () => void
  show: boolean
}

type Step = 'pledge' | 'message' | 'success'

export default function SponsorshipPledgeModal(props: SponsorPledgeModalProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { pledgeExpirationDays } = UseSettings()

  const { createTmpSponsorshipPledge, getAdminFee, updateBusiness } = useApi()

  const [pledgeAmount, setPledgeAmount] = useState<string>('')
  const [step, setStep] = useState<Step>('pledge')

  const [adminFee, setAdminFee] = useState(civ.empty)
  const [serviceFee, setServiceFee] = useState(civ.empty)
  const [currentAdminFee, setCurrentAdminFee] = useState(0)
  const [currentServiceFee, setCurrentServiceFee] = useState(0)

  const onSubmit = useCallback(
    async (message: string) => {
      const amount = parseFloat(pledgeAmount)
      const pledgeData = {
        event_id: props.event?.id,
        civ_pledge: {
          amount,
          message_to_event_owner: message,
          civillos_admin_fee_id: adminFee.id,
          meta_admin_fee_id: serviceFee.id,
        },
      }
      const response = await createTmpSponsorshipPledge(pledgeData)
      const error =
        //@ts-ignore
        response?.error?.response?.data?.error || response?.error?.message
      if (error) {
        alert(error)
      } else {
        const params = {
          business: {
            volunteer_pay_forward: true,
          },
        }
        await updateBusiness(props.business.id, params)
        setStep('success')
      }
    },
    [pledgeAmount, props.event, props.business],
  )

  const closeModal = useCallback(() => {
    setStep('pledge')
    setPledgeAmount('')
    props.onClose()
  }, [props.onClose])

  const handleOnClose = useCallback(() => {
    if (step === 'success') {
      closeModal()
    } else {
      const confirm = window.confirm(
        t('sponsorship.pledgeModal.confirmCloseMessage'),
      )
      if (confirm) {
        closeModal()
      }
    }
  }, [props.onClose, step])

  useEffect(() => {
    getAdminFee().then(result => {
      setAdminFee(result.data.current_fees.civillos_fee)
      setServiceFee(result.data.current_fees.meta_fee)
    })
  }, [])

  useEffect(() => {
    if (!!adminFee) {
      setCurrentAdminFee(feeValue(pledgeAmount || 0, adminFee))
    }
  }, [pledgeAmount, adminFee])

  useEffect(() => {
    if (!!serviceFee) {
      setCurrentServiceFee(feeValue(pledgeAmount || 0, serviceFee))
    }
  }, [pledgeAmount, serviceFee])

  return (
    <Modal
      onHide={handleOnClose}
      show={props.show}
      contentClassName={styles.modalBackground}
    >
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Badge />
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        {step === 'pledge' ? (
          <SponsorPledgeForm
            business={props.business}
            event={props.event}
            onNext={() => setStep('message')}
            pledgeAmount={pledgeAmount}
            setPledgeAmount={setPledgeAmount}
            adminFee={adminFee}
            serviceFee={serviceFee}
            currentAdminFee={currentAdminFee}
            currentServiceFee={currentServiceFee}
          />
        ) : null}
        {step === 'message' ? (
          <SponsorMessageForm
            event={props.event}
            business={props.business}
            onSubmit={onSubmit}
            pledgeAmount={pledgeAmount}
            totalAmount={formatAmount(
              parseInt(pledgeAmount || '0') +
                (currentAdminFee ?? 0) +
                (currentServiceFee ?? 0),
              true,
            )}
            pledgeExpirationDays={pledgeExpirationDays}
          />
        ) : null}

        {step === 'success' ? (
          <div className={styles.section}>
            <div className="mb-3">
              <h2>
                <b>{t('sponsorship.pledgeModal.successTitle')}</b>
              </h2>
            </div>
            <div className={styles.successIconContainer}>
              <Icon name="check-circle" />
            </div>
            <div className="mb-3">{moment().format('MMMM Do, YYYY')}</div>
            <div className="mb-3">Sent to: {props.event.user.name}</div>

            <div className="mb-3">
              <PledgeHeader event={props.event} pledgeAmount={pledgeAmount} />
            </div>

            <div className="mb-3">
              <div className="terms">
                <Trans
                  i18nKey="sponsorship.pledgeModal.successFinePrint"
                  values={{
                    days: pledgeExpirationDays,
                    total: formatAmount(
                      parseInt(pledgeAmount || '0') +
                        (currentAdminFee ?? 0) +
                        (currentServiceFee ?? 0),
                      true,
                    ),
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <Button
                onClick={() => {
                  navigate('/dashboard')
                }}
                className="w-100 mb-4"
              >
                {t('sponsorship.pledgeModal.goToDashboard')}
              </Button>
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  )
}
