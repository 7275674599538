import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import PropTypes from 'prop-types'

import {
  sortObjectsAlphabetically,
  sortCategoriesAlphabetically,
} from '../lib/sort'
import { useCurrentUser } from '../hooks/useCurrentUser'
import Button from './button'
import CategoryPill from './category-pill'
import Icon from './icon'
import styles from '../pages/groups/groups-nav.module.scss'
import UserDisplay from './user-display.jsx'

export default function GroupCard({ group }) {
  const { t } = useTranslation()
  const { currentUser, locale } = useCurrentUser()

  const listCategories = () => {
    sortCategoriesAlphabetically(group.categories, currentUser)
    return group.categories.map(category => {
      return (
        <CategoryPill
          key={`CategoryPill ${category.id} ${group.id}`}
          category={category}
          locale={locale}
        />
      )
    })
  }

  const listAdmins = () => {
    sortObjectsAlphabetically(group.admins, 'name')
    return group.admins.map(admin => {
      // iterate through admins
      return (
        <div key={`${admin.id}`}>
          <UserDisplay user={admin} />
        </div>
      )
    })
  }

  return (
    <Card>
      <Card.Img variant="top" src={group?.image_url} alt={group.name} />
      <Card.Body>
        {group.current_user_waitlist && (
          <div className={`${styles['groupcard__pending-badge']} mr-auto`}>
            {t('group.request_pending')}
          </div>
        )}
        <div className="card_title">{group.name}</div>
        <div className="my-2 card-section-title">{t('group.about')}</div>
        <p className="card_body mb-2">{group.description}</p>
        <Col>
          <div className="card-section-title">{t('group.owner')}</div>
          <UserDisplay user={group.user} />
          {!!group?.admins && group?.admins.length > 0 && (
            <>
              <div className="card-section-title">{t('group.admins')}</div>
              {listAdmins()}
            </>
          )}
        </Col>
        <div className="my-2 card-section-title">
          {t('event.categories')}
          <br />
          <div className="d-flex flex-wrap">{listCategories()}</div>
        </div>
        <Row>
          <Col md="6" className="mt-3 mb-1">
            <Link to={`/groups/${group.slug || group.id}`} target="_blank">
              <Button variant="primary" className="w-100">
                {t('group.view')}
              </Button>
            </Link>
          </Col>
          <Col md="6" className="mt-3 mb-1">
            <Link to={`/groups/${group.slug || group.id}/print`}>
              <Button className="w-100">
                <Icon name="print" /> {t('group.engagements')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

GroupCard.propTypes = {
  /* eslint-disable-next-line */
  group: PropTypes.object.isRequired,
}
