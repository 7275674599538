import Icon from './icon'

export default function LargeIcon({
  className,
  name,
}: {
  className?: string
  name: string
}) {
  return (
    <div
      className={className}
      style={{
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '#ddd 0px 0px 4px 4px',
        color: 'var(--CRPURPLE)',
        display: 'flex',
        fontSize: 60,
        height: 130,
        justifyContent: 'center',
        position: 'relative',
        width: 130,
      }}
    >
      <Icon name={name} />
    </div>
  )
}
