import { Link } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Charity, CharityRegistration } from '../../../types'
import { formatEin, toTitleCase } from '../../../lib/strings'
import Button from '../../../components/button'
import Styles from '../charities.module.scss'
import Icon from '../../../components/icon'

const CONTACT_US_URL = '/contact-us'

interface CharitySearchRowProps {
  charity: Charity
  onClickCancelRegistration?: (registration: CharityRegistration) => void
  onClickRegister?: (charity: Charity) => void
  onClickSupportRegistration?: (registration: CharityRegistration) => void
  registration?: CharityRegistration
  showStatus?: boolean
  status?: string
}

export default function CharitySearchRow(props: CharitySearchRowProps) {
  const {
    charity,
    onClickCancelRegistration,
    onClickRegister,
    onClickSupportRegistration,
    registration,
    showStatus,
  } = props

  const { t } = useTranslation()

  const handleOnClickRegister = useCallback(() => {
    onClickRegister?.(charity)
  }, [charity, onClickRegister])

  const handleOnClickRemove = useCallback(() => {
    onClickCancelRegistration?.(registration!)
  }, [registration, onClickCancelRegistration])

  const handleOnClickSupport = useCallback(() => {
    onClickSupportRegistration?.(registration!)
  }, [registration, onClickSupportRegistration])

  const address = useMemo(() => {
    const { address_line_1, address_line_2, city, state, zip } = charity
    return [
      toTitleCase(address_line_1),
      toTitleCase(address_line_2),
      toTitleCase(city),
      state,
      toTitleCase(zip),
    ]
      .filter(Boolean)
      .join(', ')
  }, [charity])

  const status = useMemo(() => {
    return charity.registration_status || props.status
  }, [charity])

  const renderBadge = () => {
    let text = t(`charities.status.${status}`)
    let backgroundColor = '#000'
    switch (status) {
      case 'Fraud Investigation':
        backgroundColor = '#000'
        break
      case 'Verification in Progress':
        backgroundColor = '#F5A623'
        break
      case 'Suspended':
        backgroundColor = '#FF4800'
        break
      case 'Withdrawn':
        backgroundColor = '#9B9B9B'
        break
      case 'Verified':
        backgroundColor = '#61A913'
        break
    }
    return (
      <div className={Styles.status} style={{ backgroundColor }}>
        {text}
      </div>
    )
  }

  const renderStatus = () => {
    switch (status) {
      case 'Fraud Investigation':
      case 'Verification in Progress':
      case 'Suspended':
      case 'Withdrawn':
        return (
          <>
            <br />
            {t('charities.search.status_not_available')}
            <br />
            <Link to={CONTACT_US_URL}>{t('charities.search.contact_us')}</Link>
          </>
        )
      case 'Verified':
        return (
          <>
            <br />
            {t('charities.search.status_verified')}
            <br />
            <Link to={CONTACT_US_URL}>{t('charities.search.contact_us')}</Link>
          </>
        )
      default:
        return (
          <button
            className="btn btn-primary"
            onClick={handleOnClickRegister}
            type="button"
          >
            {t('charities.search.register_button')}
          </button>
        )
    }
  }

  const renderActionButton = () => {
    switch (status) {
      case 'Verification in Progress':
        return (
          <Button
            className={Styles.removeButton}
            onClick={handleOnClickRemove}
            variant="outline-danger"
          >
            <Icon name="trash-alt" />
            {t('charities.register.remove_button')}
          </Button>
        )
      case 'Verified':
        return (
          <Button
            className={Styles.removeButton}
            onClick={handleOnClickSupport}
            variant="outline-danger"
          >
            <Icon name="envelope" />
            {t('charities.register.contact_support')}
          </Button>
        )
      default:
        return null
    }
  }

  return (
    <div className={Styles.row}>
      <div className={Styles.details}>
        {showStatus ? (
          <div className={Styles.badgeRow}>
            {renderBadge()}
            {renderActionButton()}
          </div>
        ) : null}
        <b>{toTitleCase(charity.organization_name)}</b>
        <br />
        EIN: {formatEin(charity.ein)}
        <br />
        {address}
      </div>
      {onClickRegister ? (
        <div className={Styles.actions}>{renderStatus()}</div>
      ) : null}
    </div>
  )
}
