import 'moment/locale/es'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { Card, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatLocation } from '../../lib/address'
import { isVideo } from '../../lib/files'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import styles from './feed.scss'

export default function TodayFeed({ event }) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()
  const startMoment = moment(event?.event_start_date).tz(moment.tz.guess())
  const todayMoment = moment()
    .hour(startMoment.hour())
    .minute(startMoment.minute())
    .second(0)
  const formattedLocation = event?.location
    ? formatLocation(event?.location)
    : null
  const status =
    todayMoment.diff(moment()) < 0
      ? t('feed.in_progress')
      : t('feed.starting_at', {
          time: todayMoment.format(t('config.time_format')),
        })

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <Link to={`/events/${event?.slug ?? event?.id}`}>
      <Card className="m-0 shadow-none today">
        {(!!event?.media_attachments?.length && (
          <Carousel
            controls={event?.media_attachments?.length > 1}
            indicators={event?.media_attachments?.length > 1}
          >
            {event?.media_attachments?.map(imgLink => {
              return (
                <Carousel.Item
                  key={`${event?.id} ${imgLink.id}`}
                  className="card-img-top"
                >
                  {(isVideo(imgLink) && (
                    <video
                      className="card-img-top"
                      autoPlay
                      muted
                      loop
                      controls
                      poster={imgLink.thumbnail_url}
                      src={imgLink.direct_upload_url}
                    />
                  )) || (
                    <Card.Img
                      variant="top"
                      src={imgLink.direct_upload_url}
                      alt={t('event.media_post_video')}
                    />
                  )}
                </Carousel.Item>
              )
            })}
          </Carousel>
        )) || (
          <Card.Img
            variant="top"
            src={
              locale === 'es'
                ? '/images/draft-media-placeholder-ES.png'
                : '/images/draft-media-placeholder-EN.png'
            }
            alt={t('event.media_post_thumbnail')}
          />
        )}
        <Card.Body className="p-2 card_body">
          {event.event_type === 'event' ? (
            <Card.Text className="time" as={'span'}>
              {status}
            </Card.Text>
          ) : null}
          <Card.Title className="card_title">{event.title}</Card.Title>
          {/* <Card.Text className="location" as={'span'}>
            {event?.remote || !formattedLocation
              ? t('event.remote')
              : formattedLocation}
          </Card.Text> */}
        </Card.Body>
      </Card>
    </Link>
  )
}
