import { Alert, Card, Col, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'

import Button from '../button'
import Icon from '../icon'
import useWallet from '../../hooks/useWallet'
import { formatAmount } from '../../lib/numbers'

interface AddCivFormProps {
  amount?: string
  bankAccountId: string
  onHide?: () => void
  onSave: (data: any) => void
  walletId: number
}

interface CivSaveStatus {
  success?: boolean
  error?: any
}

export default function AddCivForm(props: AddCivFormProps) {
  const { t } = useTranslation()

  const [saveStatus, setSaveStatus] = useState<CivSaveStatus | null>(null)
  const [amount, setAmount] = useState<number>(
    props.amount ? parseFloat(props.amount) : 0,
  )

  const { bankAccount, calculateFee, purchaseFees, updateWallet } = useWallet(
    props.walletId,
    props.bankAccountId,
  )
  const fee = useMemo(
    () => parseFloat(calculateFee(amount).toFixed(2)),
    [calculateFee, amount],
  )

  const handleSave = useCallback(async () => {
    const response = await updateWallet({
      amount: amount,
      admin_fee_id: purchaseFees.id,
      admin_fee_amount: fee,
    })
    setSaveStatus({
      success: response.success,
      // error: response.error
    })
    if (response.success) {
      props.onSave({
        amount,
        fee,
      })
    }
  }, [amount, fee, updateWallet])

  return (
    <div>
      <div>
        {saveStatus === null || (!saveStatus?.success && saveStatus?.error) ? (
          <>
            {!saveStatus?.success && saveStatus?.error ? (
              <p className="text-center mx-auto w-75">
                <Alert key="danger" variant="danger">
                  {t('civ_wallet.civ_modal.failure')}
                </Alert>
              </p>
            ) : null}
            <h5 className="text-center">{t('civ_wallet.civ_modal.heading')}</h5>
            <p className="text-center funding-icon">
              <Icon name="wallet" />
            </p>
            <p className="mx-auto w-75">
              {t('civ_wallet.civ_modal.description')}
            </p>
            <p className="text-center fw-semibold fs-6">
              {t('civ_wallet.civ_modal.amountLabel')}
            </p>
            <div className="row form-row justify-content-md-center">
              <div className="form-group col-md-9">
                <input
                  className="form-control form-control-lg text-center"
                  id="civ_amount"
                  name="civ_amount"
                  type="text"
                  value={amount}
                  placeholder="0"
                  onChange={e => {
                    setAmount(e.target.value ? parseInt(e.target.value) : 0)
                  }}
                />
              </div>
            </div>
            <Row className="my-1 mx-auto w-75">
              <Col className="text-start">
                <Trans
                  i18nKey="civ_wallet.civ_modal.transactionFee"
                  values={{
                    fee: `${purchaseFees.value}%`,
                  }}
                />
              </Col>
              <Col className="text-center">{formatAmount(fee, true)}</Col>
            </Row>
            <Row className="mb-2 mx-auto w-75 fw-bold">
              <Col className="text-start">
                {t('civ_wallet.civ_modal.totalCharge')}
              </Col>
              <Col className="text-center">
                {formatAmount(amount + fee, true)}
              </Col>
            </Row>
            <Card className="shadow-none mx-auto w-75">
              <Card.Body>
                <Card.Text className="fs-6">
                  {t('civ_wallet.civ_modal.payment.title')}
                </Card.Text>
                <Card.Text>
                  <Trans
                    i18nKey="civ_wallet.civ_modal.payment.detail"
                    values={{
                      numbers: bankAccount?.bank_account_number_last4,
                    }}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <p className="text-center success-icon">
              <Icon name="check-circle" />
            </p>
            <p className="text-center">
              {t('civ_wallet.civ_modal.success.description')}
            </p>
            <p className="text-center fw-bold fs-6">
              <Trans
                i18nKey="civ_wallet.civ_modal.success.amount"
                values={{
                  amount: amount + fee,
                }}
              />
            </p>
            <p className="text-center">
              {moment().format(t('config.date_format'))}
            </p>
          </>
        )}
      </div>
      <div className="justify-content-md-center">
        {saveStatus === null || (!saveStatus?.success && saveStatus?.error) ? (
          <>
            <Row>
              <Col>
                <p className="mx-auto w-75">
                  {t('civ_wallet.civ_modal.agreement')}
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center mb-3">
                <Button
                  variant="primary"
                  onClick={handleSave}
                  disabled={!amount}
                >
                  {t('civ_wallet.civ_modal.button')}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Button variant="primary" onClick={props.onHide}>
            {t('civ_wallet.civ_modal.success.button')}
          </Button>
        )}
      </div>
    </div>
  )
}
