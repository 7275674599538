import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import AccountSidebar from './sidebar'
import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function ResetPasswordPage() {
  const { forgotPassword } = useApi()
  const { locale } = useCurrentUser()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    const formData = new FormData(event.currentTarget)
    const response = await forgotPassword({
      email: formData.get('email'),
      locale,
    })
    if (response.errors) {
      // eslint-disable-next-line no-alert
      // alert(response.errors?.full_messages?.[0]);
      setIsLoading(false)
    } else {
      navigate('/login')
    }
  }

  return (
    <div className="container" id="loginForm">
      <div className="row" style={{ paddingBottom: '60px' }}>
        <div className="col-sm-6">
          <AccountSidebar />
        </div>
        <div className="col-sm-4 offset-sm-1" style={{ paddingTop: '60px' }}>
          <h3>{t('devise.sessions.transform_your_community')}</h3>
          <h4>{t('devise.sessions.forgot')}</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                name="email"
                type="text"
                placeholder={t('devise.sessions.email')}
                autoFocus
                autoComplete="email"
                className="form-control"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block spaced"
              disabled={isLoading}
            >
              {t('devise.sessions.send_pw_code')}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
