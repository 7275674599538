import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { Buffer } from 'buffer'

import Icon from '../../components/icon'
import SortTable from '../../components/sortTable/sortTable'
import upload from '../../constants/upload'
import useApi from '../../hooks/useApi'
import { formatAmount } from '../../lib/numbers'
import { toCapitalizeWords } from '../../lib/strings'
import { downloadCSV } from '../../lib/files'

export default function CharityDisbursement({ setLoading }) {
  const { t } = useTranslation()
  const [disbursements, setDisbursements] = useState([])
  const [queryParams, setQueryParams] = useState({
    sort_by: 'organization_name',
    sort_order: 'DESC',
  })
  const [selectedCharities, setSelectedCharities] = useState([])
  const [refresh, setRefresh] = useState(null)
  const [selectedUpload, setSelectedUpload] = useState(null)

  const hiddenFileInput = useRef(null)
  const handleClickUpload = (event, id) => {
    setSelectedUpload(id)
    hiddenFileInput.current.click()
  }

  const {
    charitiesMarkAsDisbursed,
    getDisbursementReport,
    getDisbursements,
    uploadReportDisbursement,
  } = useApi()

  const reportUploadOnChange = async e => {
    const file = e.target?.files?.[0]
    if (!file || !selectedUpload) {
      return
    }

    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('report', file)
      formData.append('civ_disbursement_id', selectedUpload)
      const uploadResponse = await uploadReportDisbursement(formData)
      // console.log('upload', uploadResponse)
      const updated = disbursements.findIndex(d => d.id === selectedUpload)
      const tempDisbursements = [...disbursements]
      tempDisbursements[updated].report = uploadResponse.data.created_at
      setDisbursements([...tempDisbursements])
      setSelectedUpload(null)
      hiddenFileInput.current.value = ''
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getDisbursements(queryParams)
      setDisbursements(
        response.data?.civ_disbursements?.map(d => ({
          id: d.id,
          ready_for_disbursement_at: d.ready_for_disbursement_at,
          charities: d.charities.map(c => ({
            organization_name: toCapitalizeWords(c.organization_name),
            donation_total: formatAmount(c.donation_total, true),
            id: c.charity_id,
            status: (
              <Row>
                <Col>
                  {!!c.disbursed_at
                    ? moment(c.disbursed_at).format(
                        t('config.file_name_date_format'),
                      )
                    : ''}
                </Col>
                <Col>
                  <Button
                    variant="primary btn-sm"
                    onClick={() =>
                      onClickDisbursement(d.id, { charity_id: c.charity_id })
                    }
                    disabled={!!c.disbursed_at}
                  >
                    {!!c.disbursed_at
                      ? t('buttons.done')
                      : t('buttons.pending')}
                  </Button>
                </Col>
              </Row>
            ),
          })),
          report: d.civ_disbursement_reports?.length
            ? d.civ_disbursement_reports[d.civ_disbursement_reports.length - 1]
                .created_at
            : null,
        })),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getDisbursements, queryParams])

  useEffect(() => {
    fetchData()
  }, [queryParams, refresh])

  const headers = [
    {
      title: t('accounting.charityDisbursement.headers.charity_name'),
      prop: 'organization_name',
      isSortable: true,
    },
    {
      title: t('accounting.charityDisbursement.headers.donation_total'),
      prop: 'donation_total',
      isSortable: true,
    },
    {
      title: t('accounting.charityDisbursement.headers.status'),
      prop: 'status',
      isSortable: false,
      // thProps: {
      //   className: 'th-center',
      // },
    },
    // {
    //   alignment: {
    //     horizontal: 'center',
    //   },
    //   checkbox: {
    //     className: 'chk-checkbox',
    //     idProp: 'ein',
    //   },
    //   prop: 'checkbox',
    //   cellProps: {
    //     className: row => (row.ein > 10600000 ? 'd-none' : 'd-block'),
    //   },
    // },
  ]

  const downloadReport = data => {
    setLoading(true)
    getDisbursementReport(data.id)
      .then(response => {
        const fileName = `civ_disbusement_report_${data.id}_${moment(
          data.ready_for_disbursement_at,
        ).format(t('config.file_name_date_format'))}.csv`
        downloadCSV(response.data, fileName)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
      })
  }

  const onClickDisbursement = async (id, data) => {
    setLoading(true)
    try {
      const response = await charitiesMarkAsDisbursed(id, data)
      if (response?.status === 200) {
        setRefresh(+new Date())
      }
      setLoading(false)
    } catch (error) {
      console.log('Error', error)
      setLoading(false)
    }
  }
  // const onCheckboxClick = selected => {
  //   console.log('CHECK', selected)
  //   setSelectedCharities(selected)
  //   // setRecipients(modalMock.filter(r => selected.includes(r.id)))
  // }

  // const onSaveDisbursement = () => {
  //   console.log('onSaveDisbursement', selectedCharities)
  //   const promises = []
  //   setLoading(true)
  //   for (let index = 0; index < selectedCharities.length; index++) {
  //     const element = selectedCharities[index]
  //     const addPromise = new Promise(resolve => {
  //       resolve(charitiesMarkAsDisbursed(element))
  //     })
  //       .then(response => {
  //         return response?.success ? true : response?.error?.message
  //       })
  //       .catch(error => {
  //         return error
  //       })
  //     promises.push(addPromise)
  //   }

  //   Promise.all(promises)
  //     .then(response => {
  //       const failed = response.filter(r => r !== true)
  //       if (failed.length) {
  //         console.log('Failure', failed)
  //       } else {
  //         console.log('Complete success')
  //       }
  //       setRefresh(+new Date())
  //       setLoading(false)
  //     })
  //     .catch(error => {
  //       console.log('Error', error)
  //       setLoading(false)
  //     })
  // }

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  if (!disbursements?.length) {
    return null
  }

  return (
    <>
      <input
        id="report"
        className="hidden"
        onChange={reportUploadOnChange}
        ref={hiddenFileInput}
        type="file"
      />
      {disbursements.map(d => (
        <Accordion.Item
          eventKey={`charityDisbursements-${d.id}`}
          className="border event-pledge"
          key={d.id}
        >
          <Accordion.Header className="border-top">
            <Row className="w-100 justify-content-between align-items-center">
              <Col className="text-start fw-semibold" lg="4">
                {t('accounting.charityDisbursement.title', {
                  period:
                    d.id +
                    ' (' +
                    moment(d.ready_for_disbursement_at).format(
                      t('config.file_name_date_format'),
                    ) +
                    ')',
                })}
              </Col>
              <Col className="text-end pe-4">
                {d.charities?.length ? (
                  <>
                    <span>
                      {t('accounting.charityDisbursement.totalRequests', {
                        total: d.charities?.length,
                      })}
                    </span>
                    <div
                      className="btn btn-link p-0 text-decoration-none"
                      onClick={() => downloadReport(d)}
                    >
                      {t('accounting.charityDisbursement.downloadReport.label')}
                      <Icon name="download" />
                    </div>
                  </>
                ) : null}
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <SortTable
              body={d.charities}
              headers={headers}
              onFetch={s => onFetch(s)}
              showPagination={false}
              // onCheckboxClick={onCheckboxClick}
            />
            {/* <Row className="m-0">
          <Col className="text-end border-bottom">
            <Button
              className="m-2"
              variant="primary"
              onClick={() => onSaveDisbursement()}
              disabled={!selectedCharities.length}
            >
              {t('accounting.charityDisbursement.saveButton')}
            </Button>
          </Col>
        </Row> */}
            <Row>
              <Col className="text-end m-3">
                <span className="me-5">
                  {d.report
                    ? t('accounting.charityDisbursement.upload.finish', {
                        date: moment(d.report).format(
                          t('config.file_name_date_format'),
                        ),
                      })
                    : ''}
                </span>
                <span className="fw-semibold">
                  {t('accounting.charityDisbursement.upload.label')}
                </span>
                <button
                  type="button"
                  className="btn btn-link p-0 text-decoration-none"
                  onClick={event => handleClickUpload(event, d.id)}
                >
                  {t('accounting.charityDisbursement.upload.link.label')}
                  <Icon name="upload" />
                </button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </>
  )
}
