import {
  brands,
  solid,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

const icons = {
  'arrow-alt-to-left': regular('arrow-alt-to-left'),
  'arrow-alt-to-right': regular('arrow-alt-to-right'),
  'arrow-alt-left': regular('arrow-alt-left'),
  'arrow-alt-right': regular('arrow-alt-right'),
  'balance-scale': solid('balance-scale'),
  'basketball-ball': solid('basketball-ball'),
  'box-heart': solid('box-heart'),
  'calendar-check': regular('calendar-check'),
  'calendar-days': solid('calendar-days'),
  'chart-line-up': solid('chart-line-up'),
  'check-circle': solid('check-circle'),
  'comment-smile': regular('comment-smile'),
  'external-link-alt': solid('external-link-alt'),
  'eye-slash': solid('eye-slash'),
  'file-invoice-dollar': regular('file-invoice-dollar'),
  'globe-americas': solid('globe-americas'),
  'grid-2': solid('grid-2'),
  'hand-heart': solid('hand-heart'),
  'hand-holding-heart': solid('hand-holding-heart'),
  'hand-paper': solid('hand-paper'),
  'hand-peace': solid('hand-peace'),
  'hands-heart': solid('hands-heart'),
  'hands-holding-heart': regular('hands-holding-heart'),
  'hands-usd': solid('hands-usd'),
  'home-heart': solid('home-heart'),
  'info-circle': solid('info-circle'),
  'laptop-house': solid('laptop-house'),
  'life-ring': solid('life-ring'),
  'location-dot': solid('location-dot'),
  'lock-alt': solid('lock-alt'),
  'map-marker-alt': solid('map-marker-alt'),
  'minus-circle': solid('minus-circle'),
  'paint-brush': solid('paintbrush'),
  'plus-circle': solid('plus-circle'),
  'sign-out-alt': solid('sign-out-alt'),
  'star-regular': regular('star'),
  'star-half-stroke': regular('star-half-stroke'),
  'user-check': solid('user-check'),
  'user-clock': regular('user-clock'),
  'calendar-days': solid('calendar-days'),
  'location-dot': solid('location-dot'),
  'trash-alt': solid('trash-alt'),
  'magnifying-glass-dollar': solid('magnifying-glass-dollar'),
  ambulance: solid('ambulance'),
  award: regular('award'),
  bars: solid('bars'),
  blind: solid('blind'),
  book: solid('book'),
  building: solid('building'),
  bullhorn: solid('bullhorn'),
  calendar: solid('calendar'),
  camera: solid('camera'),
  carrot: solid('carrot'),
  check: solid('check'),
  child: solid('child'),
  clock: solid('clock'),
  close: solid('close'),
  cog: solid('cog'),
  dog: solid('dog'),
  download: solid('download'),
  edit: solid('edit'),
  envelope: solid('envelope'),
  ellipsis: regular('ellipsis'),
  eye: solid('eye'),
  facebook: brands('facebook'),
  gift: solid('gift'),
  hand: solid('hand'),
  heart: solid('heart'),
  heartbeat: solid('heartbeat'),
  home: solid('home'),
  info: solid('info-circle'),
  instagram: brands('instagram'),
  landmark: solid('landmark'),
  location: solid('location'),
  lock: solid('lock'),
  medal: solid('medal'),
  play: solid('play'),
  plus: solid('plus'),
  print: solid('print'),
  question: solid('question'),
  question_tooltip: solid('question-circle'),
  share: solid('share'),
  star: solid('star'),
  tags: solid('tags'),
  times: solid('times'),
  tree: solid('tree'),
  trophy: solid('trophy'),
  upload: solid('upload'),
  users: solid('users'),
  wallet: solid('wallet'),
}

export default function Icon({ name = '', className = '' }) {
  if (!name || !icons[name]) {
    return null
  }
  return <FontAwesomeIcon icon={icons[name]} className={className} />
}

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}
