import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import useApi from '../hooks/useApi'

export default function RequestEventAccess(props) {
  const { eventId } = props
  const { requestEventAdminAccess } = useApi()
  const { t } = useTranslation()

  const requestAdminAccess = useCallback(async () => {
    await requestEventAdminAccess({ eventId })
    if (typeof window !== 'undefined') alert('Request for access sent')
  }, [requestEventAdminAccess, eventId])

  return (
    <>
      <p>
        <b>{t('event.request_admin_access')}</b>
      </p>
      <p>
        <button
          className="btn btn-primary"
          onClick={requestAdminAccess}
          type="button"
        >
          {t('event.request_admin_access_button')}
        </button>
      </p>
    </>
  )
}

RequestEventAccess.propTypes = {
  eventId: PropTypes.string.isRequired,
}
