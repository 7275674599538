import { AxiosResponse } from 'axios'
import { Modal } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import Form from 'react-bootstrap/esm/Form'

import { storeState } from '../../lib/localstorage'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useNavigate } from 'react-router-dom'
import Button from '../button'
import styles from './AddBusinessAccountModal.module.scss'
import TextInput from '../TextInput/TextInput'
import useApi from '../../hooks/useApi'

interface AddBusinessAccountModalProps {
  show: boolean
  onHide?: () => void
}

export default function AddBusinessAccountModal(
  props: AddBusinessAccountModalProps,
) {
  const { show, onHide } = props

  const { createBusiness, updateUser } = useApi()
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [website, setWebsite] = useState('')

  const onHideModal = useCallback(() => {
    storeState('show-add-business-account-modal', false)
    onHide?.()
  }, [onHide])

  const onHideForever = useCallback(async () => {
    await updateUser(currentUser.id, { dismissed_business_popup: true })
    onHideModal()
  }, [onHideModal, currentUser])

  const onSubmit = useCallback(async () => {
    if (!name?.length || !website?.length) {
      return
    }
    const { data } = (await createBusiness({
      name,
      website_url: website,
    })) as AxiosResponse<{ id: string }>
    if (data?.id) {
      await onHideForever?.()
      navigate('/business-account')
    }
  }, [name, onHide, website])

  return (
    <Modal show={show} onHide={onHideModal} centered>
      <Modal.Header closeButton className={styles.header} />
      <Modal.Body>
        <div className={styles.slogan}>
          {t('add-business-account-modal.slogan')}
        </div>
        <Modal.Title className={styles.title}>
          {t('add-business-account-modal.title')}
        </Modal.Title>
        <Form className={styles.form}>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.label}>
              {t('add-business-account-modal.name-label')}
            </Form.Label>
            <TextInput onChange={setName} value={name} />
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.label}>
              {t('add-business-account-modal.website-label')}
            </Form.Label>
            <TextInput onChange={setWebsite} value={website} />
            <div className={styles.example}>
              {t('add-business-account-modal.website-example')}
            </div>
          </Form.Group>
        </Form>

        <div className={styles.terms}>
          <Trans
            i18nKey="add-business-account-modal.terms"
            components={{
              1: (
                <a
                  href="/terms-of-service/"
                  target="_blank"
                  rel="noreferrer"
                ></a>
              ),
              2: (
                <a href="/privacy-policy/" target="_blank" rel="noreferrer"></a>
              ),
            }}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            variant="primary"
            onClick={onHideForever}
          >
            {t('add-business-account-modal.cancel')}
          </Button>
          <Button
            disabled={!name?.length || !website?.length}
            className={styles.button}
            variant="primary"
            onClick={onSubmit}
          >
            {t('add-business-account-modal.save')}
          </Button>
        </div>
        <div className={styles.login}>
          <Trans
            i18nKey="add-business-account-modal.login"
            components={{
              a: <a href="/logout"></a>,
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
