import React, { useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Icon from '../icon'

export interface Charity {
  id: number
  organization_name: string
}

interface CharityPickerItemProps {
  charity: Charity
  isSelected: boolean
  onChange: (charityId: number) => void
}

const CharityPickerItem: React.FC<CharityPickerItemProps> = ({
  charity,
  isSelected,
  onChange,
}) => {
  const { t } = useTranslation()
  const handleCharityPicker = useCallback(() => {
    onChange(charity.id)
  }, [charity.id, onChange])

  return (
    <Row className="py-1 border-top">
      <Col className="text-start align-self-center text-capitalize fs-6">
        {charity.organization_name.toLowerCase()}
      </Col>
      <Col className="col-3 text-end align-self-center">
        <button
          className={`btn btn-sm ${isSelected ? 'btn-pink' : 'btn-secondary'}`}
          onClick={handleCharityPicker}
        >
          <Icon name="gift" className="me-2" />
          {t('civ_wallet.donation.modal.donateButton')}
        </button>
      </Col>
    </Row>
  )
}

export default CharityPickerItem
