import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import EventTitle from '../../components/Dashboard/EventTitle'
// import Badge from '../../components/Dashboard/Badge'
import SortTable from '../../components/sortTable/sortTable'
import { currentStatus } from '../../lib/event'
import { formatAmount } from '../../lib/numbers'

// const badges = [
//   {
//     name: 'award',
//     className: 'text-success',
//   },
//   {
//     name: 'medal',
//     className: 'text-warning',
//   },
//   {
//     name: 'star',
//     className: 'text-danger',
//   },
//   {
//     name: 'trophy',
//     className: 'text-primary',
//   },
//   '',
// ]

export default function PayForward({
  setLoading,
  refresh,
  setRefresh,
  setTotalEvents,
  setTotalPledge,
}) {
  const { t } = useTranslation()
  const [eventsPayForward, setEventsPayForward] = useState()
  const [body, setBody] = useState([])
  const [queryParams, setQueryParams] = useState({
    sort_by: 'event_start_date',
    sort_order: 'DESC',
  })
  const { getEventsPayForward } = useApi()

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getEventsPayForward(queryParams)
      setEventsPayForward(response.data)
      setBody(
        response.data?.events?.map(d => ({
          event_start_date: moment(d.event_start_date).format(
            t('config.date_format'),
          ),
          title: <EventTitle event={d} />,
          accepted_pledges_count: (
            <span className="w-75 d-block text-center">
              {d.accepted_pledges_count}
            </span>
          ),
          accepted_pledges_amount_sum: `${formatAmount(
            d.accepted_pledges_amount_sum,
          )} CIV`,
          event_end_date: t(currentStatus(d)),
          participation_count: (
            <span className="w-75 d-block text-center">
              {d.participation_count}
            </span>
          ),
          // badge: <Badge {...badges[Math.floor(Math.random() * 5)]} />,
        })),
      )
      setTotalEvents(
        response.data.events.filter(e => e.accepted_pledges_count > 0).length,
      )
      setTotalPledge(
        response.data.events.reduce((acc, obj) => {
          return acc + obj.accepted_pledges_amount_sum
        }, 0),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getEventsPayForward, queryParams])

  useEffect(() => {
    fetchData()
  }, [queryParams])

  useEffect(() => {
    if (!!refresh) {
      fetchData()
    }
  }, [refresh])

  const headers = [
    {
      title: t('organizer.payForward.headers.startDate'),
      prop: 'event_start_date',
      isSortable: true,
    },
    {
      title: t('organizer.payForward.headers.event'),
      prop: 'title',
      isSortable: true,
    },
    {
      title: t('organizer.payForward.headers.sponsors'),
      prop: 'accepted_pledges_count',
      isSortable: true,
    },
    {
      title: t('organizer.payForward.headers.amount'),
      prop: 'accepted_pledges_amount_sum',
      isSortable: true,
    },
    {
      title: t('organizer.payForward.headers.status'),
      prop: 'event_end_date',
      // isSortable: true,
    },
    {
      title: t('organizer.payForward.headers.participation'),
      prop: 'participation_count',
      isSortable: true,
    },
    // {
    //   title: t('organizer.payForward.headers.badge'),
    //   prop: 'badge',
    // },
  ]

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('organizer.payForward.title')}</h1>
        </div>
      </div>
      <div className="container">
        <SortTable
          body={body}
          headers={headers}
          onFetch={s => onFetch(s)}
          showPagination={false}
        />
      </div>
    </>
  )
}
