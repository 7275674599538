import { Col, Row } from 'react-bootstrap'

export default function BlockContent({ stat, statLabel }) {
  return (
    <Row className="d-flex flex-column">
      <Col className="stat">{stat}</Col>
      <Col className="stat-label">{statLabel}</Col>
    </Row>
  )
}
