import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export default function TasksHeader({ closeTaskAndShift, event, isValid }) {
  const { t } = useTranslation()

  const buildDateText = () => {
    const timeFormat = 'h:mma'
    const start = moment(event.event_start_date)
    const end = moment(event.event_end_date)

    const startTime = `${start.format(t('config.date_format'))} ${t(
      event.at,
    )} ${start.format(timeFormat)}`
    const endTime = `${
      start.day() === end.day() ? '' : end.format(t('config.date_format'))
    } ${end.format(timeFormat)}`
    return `${startTime} ${t('event.through')} ${endTime}`
  }

  return (
    <>
      <div className="row">
        <div className="back">
          <Button variant="link" onClick={closeTaskAndShift}>
            {`< ${t('buttons.back')}`}
          </Button>
        </div>
      </div>
      <div className="row">
        <h1>{t('event.tasks_and_shifts')}</h1>
      </div>
      <div className="row">
        <div>
          <strong>{t('event.title')}:</strong> {event.title}
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <strong>{t('event.date')}:</strong> {buildDateText()}
        </div>
        <div className="col-5">
          <strong>{t('event.volunteers')}:</strong> {event.volunteers_needed}
        </div>
      </div>
      {!isValid ? (
        <div className="row invalid-warning">
          <div className="col-7">
            <strong>{t('event.task_form_missing_data')}</strong>
          </div>
        </div>
      ) : null}
    </>
  )
}
