import { useCallback, useMemo, useState } from 'react'

import { Group } from '../types'
import { sortObjectsAlphabetically } from '../lib/sort'
import { useCurrentUser } from './useCurrentUser'
import { useStateCache } from '../lib/localstorage'
import useApi from './useApi'

export default function useGroups() {
  const { currentUser } = useCurrentUser()
  const { getCurrentUserGroups, getGroup } = useApi()

  const [groups, setGroups] = useStateCache('groups', [])
  const [initialLoad, setInitialLoad] = useState(groups.length === 0)
  const [isLoading, setIsLoading] = useState(false)
  const [waitlistGroups, setWaitlistGroups] = useStateCache(
    'waitlistGroups',
    [],
  )

  const fetchGroups = useCallback(async () => {
    const { data } = await getCurrentUserGroups()
    setGroups(data)
  }, [getCurrentUserGroups])

  const fetchWaitlistGroups = useCallback(async () => {
    setWaitlistGroups(currentUser.waitlist ?? [])
  }, [currentUser, getGroup])

  const fetchAllGroups = useCallback(async () => {
    setIsLoading(true)
    await Promise.all([fetchGroups(), fetchWaitlistGroups()])
    setInitialLoad(false)
    setIsLoading(false)
  }, [fetchGroups, fetchWaitlistGroups])

  /**
   * An array of all groups the user is a member of, including waitlist groups.
   * Groups are sorted alphabetically by name.
   */
  const allGroups = useMemo(() => {
    return sortObjectsAlphabetically(
      [...groups, ...waitlistGroups],
      'name',
    ) as Group[]
  }, [groups, waitlistGroups])

  return {
    allGroups,
    fetchAllGroups,
    fetchGroups,
    fetchWaitlistGroups,
    groups,
    initialLoad,
    isLoading,
    waitlistGroups,
  }
}
