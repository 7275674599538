import { useTranslation } from 'react-i18next'

export default function CivChoise() {
  const { t } = useTranslation()
  return (
    <div className="my-4">
      <p className="text-center">{t('civChoise.powered_by')}</p>
      <div className="d-flex justify-content-center">
        <img alt={'Civ Choise Logo'} src={'/images/CivChoice-Logo.png'} />
      </div>
    </div>
  )
}
