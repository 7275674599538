import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'

import { useCurrentUser } from '../../../hooks/useCurrentUser'
import useApi from '../../../hooks/useApi'
import { onlyNumbers } from '../../../lib/numbers'
import Button from '../../../components/button'
import Icon from '../../../components/icon'

interface AddFundingSourceFormProps {
  businessId: number | undefined
  onHide?: () => void
  onSave: (data: any) => void
}

interface BankAccountAttributes {
  bank_account_holder_name?: string
  bank_account_routing_number?: string
  bank_account_number?: string
}

export default function AddFundingSourceForm(props: AddFundingSourceFormProps) {
  const { t } = useTranslation()

  const { currentUser, fetchUser, locale } = useCurrentUser()
  const { updateBusiness } = useApi()

  const [addFundingEnabled, setAddFundingEnabled] = useState(false)
  const [bankAccountAttributes, setBankAccountAttributes] =
    useState<BankAccountAttributes>()
  const [hasDataChanged, setHasDataChanged] = useState(false)

  const handleOnClickSave = useCallback(async () => {
    const response = await updateBusiness(props.businessId, {
      bank_account_attributes: {
        ...bankAccountAttributes,
      },
    })
    //@ts-ignore
    if (!response.success && response.error) {
      // @ts-ignore
      alert(response.error?.message)
    } else {
      await fetchUser()
    }
    props.onSave({
      bank_account_attributes: bankAccountAttributes,
    })
  }, [bankAccountAttributes, currentUser, fetchUser, props])

  useEffect(() => {
    const enabled =
      (bankAccountAttributes?.bank_account_number?.length ?? 0) >= 12 &&
      !!bankAccountAttributes?.bank_account_routing_number &&
      !!bankAccountAttributes?.bank_account_holder_name &&
      hasDataChanged
    setAddFundingEnabled(enabled)
  }, [
    bankAccountAttributes?.bank_account_number,
    bankAccountAttributes?.bank_account_routing_number,
    bankAccountAttributes?.bank_account_holder_name,
  ])

  return (
    <>
      <div className="text-center ms-auto p-0">
        {t('civ_wallet.funding_modal.title')}
      </div>
      <div>
        <h5 className="text-center">{t('civ_wallet.funding_modal.heading')}</h5>
        <p className="text-center funding-icon">
          <Icon name="landmark" />
        </p>
        <p className="mx-auto w-75">
          {t('civ_wallet.funding_modal.description')}
        </p>
        <p className="mx-auto w-75">
          <img
            alt="Bank account info"
            className="img-fluid"
            src={`/images/${locale}_bank_account_info.gif`}
          />
        </p>
        <div className="row form-row justify-content-md-center">
          <div className="form-group col-md-9">
            <label htmlFor="bank_account_holder_name">
              {t('civ_wallet.funding_modal.account.name')}
            </label>
            <input
              className="form-control"
              id="bank_account_holder_name"
              name="bank_account_holder_name"
              type="text"
              value={bankAccountAttributes?.bank_account_holder_name}
              onChange={e => {
                setBankAccountAttributes({
                  ...bankAccountAttributes,
                  bank_account_holder_name: e.target.value,
                })
                setHasDataChanged(true)
              }}
            />
          </div>
        </div>
        <div className="row form-row justify-content-md-center">
          <div className="form-group col-md-9">
            <label htmlFor="bank_account_routing_number">
              {t('civ_wallet.funding_modal.account.routing_number')}
            </label>
            <input
              className="form-control"
              id="bank_account_routing_number"
              name="bank_account_routing_number"
              type="text"
              maxLength={9}
              value={bankAccountAttributes?.bank_account_routing_number}
              onChange={e => {
                setBankAccountAttributes({
                  ...bankAccountAttributes,
                  bank_account_routing_number: onlyNumbers(
                    e.target.value,
                  ).toString(),
                })
                setHasDataChanged(true)
              }}
            />
          </div>
        </div>
        <div className="row form-row justify-content-md-center">
          <div className="form-group col-md-9">
            <label htmlFor="bank_account_number">
              {t('civ_wallet.funding_modal.account.number')}
            </label>
            <input
              className="form-control"
              id="bank_account_number"
              name="bank_account_number"
              type="text"
              maxLength={17}
              minLength={12}
              value={bankAccountAttributes?.bank_account_number}
              onChange={e => {
                setBankAccountAttributes({
                  ...bankAccountAttributes,
                  bank_account_number: onlyNumbers(e.target.value).toString(),
                })
                setHasDataChanged(true)
              }}
            />
          </div>
        </div>

        <p className="mx-auto w-75">{t('civ_wallet.funding_modal.terms')}</p>

        <div className="d-flex justify-content-center mb-3">
          <Button
            variant="primary"
            onClick={handleOnClickSave}
            disabled={!addFundingEnabled}
          >
            {t('civ_wallet.funding_modal.button')}
          </Button>
        </div>
      </div>
    </>
  )
}
