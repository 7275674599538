import { useCallback, useEffect, useMemo, useState } from 'react'

import { BankAccount } from '../types'
import useApi from './useApi'
import civ from '../constants/civ'

export default function UseWallet(walletId: number, bankAccountId?: string) {
  const { getAdminFee, getBankAccount, getCivWallet, updateCivWallet } =
    useApi()

  const [bankAccount, setBankAccount] = useState<BankAccount>()
  const [wallet, setWallet] = useState<any>({})
  const [purchaseFees, setPurchaseFees] = useState(civ.empty)

  const fetchBankAccount = useCallback(async () => {
    if (bankAccountId) {
      const { data } = await getBankAccount(bankAccountId)
      setBankAccount(data)
      return data
    } else {
      return null
    }
  }, [bankAccountId, getBankAccount, walletId])

  const fetchPurchaseFees = useCallback(async () => {
    const { data } = await getAdminFee()
    const fees = data?.current_fees?.civillos_fee || {}
    setPurchaseFees(fees)
    return fees
  }, [getAdminFee])

  const fetchWallet = useCallback(async () => {
    const { data } = await getCivWallet(walletId)
    setWallet(data)
    return data
  }, [getBankAccount, walletId])

  const refresh = useCallback(async () => {
    await fetchWallet()
    await fetchBankAccount()
    await fetchPurchaseFees()
  }, [fetchBankAccount, fetchPurchaseFees, fetchWallet])

  const updateWallet = useCallback(
    async (wallet: any) => {
      const response = await updateCivWallet(walletId, wallet)
      await refresh()

      console.log(response)
      return {
        success: true,
      }
    },
    [refresh, updateCivWallet, walletId],
  )

  const calculateFee = useCallback(
    (amount: number | string) => {
      if (!amount) {
        return 0
      }
      if (typeof amount === 'string') {
        amount = parseFloat(amount)
      }

      const civillosPercent =
        purchaseFees?.fee_kind === 'percentage'
          ? (purchaseFees?.value ?? 0) / 100
          : 0
      const civillosFlat =
        purchaseFees?.fee_kind === 'flat' ? purchaseFees?.value ?? 0 : 0

      return amount * civillosPercent + civillosFlat
    },
    [purchaseFees],
  )

  const hasFundingSource = useMemo(() => {
    return !!bankAccount || !!bankAccountId
  }, [bankAccount])

  useEffect(() => {
    walletId && refresh()
  }, [refresh, walletId])

  return {
    bankAccount,
    calculateFee,
    fetchBankAccount,
    fetchPurchaseFees,
    fetchWallet,
    hasFundingSource,
    purchaseFees,
    refresh,
    updateWallet,
    wallet,
  }
}
