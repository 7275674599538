import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Badge from '../../components/badge'
import Loading from '../../components/loading'
import EventSponsorship from './eventSponsorship'
import PayForward from './payForward'
import SponsorPledge from './sponsorPledge'

export default function DashboardOrganizer() {
  const { t } = useTranslation()

  const [totalEvents, setTotalEvents] = useState(0)
  const [totalPledge, setTotalPledge] = useState(0)
  const [refresh, setRefresh] = useState(null)
  const [loadingSponsorPledge, setLoadingSponsorPledge] = useState(false)
  const [loadingPayForward, setLoadingPayForward] = useState(false)

  return (
    <div className="mb-5">
      <Loading show={loadingSponsorPledge || loadingPayForward} />
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1 badge-container">
            {t('organizer.title')}
            <Badge />
          </h1>
          <h6 className="block-description">
            {t('organizer.description')}&nbsp;
            {/* <a target="_blank" href={t('organizer.learnMore.link')}>
              {t('organizer.learnMore.text')}
            </a> */}
          </h6>
        </div>
      </div>
      <EventSponsorship totalEvents={totalEvents} totalPledge={totalPledge} />
      <SponsorPledge
        setLoading={setLoadingSponsorPledge}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <PayForward
        setLoading={setLoadingPayForward}
        refresh={refresh}
        setRefresh={setRefresh}
        setTotalEvents={setTotalEvents}
        setTotalPledge={setTotalPledge}
      />
    </div>
  )
}
