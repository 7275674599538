import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import UseWallet from '../../hooks/useWallet'
import { formatAmount } from '../../lib/numbers'
import NavbarItem from './navbarItem'

export default function WalletItem() {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { wallet } = UseWallet(currentUser.wallet_id)
  return (
    <NavbarItem
      to="/settings#wallet"
      iconName="wallet"
      content={
        <>
          <span className="me-3">{t('navigation_banner.wallet')}</span>
          <span>{`${formatAmount(wallet?.balance || 0)} CIV`}</span>
        </>
      }
    />
  )
}
