import { Dropdown, Navbar as BSNavbar } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import Icon from '../icon'
import { formatAmount } from '../../lib/numbers'
import NavbarItem from './navbarItem'
import WalletItem from './walletItem'

export default function Navbar({ showAsLoggedOut = false }) {
  const { t, i18n } = useTranslation()
  const { currentUser, hasBetaFeature, locale } = useCurrentUser()

  const { has_business, business_id } = currentUser || {}

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('locale')) {
      i18n.changeLanguage(searchParams.get('locale'))
      moment.locale(searchParams.get('locale') || locale)
    }
  }, [searchParams])

  return (
    <BSNavbar bg="dark" expand="lg" variant="dark" sticky="top" className="p-0">
      <div className="w-100 m-0 p-0 d-flex flex-column">
        <div className="row m-0">
          <ul className="navbar-top navbar-nav w-100 d-flex flex-row justify-content-end">
            <li>
              <a
                className="nav-link-top"
                href={t('navigation_banner.home_url')}
                target="_blank"
              >
                CivicRush.com
              </a>
            </li>
            <li>
              <Link className="nav-link-top" to="?locale=en">
                English
              </Link>
            </li>
            <li>
              <Link className="nav-link-top" to="?locale=es">
                Español
              </Link>
            </li>
          </ul>
        </div>
        <div className="row m-0 p-0 h-50 d-flex flex-row justify-content-between align-items-center">
          <Link
            className="col m-0 p-0 navbar-brand"
            to={currentUser?.id ? '/' : '/login'}
          >
            <img
              alt="logo"
              src="/images/CivicRush-Logo-500x162.png"
              className="logo img-fluid"
            />
          </Link>
          <div className="col p-0 d-flex justify-content-start flex-row-reverse">
            <BSNavbar.Toggle aria-controls="navbarCollapse" />

            <BSNavbar.Collapse id="navbarCollapse">
              {!(showAsLoggedOut || !currentUser?.id) ? (
                <ul className="navbar-nav ml-auto align-items-center">
                  <li className="nav-item">
                    <NavbarItem
                      linkClass="nav-link"
                      to="/"
                      iconName="home-heart"
                      content={t('navigation_banner.home')}
                    />
                  </li>
                  {hasBetaFeature('meta_accounting') ? (
                    <li className="nav-item">
                      <NavbarItem
                        linkClass="nav-link"
                        to="/dashboard-accounting"
                        iconName="magnifying-glass-dollar"
                        content={t('navigation_banner.accounting')}
                      />
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <NavbarItem
                      linkClass="nav-link"
                      to="/events/new"
                      iconName="plus"
                      content={t('devise.sessions.create')}
                    />
                  </li>

                  {hasBetaFeature('charities') ? (
                    <Dropdown className="nav-dropdown">
                      <Dropdown.Toggle className="nav-item">
                        <Icon name="hands-heart" className="me-2" />
                        {t('navigation_banner.charities')}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavbarItem
                            to="/charities"
                            iconName="hands-heart"
                            content={t('navigation_banner.my_charities')}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavbarItem
                            to="/dashboard-charity"
                            iconName="hands-usd"
                            content={t(
                              'navigation_banner.my_charities_dashboard',
                            )}
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}

                  <Dropdown className="nav-dropdown">
                    <Dropdown.Toggle className="nav-item">
                      {currentUser?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <NavbarItem
                          to="/my-posts"
                          iconName="calendar-days"
                          content={t('navigation_banner.my_posts')}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavbarItem
                          to="/engagements"
                          iconName="hand-peace"
                          content={t('navigation_banner.engagements')}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavbarItem
                          to="/groups"
                          iconName="users"
                          content={t('navigation_banner.groups')}
                        />
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item>
                        <NavbarItem
                          to="/civic-participation"
                          iconName="chart-line-up"
                          content={t(
                            'navigation_banner.civic_participation_link',
                          )}
                        />
                      </Dropdown.Item>
                      {hasBetaFeature('pf_organizer') ? (
                        <Dropdown.Item>
                          <NavbarItem
                            to="/dashboard-organizer"
                            iconName="calendar-check"
                            content={t('navigation_banner.organizer_dashboard')}
                          />
                        </Dropdown.Item>
                      ) : null}
                      <Dropdown.Divider />

                      <Dropdown.Item>
                        <NavbarItem
                          to="/settings"
                          iconName="cog"
                          content={t('navigation_banner.my_settings')}
                        />
                      </Dropdown.Item>
                      {hasBetaFeature('civ_wallet') ? (
                        <Dropdown.Item>
                          <WalletItem />
                        </Dropdown.Item>
                      ) : null}
                      <Dropdown.Divider />

                      {has_business && !!business_id ? (
                        <>
                          <Dropdown.Item>
                            <NavbarItem
                              to="/business-account"
                              iconName="building"
                              content={t('navigation_banner.business_account')}
                            />
                          </Dropdown.Item>
                          {hasBetaFeature('pf_business') ? (
                            <Dropdown.Item>
                              <NavbarItem
                                to="/dashboard"
                                iconName="grid-2"
                                content={t(
                                  'navigation_banner.business_dashboard',
                                )}
                              />
                            </Dropdown.Item>
                          ) : null}
                          <Dropdown.Divider />
                        </>
                      ) : null}

                      <Dropdown.Item
                        href={
                          locale === 'es'
                            ? 'https://civicrush.com/ayuda/'
                            : 'https://civicrush.com/help-and-support/'
                        }
                        target="_blank"
                      >
                        <Icon name="life-ring" className="me-2" />
                        {t('navigation_banner.help')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={
                          locale === 'es'
                            ? 'https://civicrush.com/faq-es/'
                            : 'https://civicrush.com/faq/'
                        }
                        target="_blank"
                      >
                        <Icon name="question" className="me-2" /> FAQs
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavbarItem
                          to="/contact-us"
                          iconName="envelope"
                          content={t('navigation_banner.contact_link')}
                        />
                      </Dropdown.Item>
                      <Dropdown.Divider />

                      <Dropdown.Item>
                        <NavbarItem
                          to="/logout"
                          iconName="sign-out-alt"
                          content={t('devise.sessions.logout')}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      {t('devise.sessions.login')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/signup">
                      {t('devise.sessions.join_now')}
                    </Link>
                  </li>
                </ul>
              )}
            </BSNavbar.Collapse>
          </div>
        </div>
      </div>
    </BSNavbar>
  )
}

Navbar.propTypes = {
  showAsLoggedOut: PropTypes.bool,
}
