import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, Button, Col, Modal, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import Badge from '../../components/badge'
import Icon from '../../components/icon'
import SponsorAdMessage from '../../components/SponsorAdMessage/SponsorAdMessage'
import { formatLocation } from '../../lib/address'
import { formatAmount } from '../../lib/numbers'
import { pledgeState } from '../../lib/pledges'

export default function SponsorPledge({ setLoading, refresh, setRefresh }) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState()
  const [sendMessageValue, setSendMessageValue] = useState()
  const [eventsWithPledges, setEventsWithPledges] = useState()

  const { locale } = useCurrentUser()
  const localeKey = locale === 'es' ? 'es_name' : 'en_name'

  const { civPledgeActionTmp, getBusiness, getEvent, getEventsWithPledges } =
    useApi()

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getEventsWithPledges()
      setEventsWithPledges(
        response.data.map(d => ({
          ...d,
          amountAccepted: d?.pledges
            ?.filter(p => p.state === 'accepted')
            .reduce((acc, obj) => {
              return acc + obj.amount
            }, 0),
          totalPending: d?.pledges?.filter(p => p.state === 'pending').length,
        })),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getEventsWithPledges])

  useEffect(() => {
    fetchData()
  }, [refresh])

  const onClickView = data => {
    setLoading(true)
    const promises = []
    // Event Data
    const eventPromise = new Promise(resolve => {
      resolve(getEvent({ id: data.event_id }))
    })
      .then(response => {
        return { event: response.data }
      })
      .catch(error => {
        return { event: null }
      })
    promises.push(eventPromise)
    // Business Data
    const businessPromise = new Promise(resolve => {
      resolve(getBusiness(data.business_id))
    })
      .then(response => {
        return { business: response.data }
      })
      .catch(error => {
        return { business: null }
      })
    promises.push(businessPromise)

    Promise.all(promises).then(values => {
      const result = values.reduce((acc, curr) => {
        const key = Object.keys(curr)[0]
        const value = curr[key]
        acc[key] = value
        return acc
      }, {})
      setModalData({ ...result, pledge: { ...data } })
      setLoading(false)
      setShowModal(true)
    })
  }

  const onClickModalAction = async params => {
    const response = await civPledgeActionTmp(params)
    if (!!response.error) {
      // eslint-disable-next-line no-alert
      alert(response.error?.response.data.error)
    } else {
      setRefresh(+new Date())
      setShowModal(false)
    }
  }

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('organizer.sponsorPledge.title')}</h1>
        </div>
      </div>
      <div className="container">
        {eventsWithPledges?.length ? (
          <Accordion flush>
            {eventsWithPledges.map(e => (
              <Accordion.Item
                eventKey={e.event.id}
                className="border event-pledge"
              >
                <Accordion.Header className="border-top">
                  <Row className="w-100 justify-content-between align-items-center">
                    <Col className="text-start fw-semibold" lg="4">
                      {e.event.title}
                    </Col>
                    <Col className="text-start">
                      <Trans
                        i18nKey="organizer.sponsorPledge.dateTime"
                        values={{
                          date: moment(e.event.event_start_date).format(
                            t('config.date_format'),
                          ),
                          time: moment(e.event.event_start_date).format(
                            t('config.time_format'),
                          ),
                        }}
                      />
                    </Col>
                    <Col className="text-end pe-4">
                      {!!e.amountAccepted ? (
                        <>
                          <Trans
                            i18nKey="organizer.sponsorPledge.amountAccepted"
                            values={{
                              total: formatAmount(e.amountAccepted),
                            }}
                          />
                          {!!e.totalPending && <span>, </span>}
                        </>
                      ) : null}
                      {!!e.totalPending ? (
                        <>
                          <span className="badge rounded-pill text-dark bg-white border border-danger ">
                            {e.totalPending}
                          </span>
                          <span className="ms-1">
                            {t('organizer.sponsorPledge.actionRequired')}
                          </span>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  {e.pledges.map(p => (
                    <Row className="w-100 justify-content-between border-top py-2 m-0">
                      <Col className="text-start" lg="4">
                        {p.business_name}
                      </Col>
                      <Col className="text-start">
                        <Trans
                          i18nKey="organizer.sponsorPledge.amount"
                          values={{
                            total: formatAmount(p.amount),
                          }}
                        />
                      </Col>
                      <Col className="text-start">
                        {pledgeState(p, 'organizer.sponsorPledge')}
                      </Col>
                      <Col className="text-end pe-5">
                        <button
                          type="button"
                          className="btn btn-link p-0 text-decoration-none"
                          onClick={() => onClickView({ ...p })}
                        >
                          {t('organizer.sponsorPledge.view')}
                        </button>
                      </Col>
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <h6>{t('organizer.sponsorPledge.noResults')}</h6>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Badge />
          <Modal.Title className="text-center w-100 p-0">
            {t('organizer.sponsorPledge.modal.heading')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="block-description">
            <Trans
              i18nKey="organizer.sponsorPledge.modal.message"
              values={{
                businessName: modalData?.business?.name,
                amount: modalData?.pledge.amount,
              }}
            />
          </p>
          {modalData?.pledge?.message_to_event_owner ? (
            <p className="block-description">
              <span className="defaultText">
                {t('organizer.sponsorPledge.modal.sponsorMessage')}
              </span>
              <br />
              {modalData?.pledge?.message_to_event_owner}
            </p>
          ) : null}
          {modalData?.business?.categories.length ? (
            <>
              <p className="block-description pt-4 pb-1 mb-0">
                {t('organizer.sponsorPledge.modal.supportCauses', {
                  businessName: modalData?.business?.name,
                })}
              </p>
              <p className="block-description pt-0 pb-2 font-size-0_8 lh-sm">
                {modalData?.business?.categories
                  .map(c => c[localeKey])
                  .join(', ')}
              </p>
            </>
          ) : null}
          <hr />
          <div>
            <p className="fw-semibold">
              {t('organizer.sponsorPledge.modal.preview')}
            </p>
            <SponsorAdMessage business={modalData?.business} />
          </div>
        </Modal.Body>
        {modalData?.pledge?.state === 'pending' ? (
          <Modal.Footer>
            <Row className="w-100">
              <Col>
                <Row className="d-flex flex-column">
                  <Col className="px-0">
                    <p className="block-description">
                      {t('organizer.sponsorPledge.modal.sendMessage', {
                        businessName: modalData?.business?.name,
                      })}
                    </p>
                  </Col>
                  <Col className="px-0">
                    <textarea
                      title="sendMessage"
                      id="sendMessage"
                      rows="2"
                      style={{ height: '100%' }}
                      className="form-control"
                      onChange={e => setSendMessageValue(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="w-100">
              <Col className="terms">
                <span>{t('organizer.sponsorPledge.modal.terms.message')}</span>
                <a href={t('organizer.sponsorPledge.modal.terms.link.url')}>
                  {t('organizer.sponsorPledge.modal.terms.link.text')}
                </a>
              </Col>
            </Row>
            <Row className="w-100 justify-content-evenly pt-3">
              <Col className="text-center">
                <Button
                  variant="secondary"
                  onClick={() =>
                    onClickModalAction({
                      action: 'decline',
                      pledgeId: modalData?.pledge.id,
                      eventId: modalData?.event.id,
                      message: sendMessageValue,
                    })
                  }
                >
                  <Icon name={'box-heart'} />
                  <span className="ps-2">
                    {t('organizer.sponsorPledge.modal.declineButton')}
                  </span>
                </Button>
              </Col>
              <Col className="text-center">
                <Button
                  variant="primary"
                  onClick={() =>
                    onClickModalAction({
                      action: 'accept',
                      pledgeId: modalData?.pledge.id,
                      eventId: modalData?.event.id,
                      message: sendMessageValue,
                    })
                  }
                >
                  <Icon name={'box-heart'} />
                  <span className="ps-2">
                    {t('organizer.sponsorPledge.modal.acceptButton')}
                  </span>
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  )
}
