import { useTranslation } from 'react-i18next'
import React from 'react'

import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function AccountSidebar() {
  const { locale } = useCurrentUser()
  const { t } = useTranslation()

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <img
        alt="CivicRush mobile"
        className="img-fluid"
        src="/images/mobile-sample.png"
        width="75%"
      />
      <p className="call-to-action">{t('devise.sessions.free_download')}</p>
      <div className="row" style={{ textAlign: 'center' }}>
        <div className="col">
          <a
            href="https://apps.apple.com/us/app/civicrush-volunteer/id1382111745"
            target="_blank"
            rel="noreferrer"
          >
            {locale === 'es' && (
              <img
                src="/images/badge_apple-store-espanol.png"
                className="img-fluid"
                alt="App Store"
              />
            )}
            {locale === 'en' && (
              <img
                src="/images/badge_apple-store-english.png"
                className="img-fluid"
                alt="App Store"
              />
            )}
          </a>
        </div>

        <div className="col">
          <a
            href="https://play.google.com/store/apps/details?id=com.civicrush&hl=en_US"
            target="_blank"
            rel="noreferrer"
          >
            {locale === 'es' && (
              <img
                src="/images/badge_google-play-espanol.png"
                className="img-fluid"
                alt="Google Play"
              />
            )}
            {locale === 'en' && (
              <img
                src="/images/badge_google-play-english.png"
                className="img-fluid"
                alt="Google Play"
              />
            )}
          </a>
        </div>
      </div>
    </div>
  )
}
