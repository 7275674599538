import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import SortTable from '../../components/sortTable/sortTable'
import CharityRegistrationModal from './charityRegistrationModal'
import { formatEin, toCapitalizeWords } from '../../lib/strings'

export default function CharityRegistration({ setLoading }) {
  const { t } = useTranslation()
  const [refresh, setRefresh] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState()
  const [charityRegistrations, setCharityRegistrations] = useState([])
  const [queryParams, setQueryParams] = useState({
    sort_by: 'created_at',
    sort_order: 'DESC',
  })

  const { getCharityRegistrations } = useApi()

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCharityRegistrations(queryParams)
      setCharityRegistrations(
        response.data?.map(d => ({
          created_at: moment(d.created_at).format(t('config.date_format')),
          charity_name: toCapitalizeWords(d.charity.organization_name),
          ein: formatEin(d.charity.ein),
          status: t(`crStatus.${d.status.toLowerCase().replaceAll(' ', '_')}`),
          user_name: d.user?.name,
          details: (
            <button
              type="button"
              className="btn btn-link p-0 text-decoration-none"
              onClick={() => onClickView({ ...d })}
            >
              {t('accounting.charityRegistration.headers.view')}
            </button>
          ),
        })),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getCharityRegistrations, queryParams])

  useEffect(() => {
    if (!!refresh) {
      fetchData()
    }
  }, [refresh])

  useEffect(() => {
    fetchData()
  }, [queryParams])

  const onClickView = data => {
    setModalData(data)
    setShowModal(true)
  }

  const headers = [
    {
      title: t('accounting.charityRegistration.headers.date'),
      prop: 'created_at',
      isSortable: true,
    },
    {
      title: t('accounting.charityRegistration.headers.charity_name'),
      prop: 'charity_name',
      isSortable: true,
    },
    {
      title: t('accounting.charityRegistration.headers.ein'),
      prop: 'ein',
      isSortable: true,
    },
    {
      title: t('accounting.charityRegistration.headers.cr_status'),
      prop: 'status',
      isSortable: true,
    },
    {
      title: t('accounting.charityRegistration.headers.registrant'),
      prop: 'user_name',
      isSortable: true,
    },
    {
      title: t('accounting.charityRegistration.headers.details'),
      prop: 'details',
    },
  ]

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  return (
    <>
      <Accordion.Item
        eventKey="charityRegistration"
        className="border event-pledge"
      >
        <Accordion.Header className="border-top">
          <Row className="w-100 justify-content-between align-items-center">
            <Col className="text-start fw-semibold" lg="4">
              {t('accounting.charityRegistration.title')}
            </Col>
            <Col className="text-end pe-4">
              {charityRegistrations?.length
                ? t('accounting.charityRegistration.totalRequests', {
                    total: charityRegistrations?.length,
                  })
                : null}
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body className="p-0">
          <SortTable
            body={charityRegistrations}
            headers={headers}
            onFetch={s => onFetch(s)}
            showPagination={false}
          />
        </Accordion.Body>
      </Accordion.Item>
      <CharityRegistrationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        data={modalData}
        setRefresh={setRefresh}
      />
    </>
  )
}
