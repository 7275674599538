import { useLocation } from 'react-router-dom'
import React from 'react'

import CharityRegisterForm from './form'
import Styles from '../charities.module.scss'
import UserCharitiesList from '../userCharitiesList'

export default function CharityRegisterPage() {
  const location = useLocation()

  const { charity } = location.state

  return (
    <div className="container">
      <div className={Styles.charityIndexPage}>
        <div className="row">
          <div className="col-lg-7">
            <CharityRegisterForm charity={charity} />
          </div>
          <div className="col-lg-4 offset-lg-1">
            <UserCharitiesList />
          </div>
        </div>
      </div>
    </div>
  )
}
