import PropTypes from 'prop-types'
import React from 'react'

import styles from './loading.module.scss'

export default function Loading(props) {
  const { show } = props

  if (!show) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.throbber}>
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
}
