import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'

import { formatAmount } from '../../lib/numbers'

const civillos = {
  name: 'Civillos',
  address: {
    street: '12020 Sunrise Valley Drive, Suite 100',
    city: 'Reston',
    state: 'VA',
    zip: '20191',
  },
  email: 'info@civillos.org',
  ein: '45-5519129',
  website: 'www.civillos.org',
  contact: {
    name: 'Andrew Beck',
    title: 'Secretary',
  },
}

export default function Acknowledgement({ business, pledge, fileName }) {
  const { t } = useTranslation()

  const TextLine = ({ str }) => <Text style={styles.textline}>{str}</Text>
  const civillosLocation = [
    civillos.address.city,
    civillos.address.state,
    civillos.address.zip,
  ]
    .filter(l => !!l)
    .join(', ')
  const address = [business?.address_line_1, business?.address_line_2]
    .filter(l => !!l)
    .join(' ')
  const location = [business?.city, business?.state, business?.zip]
    .filter(l => !!l)
    .join(', ')

  return (
    <Document title={fileName}>
      <Page size="LETTER" style={styles.pdfPage}>
        <View style={styles.blockDouble}>
          <Text style={styles.title}>{t('pdf.acknowledgement.title')}</Text>
        </View>
        <View style={styles.block}>
          <Image style={styles.img} src="/images/civillos.png" />
        </View>
        <View style={styles.block}>
          <TextLine str={civillos.name} />
          <TextLine str={civillos.address.street} />
          <TextLine str={civillosLocation} />
        </View>
        <View style={styles.blockDouble}>
          <TextLine str={business?.name} />
          <TextLine str={address} />
          <TextLine str={location} />
        </View>
        <View style={styles.block}>
          <TextLine
            str={moment(pledge?.transaction_date).format(
              t('config.date_format'),
            )}
          />
        </View>
        <View style={styles.block}>
          <TextLine str={`${business?.name},`} />
        </View>
        <View style={styles.block}>
          <TextLine
            str={t('pdf.acknowledgement.line_1', {
              organizationName: civillos.name,
              amount: formatAmount(pledge.amount, true),
              date: moment(pledge?.transaction_date).format(
                t('config.date_format'),
              ),
            })}
          />
        </View>
        <View style={styles.block}>
          <TextLine str={t('pdf.acknowledgement.line_2')} />
        </View>
        <View style={styles.block}>
          <TextLine str={t('pdf.acknowledgement.line_3')} />
        </View>
        <View style={styles.block}>
          <TextLine
            str={t('pdf.acknowledgement.line_4', {
              organizationEmail: civillos.email,
            })}
          />
        </View>
        <View style={styles.block}>
          <TextLine str={t('pdf.acknowledgement.thankYou')} />
        </View>
        <View style={styles.block}>
          <TextLine str={t('pdf.acknowledgement.sincerely')} />
        </View>
        <View>
          <TextLine str={civillos.name} />
          {/* <TextLine str={civillos.contact.name} />
          <TextLine str={civillos.contact.title} /> */}
          <TextLine
            str={t('pdf.acknowledgement.ein', { number: civillos.ein })}
          />
          <TextLine str={civillos.website} />
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pdfPage: {
    padding: '25px 75px',
    border: 'black',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 24,
  },
  img: {
    width: '160px',
    height: '33px',
  },
  blockDouble: {
    paddingBottom: '40px',
  },
  block: {
    paddingBottom: '20px',
  },
  textline: {
    fontSize: 14,
    textAlign: 'justify',
  },
})
