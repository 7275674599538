import React from 'react'
import { Button, Card } from 'react-bootstrap'

import styles from './civ-card.module.scss'

export default function CivCard({
  header,
  body,
  footer,
  buttonText,
  buttonOnClick,
  buttonDisabled,
  buttonHidden = false,
}) {
  return (
    <div className={styles.mainContainer}>
      <Card bg="light" border="secondary" className={styles.card}>
        <Card.Header className="text-start border-bottom-0 bg-white">
          {header}&nbsp;
        </Card.Header>
        <Card.Body className={styles.body}>{body}</Card.Body>
        <Card.Footer className="text-end border-top-0 bg-white">
          {footer}&nbsp;
        </Card.Footer>
      </Card>
      {!buttonHidden ? (
        <Button
          variant="primary"
          onClick={buttonOnClick}
          disabled={buttonDisabled}
          className="w-100"
        >
          {buttonText}
        </Button>
      ) : null}
    </div>
  )
}
