import React from 'react'
import QRCode from 'react-qr-code'

import styles from './qr.module.scss'

export default function QR({
  size = 256,
  value = '', // Content: url or json
  title = '',
  color = '#66308d', // --CRPURPLE as default
  svgOnly = false,
}) {
  if (svgOnly) {
    return <QRCode size={size} value={value} title={title} fgColor={color} />
  }
  return (
    <div
      className={styles.qrContainer}
      style={{ width: size + 15, borderColor: color }}
    >
      <QRCode size={size} value={value} title={title} fgColor={color} />
    </div>
  )
}
