import PropTypes from 'prop-types'

export interface ApiResponse {
  data?: any
  errors?: any
}

export interface BankAccount {
  bank_account_number_last4?: string
}

export interface Category {
  en_name: string
  es_name: string
  icon_library?: string
  icon?: string
  id: number
}

export interface Group {
  id: number
  name: string
}

export interface Charity {
  id?: number
  address_line_1?: string
  address_line_2?: string
  addressLine1?: string
  addressLine2?: string
  available?: boolean
  city?: string
  county?: string
  ein?: string
  name?: string
  organization_name: string
  registered?: boolean
  registration_status?: string
  state?: string
  zip?: string
}

export interface CharityRegistration {
  id: number
  charity: Charity
  status: string
}

export const CharityType = PropTypes.shape({
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  available: PropTypes.bool,
  county: PropTypes.string,
  ein: PropTypes.string,
  name: PropTypes.string,
  registered: PropTypes.bool,
})

export default {
  CharityType,
}
