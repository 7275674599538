import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ModalDraft({ show, onHide, onClick }) {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('event.draft_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('event.draft_modal_message')}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>{t('event_card.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
