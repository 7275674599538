import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'

import Button from '../button'
import styles from '../../pages/events/sponsorship/sponsorship.module.scss'
import TextInput from '../TextInput/TextInput'
import useWallet from '../../hooks/useWallet'
import PledgeHeader from './PledgeHeader'
import Icon from '../icon'

interface SponsorPledgeFormProps {
  business?: any
  event?: any
  onNext: () => void
  onNextPurchaseCiv: () => void
  onNextAddFundingSource: () => void
  pledgeAmount?: string
  setPledgeAmount: (amount: string) => void
  setCivMissing: (amount: string) => void
}

export default function SponsorPledgeForm(props: SponsorPledgeFormProps) {
  const { t } = useTranslation()

  const {
    hasFundingSource,
    refresh: refreshWallet,
    wallet,
  } = useWallet(props.business?.wallet_id, props.business?.bank_account_id)

  // const fee = useMemo(
  //   () => calculateFee(props.pledgeAmount || '0'),
  //   [calculateFee, props.pledgeAmount],
  // )

  // const eventTotalHours = useMemo(() => {
  //   if (props.event?.event_start_date && props.event?.event_end_date) {
  //     const start = moment(props.event.event_start_date)
  //     const end = moment(props.event.event_end_date)
  //     return parseFloat((end.diff(start) / 3600000).toFixed(2))
  //   }
  //   return 1
  // }, [props.event?.event_start_date, props.event?.event_end_date])

  // const volunteerCount = useMemo(() => {
  //   return props.event?.volunteers_needed || 1
  // }, [props.event?.volunteers_needed])

  // const totalVolunteerTime = useMemo(() => {
  //   const volunteers = volunteerCount || 1
  //   return eventTotalHours * volunteers
  // }, [eventTotalHours, volunteerCount])

  // const totalDue = useMemo(() => {
  //   const amount = parseFloat(props.pledgeAmount ?? '0')
  //   return parseFloat((amount + fee).toFixed(2)) || 0
  // }, [fee, props.pledgeAmount])

  const civStillNeededAfterWalletBalance = useMemo(() => {
    const amount = parseFloat(props.pledgeAmount ?? '0')
    const total = amount - (wallet?.balance ?? 0)
    if (total < 0) {
      props.setCivMissing('0')
      return 0
    }
    props.setCivMissing(total.toFixed(2))
    return parseFloat(total.toFixed(2)) || 0
  }, [props.pledgeAmount, wallet.balance, props.setCivMissing])

  const pledgeAmountNumber = useMemo(() => {
    let amount = props.pledgeAmount?.length ? props.pledgeAmount : '0'
    return parseFloat(amount)
  }, [props.pledgeAmount])

  const handleOnClickNext = useCallback(async () => {
    props.onNext()
  }, [props])

  const handleOnClickNextPurchaseCiv = useCallback(async () => {
    props.onNextPurchaseCiv()
  }, [props])

  const handleOnClickNextAddFundingSource = useCallback(async () => {
    props.onNextAddFundingSource()
  }, [props])

  useEffect(() => {
    props.business?.wallet_id && refreshWallet()
  }, [refreshWallet, props.business?.wallet_id])

  return (
    <>
      <div className="mb-3">
        <h2>
          <b>{t('sponsorship.pledgeModal.title')}</b>
        </h2>
      </div>
      <div className="mb-3">
        <div className={styles.section}>
          <PledgeHeader event={props.event} pledgeAmount={props.pledgeAmount} />
        </div>
      </div>
      <div className="text-center mt-2 mb-3">
        <strong>{t('sponsorship.pledgeModal.volunteerPayForward')}</strong>
      </div>
      <div className="mb-3">
        <div className={styles.calculator}>
          <Table>
            <tbody>
              <tr className={styles.lineThick}>
                <td className={styles.tableRowKey}>
                  {t('sponsorship.pledgeModal.pledgeAmountTitle')}
                </td>
                <td className={styles.tableRowValue}>
                  <TextInput
                    min={0}
                    onChange={props.setPledgeAmount}
                    placeholder={t(
                      'sponsorship.pledgeModal.pledgeAmountPlaceholder',
                    )}
                    type="number"
                    value={props.pledgeAmount}
                    inputStyle={{
                      maxWidth: '100px',
                      height: '30px',
                      marginLeft: '-15px',
                    }}
                  />
                </td>
              </tr>

              <tr className={styles.line}>
                <td className={styles.tableRowKey}>
                  {t('sponsorship.pledgeModal.walletBalance')}
                </td>
                <td className={styles.tableRowValue}>
                  <b>{wallet?.balance ?? '0'} CIV</b>
                </td>
              </tr>

              <tr>
                <td className={styles.tableRowKey}>
                  {t('sponsorship.pledgeModal.additionalNeeded')}
                </td>
                <td className={styles.tableRowValue}>
                  <b>{civStillNeededAfterWalletBalance} CIV</b>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.terms}>
          {t('sponsorship.pledgeModal.pledgeFinePrint')}
        </div>
      </div>

      <div className={styles.section}>
        <div className="d-flex align-items-end flex-column">
          {hasFundingSource ? (
            <>
              {civStillNeededAfterWalletBalance > 0 ? (
                <Button
                  className="text-right mb-4"
                  disabled={pledgeAmountNumber <= 0}
                  onClick={handleOnClickNextPurchaseCiv}
                >
                  <Icon name="box-heart" />{' '}
                  {t('sponsorship.pledgeModal.purchaseCivButton')}
                </Button>
              ) : (
                <Button
                  className="text-right mb-4"
                  disabled={pledgeAmountNumber <= 0}
                  onClick={handleOnClickNext}
                >
                  {t('sponsorship.pledgeModal.nextButton')}
                </Button>
              )}
            </>
          ) : (
            <Button
              className="text-right mb-4"
              onClick={handleOnClickNextAddFundingSource}
            >
              <Icon name="plus" />{' '}
              {t('sponsorship.pledgeModal.addFundingSourceButton')}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
