import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import {
  Document,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'

import { isVideo } from '../../lib/files'
import {
  sortCategoriesAlphabetically,
  sortObjectsAlphabetically,
} from '../../lib/sort'
import { getTeam, removeHTMLTags } from '../../lib/event'
import { formatLocation } from '../../lib/address'

export default function EventFlyer({
  event,
  acceptedPledges,
  fileName,
  qr,
  locale,
}) {
  const { i18n, t } = useTranslation()

  const mainImage = isVideo(event.media_attachments[0])
    ? event.media_attachments[0].thumbnail_url
    : event.media_attachments[0].direct_upload_url
  const team = getTeam(event)

  const startMoment = moment(event?.event_start_date || undefined).tz(
    moment.tz.guess(),
  )
  const endMoment = (
    event?.event_end_date ? moment(event.event_end_date) : moment().add(1, 'h')
  ).tz(moment.tz.guess())
  const sameDay = startMoment.isSame(endMoment, 'day')
  const formattedLocation = formatLocation(event.location)

  useEffect(() => {
    moment.locale(locale)
    i18n.changeLanguage(locale)
  }, [locale])

  return (
    <Document title={fileName}>
      <Page size="A4" style={styles.pdfPage}>
        <View style={styles.block}>
          <Image
            style={styles.img}
            src={{
              uri: mainImage,
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
              body: '',
            }}
          />
        </View>
        <View style={styles.containerColumns}>
          <View style={styles.leftColumn}>
            <View style={styles.block}>
              <Text style={styles.title}>{event.title}</Text>
            </View>
            <View style={styles.smallBlock}>
              <Text style={styles.text}>{removeHTMLTags(event.details)}</Text>
            </View>
            {event.volunteers_details && (
              <View style={styles.block}>
                <Text style={styles.textBold}>{t('event.volunteer_info')}</Text>
                <Text style={styles.text}>
                  {removeHTMLTags(event.volunteers_details)}
                </Text>
              </View>
            )}
            {event.hashtags && (
              <View style={styles.block}>
                <Text style={styles.text}>{event.hashtags}</Text>
              </View>
            )}
            <View style={styles.block}>
              <Text style={styles.text}>
                {t('event.by')}{' '}
                <Text style={styles.textBold}>{event.user?.name}</Text>
              </Text>
            </View>
            {!!team.length && (
              <View style={styles.block}>
                <Text style={styles.text}>
                  {t('event.team')}
                  <Text style={styles.textBold}>
                    {sortObjectsAlphabetically(team, 'name')
                      .map(t => t.name)
                      .join(', ')}
                  </Text>
                </Text>
              </View>
            )}
            {!!event.groups && (
              <View style={styles.block}>
                <Text style={styles.text}>
                  {t('event.private_groups')}
                  <Text style={styles.textBold}>
                    {sortObjectsAlphabetically(event.groups ?? [], 'name')
                      .map(group => group.name)
                      .join(', ')}
                  </Text>
                </Text>
              </View>
            )}
            <View style={styles.categories}>
              {!!event.categories && !!event.categories.length ? (
                sortCategoriesAlphabetically(event.categories, locale).map(
                  category => (
                    <View style={styles.categoryPill} key={category.id}>
                      <Text>
                        {locale === 'es' ||
                        localStorage.getItem('i18nextLng') === 'es'
                          ? category.es_name
                          : category.en_name}
                      </Text>
                    </View>
                  ),
                )
              ) : (
                <View style={styles.categoryPill} key={25}>
                  <Text>
                    {locale === 'es' ||
                    localStorage.getItem('i18nextLng') === 'es'
                      ? 'Mejorar la Comunidad'
                      : 'Community Improvement'}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.block}>
              <Image
                style={styles.imgLogo}
                src="/images/CR-Logo-PrintEvent.png"
              />
            </View>
            <View style={styles.viewQR}>
              <Image style={styles.imgQR} src={qr} />
            </View>
            {event.event_type !== 'need' && (
              <View style={styles.date}>
                <Text style={styles.textBold}>{t('event.date_time')}</Text>
                <Text style={styles.text}>
                  {startMoment.format(t('config.date_format'))}
                  {!sameDay && (
                    <>
                      {' '}
                      {t('event.to')}{' '}
                      {endMoment.format(t('config.date_format'))}
                    </>
                  )}
                </Text>
                <Text style={styles.text}>
                  {startMoment.format('h:mm a')} {t('event.to')}{' '}
                  {endMoment.format('h:mm a z')}
                </Text>
              </View>
            )}
            <View style={styles.date}>
              <Text style={styles.textBold}>{t('event.location')}</Text>
              <Text style={styles.text}>
                {!!event.remote
                  ? t('event.remote')
                  : !!formattedLocation
                    ? formattedLocation
                    : t('event.locationDefault')}
              </Text>
            </View>
            {!!event.service_hours && (
              <View style={styles.block}>
                <Text style={styles.text}>
                  {t('event_card.has_service_hours')}
                </Text>
              </View>
            )}
            {acceptedPledges?.length && (
              <View style={styles.block}>
                <Text style={styles.sponsorsTitle}>
                  {t('event.sponsored_by')}
                </Text>
                <View style={styles.sponsorsContainer}>
                  {acceptedPledges.map(pledge => (
                    <View
                      style={styles.sponsorAdMessage}
                      key={pledge?.business?.name}
                    >
                      <View style={styles.sponsorLogo}>
                        <Image
                          style={styles.sponsorLogoImage}
                          src={{
                            uri:
                              pledge?.business?.media_attachment
                                ?.direct_upload_url ||
                              '/images/default-business.png',
                            method: 'GET',
                            headers: { 'Cache-Control': 'no-cache' },
                            body: '',
                          }}
                        />
                      </View>
                      <View style={styles.sponsorContent}>
                        <View style={styles.sponsorText}>
                          <Text style={styles.textBold}>
                            {pledge?.business?.name}
                            <Text
                              style={styles.text}
                            >{` - ${pledge?.business?.pay_forward_message}`}</Text>
                          </Text>
                        </View>
                        <View style={styles.sponsorFooter}>
                          <Text style={styles.sponsorLabel}>
                            {t('business.sponsor_ad_message.preview.sponsor')}
                          </Text>
                          <Link
                            style={styles.url}
                            src={`https://${pledge?.business?.website_url}`}
                          >
                            {t('business.sponsor_ad_message.preview.website')}
                          </Link>
                        </View>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.appQRView}>
            <Image style={styles.appQR} src="/images/getApp-QrCode.png" />
          </View>
          <View style={styles.urlView}>
            <Link
              style={styles.url}
              src={`${window.location.origin}/events/${event.slug || event.id}`}
            >
              {`${window.location.origin}/events/${event.slug || event.id}`}
            </Link>
          </View>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pdfPage: {
    border: 'black',
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  containerColumns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    width: 400,
    paddingHorizontal: 30,
  },
  rightColumn: {
    width: 275,
    backgroundColor: 'white',
    marginRight: 35,
    paddingTop: 10,
    position: 'relative',
    top: '-100px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  section: {
    marginTop: 10,
  },
  text: {
    color: '#555555',
  },
  textBold: {
    color: 'black',
  },
  img: {
    width: '100%',
    height: '200px',
    alignSelf: 'center',
    objectFit: 'cover',
  },
  smallBlock: {
    paddingBottom: '10px',
  },
  block: {
    paddingBottom: '20px',
  },
  categories: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 5,
  },
  categoryPill: {
    backgroundColor: '#FFF',
    color: '#4a4a4a',
    fontSize: 8,
    border: '2px solid #e6526c',
    borderRadius: '4px',
    padding: 3,
    alignSelf: 'center',
  },
  imgLogo: {
    paddingHorizontal: 20,
    width: 200,
  },
  viewQR: {
    marginBottom: 20,
    justifyContent: 'center',
    border: '3px solid #66308d',
    borderRadius: '10px',
    padding: 5,
    height: 100,
  },
  imgQR: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  appQRView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 35,
  },
  appQR: {
    width: 200,
  },
  urlView: {
    marginTop: 10,
    marginLeft: 10,
  },
  url: {
    fontSize: 8,
    textDecoration: 'none',
    color: '#e6526c',
  },
  sponsorsTitle: {
    marginBottom: 5,
    fontSize: 8,
    color: '#555555',
  },
  sponsorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
  sponsorAdMessage: {
    padding: 5,
    border: '1px solid #9b9b9b',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    width: 220,
  },
  sponsorLogo: {
    width: 50,
    marginRight: 5,
  },
  sponsorLogoImage: {
    borderRadius: '50%',
    border: '1px solid #9b9b9b',
    objectFit: 'contain',
  },
  sponsorContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 175,
  },
  sponsorText: {
    display: 'flex',
    fontSize: 8,
  },
  sponsorLabel: {
    fontSize: 7,
    color: '#9b9b9b',
  },
  sponsorFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
