import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// }

export default function CarouselMulti({ items, ...rest }) {
  return (
    <Carousel {...rest}>
      {items.map(item => (
        <div key={`item-multi-${item.id}`} className="p-2">
          {item}
        </div>
      ))}
    </Carousel>
  )
}
