import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './SponsorAdMessage.module.scss'
import Icon from '../icon'

export default function SponsorAdMessage({ adMessage, business }) {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          alt="Avatar"
          className="rounded-circle avatar-border bg-white"
          src={
            business?.media_attachment?.direct_upload_url ||
            '/images/default-business.png'
          }
        />
      </div>
      <div className={styles.content}>
        <div className={styles.messageContent}>
          <span className={styles.name}>{business?.name}</span>
          <span className={styles.message}>
            &nbsp;-&nbsp;
            {adMessage?.length ? adMessage : business?.pay_forward_message}
          </span>
        </div>
        <div className={styles.footer}>
          <span className={styles.ref}>
            {t('business.sponsor_ad_message.preview.sponsor')}
          </span>
          <span>
            <a
              href={`https://${business?.website_url}`}
              target="_blank"
              title={`${business?.name} ${t(
                'business.sponsor_ad_message.preview.website',
              )}`}
            >
              {t('business.sponsor_ad_message.preview.website')}
              &nbsp;
              <Icon name="external-link-alt" />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}
