import propTypes from 'prop-types'
import Icon from './icon'

export default function CategoryPill({ category, locale }) {
  return (
    <span className="category-pill text-nowrap">
      <Icon name={category?.icon} /> {category[`${locale}_name`]}
    </span>
  )
}

CategoryPill.propTypes = {
  category: propTypes.object.isRequired,
  locale: propTypes.string.isRequired, // more efficient to pass in instead of calling mult times here
}
