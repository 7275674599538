import ReactPDF, { useEffect, useState, useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
} from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { formatLocation } from '../../lib/address'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Loading from '../../components/loading'
import useApi from '../../hooks/useApi'
import { Alert } from 'react-bootstrap'

const thumbnailPlaceholder = '/images/thumbnail-placeholder.png'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: '/fonts/Roboto-Regular.ttf',
    },
    { src: '/fonts/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 'bold' },
    { src: '/fonts/Roboto-Light.ttf', fontStyle: 'light', fontWeight: 'light' },
  ],
})

export default function GroupEngagements() {
  const { getGroupEngagementsPDFData, getGroup } = useApi()
  const { t } = useTranslation()
  const params = useParams()

  const [events, setEvents] = useState(null)
  const [group, setGroup] = useState(null)
  const [groupImage, setGroupImage] = useState()

  const fetchData = useCallback(async () => {
    const {
      data: { events: _events },
    } =
      (await getGroupEngagementsPDFData({
        id: params.slug,
      })) ?? []
    setEvents(_events)
    const fetchGroup = await getGroup({ id: params.slug })
    setGroup(fetchGroup.data)
  }, [getGroupEngagementsPDFData, getGroup])

  const fileName = useMemo(() => {
    const name = group?.name.trim().replace(/\W/g, '-') ?? 'group'
    const date = moment().format('YYYY-MM-DD')
    return `${name}_engagements_${date}.pdf`
  }, [group])

  useEffect(() => {
    fetchData()
  }, [])

  if (!group) return <Loading show />

  const engagementsPDF = (
    <Document title={fileName}>
      <Page size="letter" style={styles.pdfPage}>
        <View
          style={{ ...styles.col, rowGap: '4px', marginBottom: '5px' }}
          fixed
        >
          <View style={{ ...styles.row, justifyContent: 'flex-start' }}>
            <Image
              style={{ ...styles.groupImg, width: '75px', height: '75px' }}
              src={{
                uri: group?.image_url || thumbnailPlaceholder,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: '',
              }}
            />
            <View style={{ ...styles.col, rowGap: '3px' }}>
              <Text style={styles.groupName}>
                {group.name ?? 'Error: Group Not Found'}
              </Text>
              <Text style={styles.upcoming}>
                {t('engagements.print.upcoming')}
              </Text>
            </View>
          </View>
          <View style={styles.hrLine} />
          <View style={{ ...styles.row, ...styles.columnLabels }}>
            {/* all of this font should be bolded. */}
            <Text
              style={{
                /*border: '2px solid green',*/ width: '200px',
                left: '70px',
              }}
            >
              {t('engagements.print.event')}
            </Text>
            <Text style={{ /*border: '2px solid green',*/ width: '100px' }}>
              {t('engagements.print.date')}
            </Text>
            <Text style={{ /*border: '2px solid green',*/ width: '80px' }}>
              {t('engagements.print.time')}
            </Text>
            <Text style={{ /*border: '2px solid green',*/ width: '136px' }}>
              {t('engagements.print.location')}
            </Text>
          </View>
          <View
            style={{ ...styles.hrLineLight, margin: '8px 0 12px 0' }}
          ></View>
        </View>

        <View style={styles.col}>
          {events?.length !== 0 &&
            events.map((event, i) => {
              const startDate = moment(event.event_start_date)
              const endDate = moment(event.event_end_date)
              const date = endDate.isSame(startDate, 'day')
                ? startDate.format(t('config.date_format'))
                : `${startDate.format(t('config.date_format'))} ${t(
                    'engagements.print.to',
                  )} ${endDate.format(t('config.date_format'))}`
              return (
                <View style={styles.col} key={event.id}>
                  <View style={{ ...styles.row, rowGap: '3px' }}>
                    <View
                      style={{
                        ...styles.row,
                        width: '200px',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <View>
                        <Image
                          style={styles.groupImg}
                          src={{
                            uri:
                              event?.media_attachments?.at?.(0)
                                ?.thumbnail_url ||
                              event?.media_attachments?.at?.(0)
                                ?.direct_upload_url ||
                              thumbnailPlaceholder,
                            method: 'GET',
                            headers: { 'Cache-Control': 'no-cache' },
                            body: '',
                          }}
                        />
                      </View>
                      <View style={{ ...styles.col, width: '133px' }}>
                        <Text
                          style={{
                            ...styles.eventName,
                          }}
                        >
                          {event.title}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.col,
                        width: '100px',
                      }}
                    >
                      <View style={styles.col}>
                        <Text style={styles.tableData}>{date}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        ...styles.col,
                        width: '80px',
                      }}
                    >
                      <Text style={styles.tableData}>{`${moment(
                        event.event_start_date,
                      ).format('h:mm A')} ${t('engagements.print.to')} ${moment(
                        event.event_end_date,
                      ).format('h:mm A')}`}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.col,
                        width: '136px',
                      }}
                    >
                      <Text style={styles.tableData}>
                        {event.remote
                          ? 'Remote'
                          : formatLocation(event?.location, true, true)}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...styles.hrLineLight,
                      backgroundColor: '#e2e2e2',
                    }}
                  />
                </View>
              )
            })}
          {/* <Text
            fixed
            style={styles.pageNum}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          /> */}
          <View
            style={{ ...styles.footer, ...styles.row, alignItems: 'center' }}
            fixed
          >
            <View
              style={{
                ...styles.row,
                width: '180px',
                fontStyle: 'bold',
                fontSize: '16px',
              }}
            >
              <Text>www.civicrush.com</Text>
            </View>
            <View style={{ ...styles.row, width: '370px', fontSize: '12px' }}>
              <Text>{t('engagements.print.footer')}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <div style={styles.wrapper}>
      <div style={styles.pdfDL}>
        <Link to={`/groups`}>
          <Button className="m-3">{t('engagements.print.backButton')}</Button>
        </Link>
        <PDFDownloadLink document={engagementsPDF} fileName={fileName}>
          <Button>{t('engagements.print.downloadButton')}</Button>
        </PDFDownloadLink>
      </div>
      <PDFViewer title={fileName} style={styles.pdfFrame} showToolbar={false}>
        {engagementsPDF}
      </PDFViewer>
    </div>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    height: '50rem',
    display: 'flex',
    padding: '1rem 10rem',
    flexDirection: 'column',
  },
  pdfDL: {
    margin: '1rem auto',
  },
  pdfFrame: {
    minHeight: '40rem',
    width: '100%',
  },
  pdfPage: {
    padding: '24px 24px',
    border: 'black',
    fontFamily: 'Roboto',
  },
  groupImg: {
    objectFit: 'cover',
    display: 'inline-block',
    width: '60px',
    height: '60px',
    borderRadius: '5px',
    border: '1px solid #e2e2e2',
  },
  pageNum: {
    position: 'absolute',
    top: '660px',
    right: '-15px',
    color: '#9b9b9b',
    fontSize: '10px',
  },
  footer: {
    position: 'absolute',
    top: '570px',
    backgroundColor: '#66308d',
    height: '35px',
    width: '100%',
    color: '#fff',
  },
  col: {
    display: 'inline-flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    justifyContent: 'space-evenly',
  },
  hrLine: {
    backgroundColor: '#66308d', // PDF doesn't like css variables
    width: '100%',
    alignSelf: 'center',
    height: '3px',
    borderRadius: '7px',
    margin: '8px 0',
  },
  hrLineLight: {
    backgroundColor: '#4a4a4a',
    width: '100%',
    alignSelf: 'center',
    height: '2px',
    borderRadius: '7px',
  },
  groupName: {
    fontSize: '24px',
    color: '#66308d',
    fontStyle: 'bold',
  },
  upcoming: {
    marginTop: '13px',
    color: '#4a4a4a',
  },
  eventName: {
    display: 'inline-flex',
    fontSize: '12px',
    fontStyle: 'bold',
  },
  tableData: {
    fontSize: '12px',
    color: '#4a4a4a',
  },
  columnLabels: {
    color: '#66308d',
    fontSize: '12px',
    fontStyle: 'light',
  },
})
