import { Trans } from 'react-i18next'
import { useMemo } from 'react'

import { useCurrentUser } from '../../../hooks/useCurrentUser'

interface PledgeHeaderProps {
  event?: any
  pledgeAmount?: string
}

export default function PledgeHeader(props: PledgeHeaderProps) {
  const { business } = useCurrentUser()

  const pledgeAmount = useMemo(() => {
    return props.pledgeAmount?.length ? parseFloat(props.pledgeAmount) : 0
  }, [props.pledgeAmount])

  return (
    <Trans
      i18nKey="dashboard.sponsorship.paymentModal.description"
      values={{
        businessName: business?.name,
        eventName: props.event?.title,
        amountTotal: pledgeAmount,
      }}
    />
  )
}
