import { Helmet } from 'react-helmet'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useMemo } from 'react'

import './show.scss'
import {
  sortCategoriesAlphabetically,
  sortObjectsAlphabetically,
} from '../../lib/sort'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Loading from '../../components/loading'
import useApi from '../../hooks/useApi'

export default function GroupsShowPage() {
  const { currentUser, locale } = useCurrentUser()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const { getGroup } = useApi()

  const [group, setGroup] = useState()
  const [unaccesible, setUnaccesible] = useState(false)

  const fetchData = async () => {
    try {
      const { data } = await getGroup({ id: params.slug })
      if (data) {
        setGroup(data)
      }
    } catch (e) {
      setUnaccesible(true)
    }
  }

  const stringOfGroupCategories = useMemo(
    () =>
      sortCategoriesAlphabetically(group?.categories ?? [], locale)
        .map(category =>
          currentUser?.language === 'es' ||
          localStorage.getItem('i18nextLng') === 'es'
            ? category.es_name
            : category.en_name,
        )
        .join(', '),
    [group, locale, currentUser],
  )

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (unaccesible) {
      navigate(
        currentUser?.id ? '/home' : `/login?redirect_to=/groups/${params.slug}`,
      )
    }
  }, [unaccesible])

  if (!group) {
    return <Loading show />
  }

  return (
    <div
      className="bg-image"
      style={{
        backgroundImage: `url(${group.image_url})`,
      }}
    >
      <Helmet>
        <title>CivicRush - {group.name}</title>
        <meta property="og:title" content={group.name} />
        <meta property="og:url" content={window.location} />
        <meta property="og:image" content={group.image_url} />
        <meta property="og:description" content={group.description} />
        <meta
          name="description"
          content={`CivicRush - ${group.name} - ${group.description} - ${stringOfGroupCategories} - Learn more and participate.`}
        />
        <meta
          name="keywords"
          content={`CivicRush, ${group.name}, ${stringOfGroupCategories}. 
          join civic rush, groups, network, volunteer, donate, give, 
          support opportunity, philanthropy, outreach, charity, giving, 
          community, need, fundraising, fundraising app, event app, 
          social network app, lifestyle app`}
        />
      </Helmet>
      <div className="container-fluid bg-content">
        <div className="container content">
          <div className="row">
            <div className="col-4">
              <p>
                <img
                  src={group.image_url}
                  className="img-fluid grpPic"
                  alt={group.title}
                />
              </p>
              <div className="categoryList">
                {!!group.categories &&
                  sortCategoriesAlphabetically(group.categories, locale).map(
                    category => (
                      <div key={category.id} className="category-pill">
                        <Icon name={category.icon} />{' '}
                        {currentUser?.language === 'es' ||
                        localStorage.getItem('i18nextLng') === 'es'
                          ? category.es_name
                          : category.en_name}
                      </div>
                    ),
                  )}
              </div>
            </div>
            <div className="col-8">
              <h2>{group.name}</h2>
              <p className="groupStats">
                {group.user_count} {t('group.members')}
                &nbsp;·&nbsp;
                {!!group.is_private && t('group.private')}
                {!group.is_private && t('group.public')}
                <br />
                <span className="groupOwner">
                  {t('group.owner')} {group.user?.name}
                </span>
              </p>
              <div className="details">
                <p>{group.description}</p>
                {/* <%= auto_link(simple_format(@group.description), :all, target: '_blank' ) %> */}
              </div>

              {(!!group.parent?.length || !!group.children?.length) && (
                <div className="assocGrpList">
                  <h6>{t('group.associated')}</h6>
                  <ul>
                    {group.parent && (
                      <li key={group.parent.id}>
                        <img
                          className="assocGrpPic"
                          src={group.parent.image_url}
                          alt={group.parent.name}
                        />
                        {group.parent.name}
                      </li>
                    )}
                    {sortObjectsAlphabetically(
                      group.children ?? [],
                      'name',
                    ).map(g => (
                      <li key={g.id}>
                        <img
                          className="assocGrpPic"
                          src={g.image_url}
                          alt={g.name}
                        />
                        {g.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {/* <Link to={`/groups/${params.slug}/print`}>
                <Button>View PDF</Button>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mrkSct">
            <div className="col">
              <p className="participation">{t('group.join')}</p>
              <p className="logo-icon">
                <img
                  src="/images/CR-Icon-192.png"
                  width="100px"
                  alt="CivicRush"
                />
              </p>
              <p className="mrkmssg">{t('group.transform')}</p>
              <h3>{t('group.download')}</h3>
              <div className="row">
                <div className="col badge-right">
                  <a
                    href="https://apps.apple.com/us/app/civicrush/id1382111745"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        locale === 'es'
                          ? '/images/badge_apple-store-espanol.png'
                          : '/images/badge_apple-store-english.png'
                      }
                      height="59px"
                      alt="Download on the App Store"
                    />
                  </a>
                </div>
                <div className="col badge-left">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.civicrush"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        locale === 'es'
                          ? '/images/badge_google-play-espanol.png'
                          : '/images/badge_google-play-english.png'
                      }
                      height="59px"
                      alt="Download on the Google Play Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
