import { useState } from 'react'

interface ValidationErrors {
  [key: string]: string | boolean | null
}

interface ValidationRules {
  [key: string]: (value: string) => string | boolean | null
}

interface UseValidationProps {
  validationRules: ValidationRules
}

const useValidation = ({ validationRules }: UseValidationProps) => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

  const validate = (values: { [key: string]: string }) => {
    const errors: ValidationErrors = {}
    let isValid = true

    Object.entries(validationRules).forEach(([fieldName, validationRule]) => {
      const value = values[fieldName]
      const error = validationRule(value)
      if (error) {
        errors[fieldName] = error
        isValid = false
      }
    })

    setValidationErrors(isValid ? {} : errors)
    return isValid
  }

  const setValidationError = (
    fieldName: string,
    error: string | boolean | null,
  ) => {
    setValidationErrors({ ...validationErrors, [fieldName]: error })
  }

  return { validate, validationErrors, setValidationError }
}

export default useValidation
