import { Alert } from 'react-bootstrap'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import useFacebook from '../../hooks/useFacebook'
import useValidation from '../../hooks/useValidation'
import Icon from '../icon'
import PasswordInput from '../password-input'
import TextInput from '../TextInput/TextInput'
import Tooltip from '../tooltip'
import Recaptcha from '../Recaptcha'

export default function NewAccountForm() {
  const {
    createAccount,
    createBusiness,
    signInWithEmail,
    signInWithFacebookResponse,
  } = useApi()
  const { locale } = useCurrentUser()
  const { login: loginWithFacebook } = useFacebook()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [isBusiness, setIsBusiness] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [token, setToken] = useState('')
  const [submitEnabled, setSubmitEnable] = useState(false)

  const { validate, validationErrors, setValidationError } = useValidation({
    validationRules: {
      email: (value = '') => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(value)) {
          return t('devise.sessions.addEmailError')
        }
        return null
      },
      name: (value = '') =>
        value.length > 0 ? null : t('devise.sessions.addNameError'),
      nickname: (value = '') =>
        value.length > 0 ? null : t('devise.sessions.addNicknameError'),
      password: (value = '') => {
        const minLength = 6
        const hasUppercase = /[A-Z]/.test(value)
        const hasNumber = /\d/.test(value)
        const hasSpecialChar = /[_\-!@%&()*']/.test(value)
        if (value.length < minLength) {
          return 'Password must be at least 6 characters'
        }
        if (!hasUppercase) {
          return 'Password must contain at least one uppercase letter'
        }
        if (!hasNumber) {
          return 'Password must contain at least one number'
        }
        if (!hasSpecialChar) {
          return "Password must contain at least one special character: _ - ! @ % & ( ) * '"
        }
        return null
      },
      businessName: (value = '') => {
        if (isBusiness) {
          return value.length > 0
            ? null
            : t('devise.sessions.addBusinessNameError')
        }
        return null
      },
      website: (value = '') => {
        if (isBusiness) {
          return value.length > 0 ? null : t('devise.sessions.addWebsiteError')
        }
        return null
      },
    },
  })

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      const formData = new FormData(event.currentTarget)

      const isValid = validate({
        email: formData.get('email') as string,
        name: formData.get('name') as string,
        nickname: formData.get('nickname') as string,
        password: formData.get('password') as string,
        businessName: formData.get('businessName') as string,
        website: formData.get('website') as string,
      })

      if (!isValid) {
        return
      }

      setIsLoading(true)
      const response = (await createAccount({
        // device_id: props.deviceId,
        // language: props.language,
        // marketing_opt_in: props.marketing_opt_in,
        // password_confirmation: props.password,
        email: formData.get('email'),
        language: locale,
        locale,
        name: formData.get('name'),
        nickname: formData.get('nickname'),
        password: formData.get('password'),
      })) as any
      if (response.errors) {
        setIsLoading(false)
        setValidationError(
          'signInResponse',
          response.errors?.full_messages || [],
        )
      } else {
        const signInResponse = (await signInWithEmail({
          email: formData.get('email'),
          locale,
          password: formData.get('password'),
        })) as any
        if (signInResponse.status === 200) {
          setTimeout(async () => {
            if (isBusiness) {
              await createBusiness({
                name: formData.get('businessName') as string,
                website_url: formData.get('website') as string,
              })
            }
            // To indicate a new account on the settings screen to popup email validation
            localStorage.setItem('new-account', 'true')
            window.location.href = '/settings'
            setIsLoading(false)
          }, 1000)
        } else {
          setIsLoading(false)
          setValidationError(
            'signInResponse',
            signInResponse.errors?.full_messages,
          )
        }
      }
    },
    [
      createAccount,
      isBusiness,
      locale,
      location,
      navigate,
      signInWithEmail,
      signInWithFacebookResponse,
      t,
    ],
  )

  const handleFacebookLogin = useCallback(async () => {
    const fbResponse = await loginWithFacebook(locale)
    if (fbResponse) {
      signInWithFacebookResponse(fbResponse)
        .then(() => {
          const from = location.state?.from?.pathname || '/home'
          setTimeout(() => {
            window.location = from
          }, 500)
        })
        .catch(error => {
          console.log('signInWithFacebookResponse error', error)
        })
    }
  }, [loginWithFacebook, signInWithFacebookResponse, navigate, location])

  const handleToken = (tokenValue: SetStateAction<string>) => {
    setToken(tokenValue)
  }

  useEffect(() => {
    setSubmitEnable(!!token?.length)
  }, [token])

  return (
    <>
      <h3>{t('devise.sessions.transform_your_community')}</h3>
      <h4>{t('devise.sessions.create_account')}</h4>
      <form name="form" onSubmit={handleSubmit} noValidate>
        {!!validationErrors?.signInResponse && (
          <Alert
            dismissible
            onClose={() => setValidationError('signInResponse', '')}
            show
            variant="danger"
          >
            {validationErrors?.signInResponse}
          </Alert>
        )}
        <div className="form-group">
          <label htmlFor="email">{t('devise.sessions.email')}</label>
          <TextInput
            autoComplete="email"
            isInvalid={!!validationErrors?.email}
            name="email"
            onChange={() => setValidationError('email', '')}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{t('devise.sessions.password')}</label>
          <PasswordInput
            isInvalid={!!validationErrors?.password}
            name="password"
            onChange={() => setValidationError('password', '')}
          />
          <p className="instructions">
            {t('devise.sessions.password_sublabel')}
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="name">{t('devise.sessions.fullname')}</label>
          <TextInput
            autoComplete="none"
            isInvalid={!!validationErrors?.name}
            name="name"
            onChange={() => setValidationError('name', '')}
          />
        </div>
        <div className="form-group">
          <label htmlFor="nickname">{t('devise.sessions.username')}</label>
          <TextInput
            autoComplete="none"
            isInvalid={!!validationErrors?.nickname}
            name="nickname"
            onChange={() => setValidationError('nickname', '')}
          />
        </div>

        <div className="form-group" onClick={() => setIsBusiness(!isBusiness)}>
          <input
            checked={isBusiness}
            type="checkbox"
            className="form-check-input chk-checkbox"
            id="businessAccountCheckbox"
            readOnly
          />{' '}
          <span className="checkbox-label">
            {t('devise.sessions.businessAccountCheckbox')}
          </span>
          <span className="info-circle">
            <Tooltip
              id="businessAccountCheckboxTooltip"
              innerContent={t('devise.sessions.businessAccountCheckboxTooltip')}
              outerContent={
                <Icon className={'info-circle-icon'} name={'info'} />
              }
            />
          </span>
        </div>

        {isBusiness ? (
          <>
            <div className="form-group">
              <label htmlFor="businessName">
                {t('devise.sessions.businessName')}
              </label>
              <TextInput
                isInvalid={!!validationErrors?.businessName}
                name="businessName"
                onChange={() => setValidationError('businessName', '')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="website">{t('devise.sessions.website')}</label>
              <TextInput
                isInvalid={!!validationErrors?.website}
                name="website"
                value={websiteUrl}
                onChange={value => {
                  setWebsiteUrl(value.replace(/(^\w+:|^)\/\//, ''))
                  setValidationError('website', '')
                }}
              />
              <small>{t('devise.sessions.website-example')}</small>
            </div>
          </>
        ) : null}

        {/* 6LcT1fEpAAAAACfolw0ihTH4TnrxznaaI3_iA2s6 */}
        <div className="centered spaced d-flex justify-content-center">
          <Recaptcha
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            callback={handleToken}
          />
        </div>
        <div className="centered spaced">
          <button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={isLoading || !submitEnabled}
          >
            {t('devise.sessions.create')}
          </button>
        </div>

        <div className="centered spaced">
          <p>
            {t('devise.sessions.existing_account')}{' '}
            <Link className="cr-link" to={`/login?locale=${locale}`}>
              {t('devise.sessions.login')}
            </Link>
          </p>
          <input
            type="button"
            value={t('devise.sessions.fb_join')}
            className="btn btn-primary btn-block spaced"
            onClick={handleFacebookLogin}
          />
        </div>

        <div className="centered spaced">
          <p>
            <span>{t('devise.sessions.terms.line_1')}</span>
            <a
              href={t('user_mailer.welcome_email.terms_of_service_url')}
              target="_blank"
            >
              {t('user_mailer.welcome_email.terms_of_service')}
            </a>
            <span>{t('devise.sessions.terms.line_2')}</span>
            <a
              href={t('user_mailer.welcome_email.privacy_policy_url')}
              target="_blank"
            >
              {t('user_mailer.welcome_email.privacy_policy')}
            </a>
            <span>.</span>
          </p>
        </div>
      </form>
    </>
  )
}
