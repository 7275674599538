import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import Icon from '../icon'

export default function NavbarItem({
  linkClass = '',
  to = '/',
  iconName = '',
  content = '',
}) {
  return (
    <Link className={linkClass} to={to}>
      <div className="d-flex">
        <div className="p-0 me-2 flex-shrink-1">
          <Icon name={iconName} />
        </div>
        <div className="p-0 w-100">{content}</div>
      </div>
    </Link>
  )
}
