import { Modal } from 'react-bootstrap'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Charity, CharityRegistration } from '../../types'
import Button from '../../components/button'
import CharitySearchRow from './search/row'
import Styles from './charities.module.scss'
import useApi from '../../hooks/useApi'
import ContactSupportModal from './search/ContactSupportModal'

export default function UserCharitiesList() {
  const { getUserCharityRegistrations, deleteCharityRegistration } = useApi()
  const { t } = useTranslation()

  const [charityRegistrations, setCharityRegistrations] = useState<
    CharityRegistration[]
  >([])
  const [registrationToCancel, setRegistrationToCancel] =
    useState<CharityRegistration>()

  const [registrationToSupport, setRegistrationToSupport] =
    useState<CharityRegistration>()

  const [supportModalShow, setSupportModalShow] = useState<boolean>(false)

  // Format charity api data to be compatible with the client
  const formatCharity = useCallback(
    (charity: Charity) => ({
      ...charity,
      addressLine1: charity.address_line_1,
      addressLine2: charity.address_line_2,
    }),
    [],
  )

  const fetchCharityRegistrations = useCallback(async () => {
    const { data } = await getUserCharityRegistrations()
    if (data && data.length > 0) {
      setCharityRegistrations(data.map(formatCharity))
    } else {
      setCharityRegistrations([])
    }
  }, [])

  const onClickCancelRegistration = useCallback(
    (registration: CharityRegistration) => {
      setRegistrationToCancel(registration)
    },
    [],
  )

  const onDelete = useCallback(async () => {
    await deleteCharityRegistration(registrationToCancel?.id)
    setRegistrationToCancel(undefined)
    fetchCharityRegistrations()
  }, [fetchCharityRegistrations, registrationToCancel])

  const onClickSupportRegistration = useCallback(
    (registration: CharityRegistration) => {
      setRegistrationToSupport(registration)
      setSupportModalShow(true)
    },
    [],
  )

  useEffect(() => {
    fetchCharityRegistrations()
  }, [fetchCharityRegistrations])

  return (
    <div className={Styles.userCharityRegistrationsList}>
      <div className={Styles.heading}>{t('charities.myCharities.title')}</div>
      {charityRegistrations.length === 0 ? (
        <small>{t('charities.myCharities.empty')}</small>
      ) : (
        <div className={Styles.searchList}>
          <div className={Styles.listContainer}>
            {charityRegistrations.map(registration => (
              <div className={Styles.list} key={registration.id}>
                <CharitySearchRow
                  charity={registration.charity}
                  onClickCancelRegistration={onClickCancelRegistration}
                  registration={registration}
                  showStatus
                  status={registration.status}
                  onClickSupportRegistration={onClickSupportRegistration}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal
        show={!!registrationToCancel}
        onHide={() => setRegistrationToCancel(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('charities.register.delete_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('charities.register.delete_message')}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setRegistrationToCancel(undefined)}
          >
            {t('charities.register.delete_cancel_button')}
          </Button>
          <Button onClick={onDelete}>
            {t('charities.register.delete_submit_button')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ContactSupportModal
        show={supportModalShow}
        onHide={() => setSupportModalShow(false)}
        registrationToSupport={registrationToSupport}
      />
    </div>
  )
}
