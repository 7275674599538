import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function getVideoThumb(file, seekTo = 1) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.setAttribute('src', URL.createObjectURL(file))
    video.load()
    video.addEventListener('error', err => {
      reject(err)
    })
    video.addEventListener('loadedmetadata', () => {
      setTimeout(() => {
        video.currentTime = seekTo
      }, 200)
      video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        ctx.canvas.toBlob(
          blob => {
            resolve(URL.createObjectURL(blob))
          },
          'image/jpeg',
          0.75,
        )
      })
    })
  })
}

export default function VideoThumbnail({ file, thumb = null }) {
  const [thumbnail, setThumbnail] = useState(thumb)

  useEffect(() => {
    if (file) {
      getVideoThumb(file).then(setThumbnail)
    }
  }, [file])

  if (!thumbnail) {
    return <div className="event-image event-image-video" />
  }
  return (
    <img
      alt="Video thumbnail"
      className="event-image event-image-video"
      src={thumbnail}
    />
  )
}

VideoThumbnail.defaultProps = {
  file: null,
}

VideoThumbnail.propTypes = {
  file: PropTypes.instanceOf(File),
}
