import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'

import { feeKind, feeValue, formatAmount } from '../../lib/numbers'
import civ from '../../constants/civ'

export default function AdminFeeReceipt({ business, pledge, fileName }) {
  const { t } = useTranslation()

  return (
    <Document title={fileName}>
      <Page size="LETTER" style={styles.pdfPage}>
        <View style={styles.block}>
          <Image style={styles.img} src="/images/civillos.png" />
        </View>
        <View style={styles.blockLarge}>
          <Text style={styles.title}>{t('pdf.adminFeeReceipt.title')}</Text>
        </View>
        <View style={styles.columnBlock}>
          <Text style={[styles.textline, styles.label]}>
            {t('pdf.adminFeeReceipt.transactionDate')}
          </Text>
          <Text style={[styles.textline, styles.text]}>
            {moment(pledge?.created_at).format(t('config.date_format'))}
          </Text>
        </View>
        <View style={styles.columnBlock}>
          <Text style={[styles.textline, styles.label]}>
            {t('pdf.adminFeeReceipt.purchaseBy')}
          </Text>
          <Text style={[styles.textline, styles.text]}>{business?.name}</Text>
        </View>
        <View style={styles.columnBlock}>
          <Text style={[styles.textline, styles.label]}>
            {t('pdf.adminFeeReceipt.paymentMethod')}
          </Text>
          <Text
            style={[styles.textline, styles.text]}
          >{`****${business.bank_account.bank_account_number_last4}`}</Text>
        </View>
        <View style={styles.columnBlock}>
          <Text style={[styles.textline, styles.label]}>
            {t('pdf.adminFeeReceipt.purchaseAmount')}
          </Text>
          <Text style={[styles.textline, styles.text]}>{`${formatAmount(
            pledge?.amount,
          )} CIV`}</Text>
        </View>
        <View style={styles.columnBlock}>
          <Text style={[styles.textline, styles.label]}>
            {t('pdf.adminFeeReceipt.feeValue')}
          </Text>
          <Text style={[styles.textline, styles.text]}>
            {feeKind(pledge.civillos_admin_fee, true)}
          </Text>
        </View>
        <View style={styles.columnBlock}>
          <Text style={[styles.textline, styles.label]}>
            {t('pdf.adminFeeReceipt.AdminFeePaid')}
          </Text>
          <Text style={[styles.textline, styles.text]}>
            {formatAmount(
              feeValue(pledge?.amount, pledge?.civillos_admin_fee),
              true,
            )}
          </Text>
        </View>
        {/* <View style={styles.block}>
          <Text style={styles.disclaimer}>
            {t('pdf.adminFeeReceipt.disclaimer')}
          </Text>
        </View> */}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pdfPage: {
    padding: '10px 50px',
    border: 'black',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginHorizontal: '50px',
  },
  img: {
    width: '160px',
    height: '33px',
    alignSelf: 'center',
    marginVertical: '50px',
  },
  blockLarge: {
    paddingBottom: '50px',
  },
  block: {
    paddingBottom: '20px',
  },
  columnBlock: {
    flexDirection: 'row',
    paddingBottom: '20px',
    marginLeft: '20px',
  },
  textline: {
    fontSize: 18,
    textAlign: 'justify',
  },
  label: {
    width: '250px',
  },
  text: {
    width: '350px',
  },
  disclaimer: {
    fontSize: 10,
    marginTop: '100px',
  },
})
