import { FormCheck, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'

import Button from '../button'
import Icon from '../icon'
import { onlyNumbers } from '../../lib/numbers'

interface bankAccount {
  bank_account_number: string
  bank_account_routing_number: string
  bank_account_holder_name: string
}

interface responseStatus {
  success?: boolean
  error?: any
  data?: any
}

interface FundingModalProps {
  bankAccountAttributes: bankAccount
  bankAccountId: number
  civAmount: number
  data: any
  locale: String
  onHide: () => void
  onSave: (data: any) => void
  origin: String
  setBankAccountAttributes: (data: any) => void
  show: boolean
  updateData: (id: number, data: any) => Promise<responseStatus>
}

export default function FundingModal(props: FundingModalProps) {
  const { t } = useTranslation()

  const [addFundingEnabled, setAddFundingEnabled] = useState(false)
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [savePaymentMethod, setSavePaymentMethod] = useState(true)

  const handleSave = useCallback(async () => {
    const bankAccountId = props.bankAccountId ? { id: props.bankAccountId } : {}
    const fundingId = props.bankAccountId
      ? { bank_account_id: props.bankAccountId }
      : {}

    const params = {
      [`${props.origin}`]: {
        ...fundingId,
        bank_account_attributes: {
          ...props.bankAccountAttributes,
          ...bankAccountId,
        },
      },
    }
    const response = await props.updateData(props.data.id, params)
    if (!response.success && response.error) {
      // eslint-disable-next-line no-alert
      alert(response.error?.message)
    } else {
      // update parent object because we avoid a reload
      props.onSave({
        ...props.data,
        ...params[`${props.origin}`],
        bank_account_id: response.data.bank_account_id,
      })
    }
    props.onHide()
  }, [props.bankAccountAttributes])

  useEffect(() => {
    setAddFundingEnabled(
      props.bankAccountAttributes?.bank_account_number?.length >= 12 &&
        !!props.bankAccountAttributes?.bank_account_routing_number &&
        !!props.bankAccountAttributes?.bank_account_holder_name &&
        hasDataChanged,
    )
  }, [
    props.bankAccountAttributes?.bank_account_number,
    props.bankAccountAttributes?.bank_account_routing_number,
    props.bankAccountAttributes?.bank_account_holder_name,
  ])

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto p-0">
          {t('civ_wallet.funding_modal.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-center">{t('civ_wallet.funding_modal.heading')}</h5>
        <p className="text-center funding-icon">
          <Icon name="landmark" />
        </p>
        <p className="mx-auto w-75">
          {t('civ_wallet.funding_modal.description')}
        </p>
        <p className="mx-auto w-75">
          <img
            alt="Bank account info"
            className="img-fluid"
            src={`images/${props.locale}_bank_account_info.gif`}
          />
        </p>
        <div className="row form-row justify-content-md-center">
          <div className="form-group col-md-9">
            <label htmlFor="bank_account_holder_name">
              {t('civ_wallet.funding_modal.account.name')}
            </label>
            <input
              className="form-control"
              id="bank_account_holder_name"
              name="bank_account_holder_name"
              type="text"
              value={props.bankAccountAttributes?.bank_account_holder_name}
              onChange={e => {
                props.setBankAccountAttributes({
                  ...props.bankAccountAttributes,
                  bank_account_holder_name: e.target.value,
                })
                setHasDataChanged(true)
              }}
            />
          </div>
        </div>
        <div className="row form-row justify-content-md-center">
          <div className="form-group col-md-9">
            <label htmlFor="bank_account_routing_number">
              {t('civ_wallet.funding_modal.account.routing_number')}
            </label>
            <input
              className="form-control"
              id="bank_account_routing_number"
              name="bank_account_routing_number"
              type="text"
              maxLength={9}
              value={props.bankAccountAttributes?.bank_account_routing_number}
              onChange={e => {
                props.setBankAccountAttributes({
                  ...props.bankAccountAttributes,
                  bank_account_routing_number: onlyNumbers(
                    e.target.value,
                  ).toString(),
                })
                setHasDataChanged(true)
              }}
            />
          </div>
        </div>
        <div className="row form-row justify-content-md-center">
          <div className="form-group col-md-9">
            <label htmlFor="bank_account_number">
              {t('civ_wallet.funding_modal.account.number')}
            </label>
            <input
              className="form-control"
              id="bank_account_number"
              name="bank_account_number"
              type="text"
              maxLength={17}
              minLength={12}
              value={props.bankAccountAttributes?.bank_account_number}
              onChange={e => {
                props.setBankAccountAttributes({
                  ...props.bankAccountAttributes,
                  bank_account_number: onlyNumbers(e.target.value).toString(),
                })
                setHasDataChanged(true)
              }}
            />
          </div>
        </div>
        {!props.bankAccountAttributes && !!props.civAmount ? (
          <div className="row form-row justify-content-md-center">
            <div className="form-group col-md-9">
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  checked={savePaymentMethod}
                  className={'chk-checkbox'}
                  onChange={e => setSavePaymentMethod(e.target.checked)}
                />
                <FormCheck.Label>
                  {t('civ_wallet.funding_modal.checkPayment')}
                  <Icon
                    name="info-circle"
                    className="info-circle info-circle-icon"
                  />
                </FormCheck.Label>
              </FormCheck>
            </div>
          </div>
        ) : null}
        <p className="mx-auto w-75">{t('civ_wallet.funding_modal.terms')}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-md-center">
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!addFundingEnabled}
        >
          {!!props.civAmount
            ? t('civ_wallet.funding_modal.purchase')
            : t('civ_wallet.funding_modal.button')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
