import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

import styles from '../sponsorship.module.scss'
import PledgeHeader from '../../../../components/SponsorPledgeModal/PledgeHeader'
import TextInput from '../../../../components/TextInput/TextInput'
import Button from '../../../../components/button'
import { feeKind, formatAmount } from '../../../../lib/numbers'

interface SponsorPledgeFormProps {
  business?: any
  event?: any
  onNext: () => void
  pledgeAmount?: string
  setPledgeAmount: (amount: string) => void
  adminFee?: object
  serviceFee?: object
  currentAdminFee?: number
  currentServiceFee?: number
}

export default function SponsorPledgeForm(props: SponsorPledgeFormProps) {
  const { t } = useTranslation()

  const pledgeAmountNumber = useMemo(() => {
    let amount = props.pledgeAmount?.length ? props.pledgeAmount : '0'
    return parseFloat(amount)
  }, [props.pledgeAmount])

  const handleOnClickNext = useCallback(async () => {
    props.onNext()
  }, [props])

  return (
    <>
      <div className="mb-3">
        <h2>
          <b>{t('sponsorship.pledgeModal.title')}</b>
        </h2>
      </div>
      <div className="mb-3">
        <div className={styles.section}>
          <PledgeHeader event={props.event} pledgeAmount={props.pledgeAmount} />
        </div>
      </div>
      <div className="text-center mt-2 mb-3">
        <strong>{t('sponsorship.pledgeModal.volunteerPayForward')}</strong>
      </div>
      <div className="mb-3">
        <div className={styles.calculator}>
          <Table>
            <tbody>
              <tr className={styles.lineThick}>
                <td className={styles.tableRowKey}>
                  {t('sponsorship.pledgeModal.pledgeAmountTitle')}
                </td>
                <td className={styles.tableRowValue}>
                  <TextInput
                    min={0}
                    onChange={props.setPledgeAmount}
                    placeholder={t(
                      'sponsorship.pledgeModal.pledgeAmountPlaceholder',
                    )}
                    type="number"
                    value={props.pledgeAmount}
                    inputStyle={{
                      maxWidth: '100px',
                      height: '30px',
                      marginLeft: '-15px',
                    }}
                  />
                </td>
              </tr>
              <tr className={styles.line}>
                <td className={styles.tableRowKey}>
                  {t('civ_wallet.civ_modal.transactionFee', {
                    fee: feeKind(props.adminFee, true),
                  })}
                </td>
                <td className={styles.tableRowValue}>
                  {formatAmount(props.currentAdminFee ?? 0, true)}
                </td>
              </tr>
              <tr className={styles.lineThick}>
                <td className={styles.tableRowKey}>
                  {t('civ_wallet.civ_modal.serviceFee', {
                    fee: feeKind(props.serviceFee, true),
                  })}
                </td>
                <td className={styles.tableRowValue}>
                  {formatAmount(props.currentServiceFee ?? 0, true)}
                </td>
              </tr>
              <tr>
                <td className={styles.tableRowKey}>Total</td>
                <td className={styles.tableRowValue}>
                  {formatAmount(
                    parseInt(props.pledgeAmount || '0') +
                      (props.currentAdminFee ?? 0) +
                      (props.currentServiceFee ?? 0),
                    true,
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.terms}>
          {t('sponsorship.pledgeModal.pledgeFinePrint')}
        </div>
      </div>

      <div className={styles.section}>
        <div className="d-flex align-items-end flex-column">
          <Button
            className="text-right mb-4"
            disabled={pledgeAmountNumber <= 0}
            onClick={handleOnClickNext}
          >
            {t('sponsorship.pledgeModal.nextButton')}
          </Button>
        </div>
      </div>
    </>
  )
}
