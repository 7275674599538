import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ModalVolunteerCount({
  show,
  onHide,
  onClick,
  shiftVolunteersNeeded,
  event,
}) {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('event.invalid_volunteer_count_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('event.invalid_volunteer_count_message', {
          shiftVolunteersNeeded,
          eventVolunteersNeeded: event.volunteers_needed,
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('event.invalid_volunteer_count_cancel')}
        </Button>
        <Button onClick={onClick}>
          {t('event.invalid_volunteer_count_update')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
