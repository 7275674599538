import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Icon from './icon'
import Button from './button'

export default function EngagementCard({ engagement }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Col md="4">
      <Card>
        <Card.Img
          variant="top"
          src={
            engagement?.media_attachments[0]?.thumbnail_url ??
            engagement?.media_attachments[0]?.direct_upload_url
          }
        />
        <Card.Body className="card_subtitle">
          {moment(engagement.event_end_date).isBefore(moment()) && (
            <div>
              <span className="badge badge-success">
                {t('event_card.past')}
              </span>
            </div>
          )}
          <Card.Title className="card_title">{engagement.title}</Card.Title>
          <Icon name="calendar-days" />{' '}
          {moment(
            moment(engagement.event_end_date).isBefore(moment())
              ? engagement.event_end_date
              : engagement.event_start_date,
          ).format('LL')}
          <br />
          <Icon name="clock" />{' '}
          {`${moment(engagement.event_start_date).format('h:mm A')} to ${moment(
            engagement.event_end_date,
          ).format('h:mm A')} EDT`}
          <br />
          <Icon name="location-dot" />{' '}
          {/* tech to avoid extra commas and undefined below v */}
          {engagement?.remote ? (
            t('event.remote')
          ) : (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${
                engagement?.location?.name ?? ''
              } ${engagement?.location?.city ?? ''} ${
                engagement?.location?.state ?? ''
              }`}
              rel="noreferrer"
              target="_blank"
            >
              {((engagement?.location?.name ||
                engagement?.location?.city ||
                engagement?.location?.state) &&
                `${engagement?.location?.name}${
                  engagement?.location?.name &&
                  engagement?.location?.city &&
                  ', '
                }${engagement?.location?.city}${
                  engagement?.location?.city &&
                  engagement?.location?.state &&
                  ', '
                }${engagement?.location?.state}`) ||
                'error'}
            </a>
          )}
          <br />
          <Icon name="hand" />{' '}
          {t('engagements.vol_count', {
            part: engagement.volunteers_count,
            whole: engagement.volunteers_needed,
          })}
          <Row className="mt-3">
            <Col xs="6">
              <Link to={`../events/${engagement.slug}`}>
                <Button className="w-100">{t('event_card.view_post')}</Button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  )
}

EngagementCard.propTypes = {
  // eslint-disable-next-line
  engagement: PropTypes.object.isRequired,
}
