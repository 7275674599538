import { Row, Col, Carousel, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { restoreState, storeState } from '../lib/localstorage'
import { groupIntoBlocks } from '../lib/sort'
import Badge from '../components/badge'
import Loading from '../components/loading'
import CarouselMulti from '../components/Carousel-multi'
import TodayFeed from '../components/Feed/today'
import UpcomingFeed from '../components/Feed/upcoming'
import NeedEventFeed from '../components/Feed/needEvent'
import GroupEventFeed from '../components/Feed/groupEvent'
import GroupFeed from '../components/Feed/group'
import ConnectionFeed from '../components/Feed/connectionEvent'
import SponsorshipFeed from '../components/Feed/sponsorshipEvent'
import useApi from '../hooks/useApi'
import { useCurrentUser } from '../hooks/useCurrentUser'
import useGroups from '../hooks/useGroups'
import useScreenSize from '../hooks/useScreenSize'
import carousel from '../constants/carousel'

export default function FeedPage() {
  const { groups, fetchGroups } = useGroups()
  const { currentUser, hasBetaFeature } = useCurrentUser()
  const {
    getTodaysEvents,
    getMonthlyEvents,
    getCurrentUserGroupEvents,
    getCurrentUserFollowingEvents,
    getEventsWithParams,
    getSponsorshipOpportunities,
  } = useApi()
  const { t } = useTranslation()
  const { has_business, business_id } = currentUser || {}

  const [todayEvents, setTodayEvents] = useState(() =>
    restoreState('todayEvents', []),
  )
  const [upcomingEvents, setUpcomingEvents] = useState(() =>
    restoreState('upcomingEvents', []),
  )
  const [needs, setNeeds] = useState(() => restoreState('needs', []))
  const [groupEvents, setGroupEvents] = useState(() =>
    restoreState('groupEvents', []),
  )
  const [connectionEvents, setConnectionEvents] = useState(() =>
    restoreState('connectionEvents', []),
  )
  const [sponsorshipOpportunities, setSponsorshipOpportunities] = useState(() =>
    restoreState('sponsorshipOpportunities', []),
  )
  const [isLoading, setIsLoading] = useState(true)
  const [upcomingBlocks, setUpcomingBlocks] = useState([])
  const [needsBlocks, setNeedsBlocks] = useState([])
  const [groupsBlocks, setGroupsBlocks] = useState([])

  const screenSize = useScreenSize()

  const fetchData = async () => {
    const promises = []
    // Today
    const todayPromise = new Promise(resolve => {
      resolve(getTodaysEvents())
    })
      .then(response => {
        setTodayEvents(
          response.data.sort(
            (a, b) =>
              new Date(a.event_start_date).getTime() -
              new Date(b.event_start_date).getTime(),
          ),
        )
      })
      .catch(error => {
        setTodayEvents([])
      })
    promises.push(todayPromise)
    // Upcoming
    const upcomingPromise = new Promise(resolve => {
      resolve(getMonthlyEvents())
    })
      .then(response => {
        setUpcomingEvents(
          response.data.sort(
            (a, b) =>
              new Date(a.event_start_date).getTime() -
              new Date(b.event_start_date).getTime(),
          ),
        )
      })
      .catch(error => {
        setUpcomingEvents([])
      })
    promises.push(upcomingPromise)
    // Needs
    const needsPromise = new Promise(resolve => {
      const params = {
        event_type: 'need',
        page: 1,
        limit: 30,
        // draft: false,
        sort_by: 'updated_at',
        order: 'DESC',
      }
      resolve(getEventsWithParams(params))
    })
      .then(response => {
        setNeeds(response.data)
      })
      .catch(error => {
        setNeeds([])
      })
    promises.push(needsPromise)
    // Group Events
    const groupPromise = new Promise(resolve => {
      resolve(getCurrentUserGroupEvents())
    })
      .then(response => {
        setGroupEvents(
          response.data.sort(
            (a, b) =>
              new Date(a.event_start_date).getTime() -
              new Date(b.event_start_date).getTime(),
          ),
        )
      })
      .catch(error => {
        setGroupEvents([])
      })
    promises.push(groupPromise)
    // Connection Events
    const connectionPromise = new Promise(resolve => {
      resolve(getCurrentUserFollowingEvents())
    })
      .then(response => {
        setConnectionEvents(
          response.data.sort(
            (a, b) =>
              new Date(a.event_start_date).getTime() -
              new Date(b.event_start_date).getTime(),
          ),
        )
      })
      .catch(error => {
        setConnectionEvents([])
      })
    promises.push(connectionPromise)
    // Sponsor opportunities, only for business!!
    if (has_business && !!business_id && hasBetaFeature('pf_business')) {
      const sponsorshipPromise = new Promise(resolve => {
        resolve(getSponsorshipOpportunities())
      })
        .then(response => {
          setSponsorshipOpportunities(
            // filter and slice temporary until EP ready
            response.data.sort(
              (a, b) =>
                new Date(a.event_start_date).getTime() -
                new Date(b.event_start_date).getTime(),
            ),
          )
        })
        .catch(error => {
          setSponsorshipOpportunities([])
        })
      promises.push(sponsorshipPromise)
    }
    fetchGroups()
    Promise.all(promises).then(values => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    storeState('todayEvents', todayEvents)
  }, [todayEvents])

  useEffect(() => {
    storeState('upcomingEvents', upcomingEvents)
    setUpcomingBlocks(
      groupIntoBlocks(upcomingEvents, screenSize.isMobile ? 1 : 4),
    )
  }, [upcomingEvents, screenSize.isMobile])

  useEffect(() => {
    storeState('needs', needs)
    setNeedsBlocks(groupIntoBlocks(needs, screenSize.lowResolution ? 2 : 4))
  }, [needs, screenSize.lowResolution])

  useEffect(() => {
    storeState('groupEvents', groupEvents)
    setGroupsBlocks(groupIntoBlocks(groupEvents, screenSize.isMobile ? 1 : 3))
  }, [groupEvents])

  useEffect(() => {
    storeState('connectionEvents', connectionEvents)
  }, [connectionEvents])

  useEffect(() => {
    storeState('sponsorshipOpportunities', sponsorshipOpportunities)
  }, [sponsorshipOpportunities])

  if (isLoading) {
    return <Loading show={isLoading} />
  }

  return (
    <Container className="container-fluid mw-100 mb-4 mt-2 feed">
      <Row className="rowSpacer">
        <Col className="col-1 d-none d-sm-block">&nbsp;</Col>
        <Col className="col-12 col-md-4">
          <Row>
            <Col>
              <h1 className="ms-1">{t('feed.today.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="pb-3 position-relative">
              {!!todayEvents.length ? (
                <CarouselMulti
                  items={todayEvents.map(event => (
                    <TodayFeed key={`today-${event.id}`} event={event} />
                  ))}
                  responsive={carousel.items1}
                  arrows={false}
                  slidesToSlide={1}
                  showDots={todayEvents.length > 1}
                  renderDotsOutside
                  autoPlay={todayEvents.length > 1}
                  autoPlaySpeed={5000}
                  infinite
                  pauseOnHover
                />
              ) : (
                <h6>{t('feed.today.placeholder')}</h6>
              )}
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-md-6">
          <Row>
            <Col>
              <h1 className="ms-2">{t('feed.upcoming.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="pb-3 position-relative">
              {!!upcomingEvents.length ? (
                <CarouselMulti
                  items={upcomingBlocks.map((block, idx) => (
                    <Row className="g-3" key={`upcoming-row-${idx}`}>
                      {block.map(event => (
                        <Col
                          className="col-12 col-sm-6"
                          key={`upcoming-col-${event.id}`}
                        >
                          <UpcomingFeed
                            key={`upcoming-${event.id}`}
                            event={event}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}
                  responsive={carousel.items1}
                  arrows={false}
                  slidesToSlide={1}
                  showDots={upcomingBlocks.length > 1}
                  renderDotsOutside
                  autoPlay={upcomingBlocks.length > 1}
                  autoPlaySpeed={30000}
                  infinite
                  pauseOnHover
                />
              ) : (
                <h6>{t('feed.upcoming.placeholder')}</h6>
              )}
            </Col>
          </Row>
        </Col>
        <Col className="col-1 d-none d-md-block">&nbsp;</Col>
      </Row>

      <Row>
        <Col className="col-1 d-none d-sm-block">&nbsp;</Col>
        <Col className="col-12 col-md-10">
          <Row>
            <Col>
              <h1 className="ms-2">{t('feed.needs.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="pb-3 position-relative">
              {!!needs.length ? (
                <CarouselMulti
                  items={needsBlocks.map((block, idx) => (
                    <Row className="g-3" key={`needs-row-${idx}`}>
                      {block.map(needEvent => (
                        <Col
                          className="col-6 col-md-3"
                          key={`needs-col-${needEvent.id}`}
                        >
                          <NeedEventFeed
                            key={`needs-${needEvent.id}`}
                            event={needEvent}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}
                  responsive={carousel.items1}
                  arrows={false}
                  slidesToSlide={1}
                  showDots={needsBlocks.length > 1}
                  renderDotsOutside
                  autoPlay={needsBlocks.length > 1}
                  autoPlaySpeed={30000}
                  infinite
                  pauseOnHover
                />
              ) : (
                <h6>{t('feed.needs.placeholder')}</h6>
              )}
            </Col>
          </Row>
        </Col>
        <Col className="col-1 d-none d-md-block">&nbsp;</Col>
      </Row>

      <Row>
        <Col className="col-1 d-none d-md-block">&nbsp;</Col>
        <Col className="col-12 col-md-4 groups">
          <Row>
            <Col>
              <h1 className="ms-1">{t('feed.my_groups.title')}</h1>
            </Col>
          </Row>
          {!groups.length ? (
            <Row>
              <Col>
                <h6 className="ms-2">{t('feed.my_groups.placeholder')}</h6>
              </Col>
            </Row>
          ) : (
            <>
              {!!groupEvents.length && (
                <Row>
                  <Col className="pb-3 position-relative">
                    <CarouselMulti
                      items={groupsBlocks.map((block, idx) =>
                        block.map(event => (
                          <Row
                            className="mb-3"
                            key={`row-group-event-${event.id}`}
                          >
                            <Col>
                              <GroupEventFeed event={event} />
                            </Col>
                          </Row>
                        )),
                      )}
                      responsive={carousel.items1}
                      arrows={false}
                      slidesToSlide={1}
                      showDots={groupsBlocks.length > 1}
                      renderDotsOutside
                      autoPlay={groupsBlocks.length > 1}
                      autoPlaySpeed={30000}
                      infinite
                      pauseOnHover
                    />
                  </Col>
                </Row>
              )}
              <Row className="list">
                <Col>
                  <h4>{t('feed.my_groups.groups')}</h4>
                </Col>
              </Row>
              <Row key={`row-group-feed`} className="list gy-2 mb-3">
                {!!groups.length &&
                  groups.map(group => (
                    <Col
                      className="col-xxl-3 col-lg-4 col-md-6 col-sm-4 col-6"
                      key={`group-col-${group.id}`}
                    >
                      <GroupFeed key={`group-${group.id}`} group={group} />
                    </Col>
                  ))}
              </Row>
            </>
          )}
        </Col>
        <Col className="col-12 col-md-6">
          <Row className="d-flex flex-column">
            <Col>
              <Row>
                <Col>
                  <h1 className="ms-2">{t('feed.my_connections.title')}</h1>
                </Col>
              </Row>
              <Row>
                <Col className="pb-3 position-relative">
                  {!!connectionEvents.length ? (
                    <CarouselMulti
                      items={connectionEvents.map(event => (
                        <ConnectionFeed
                          key={`connection-${event.id}`}
                          event={event}
                        />
                      ))}
                      responsive={carousel.items3}
                      arrows={false}
                      slidesToSlide={3}
                      showDots={connectionEvents.length > 1}
                      renderDotsOutside
                      autoPlay={connectionEvents.length > 1}
                      autoPlaySpeed={15000}
                      infinite
                      pauseOnHover
                    />
                  ) : (
                    <h6 className="ms-2">
                      {t('feed.my_connections.placeholder')}
                    </h6>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              {has_business &&
              !!business_id &&
              hasBetaFeature('pf_business') ? (
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h1 className="ms-1 badge-container">
                          {t('feed.sponsorship.title')}
                          <Badge />
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      {!!sponsorshipOpportunities.length ? (
                        sponsorshipOpportunities.map(event => (
                          <Col
                            className="col-xl-4 col-md-6 col-12 pb-3"
                            key={`sponsor-col-${event.id}`}
                          >
                            <SponsorshipFeed
                              key={`sponsor-${event.id}`}
                              event={event}
                            />
                          </Col>
                        ))
                      ) : (
                        <Col>
                          <h6>{t('feed.sponsorship.placeholder')}</h6>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col className="col-1 d-none d-sm-block">&nbsp;</Col>
      </Row>
    </Container>
  )
}
