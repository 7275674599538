interface ButtonProps {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  id?: string
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  variant?: string
}

export default function Button(props: ButtonProps) {
  const {
    children = 'Submit',
    className,
    disabled,
    id,
    onClick,
    type = 'button',
    variant = 'primary',
  } = props

  return (
    <button
      className={`btn btn-${variant} ${className}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  )
}
