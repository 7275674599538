import moment from 'moment'

import Acknowledgement from '../pages/pdf/acknowledgement'
import AdminFeeReceipt from '../pages/pdf/adminFeeReceipt'
import PledgeReceipt from '../pages/pdf/pledgeReceipt'
import EventFlyer from '../pages/pdf/eventFlyer'

import { QRCodePNG, mobileDeepLink } from './event'
import { sortObjectsByNumber } from './sort'

export const isContentType = (file, type) =>
  !!(file?.fileObject?.type ?? file?.upload_content_type)?.startsWith(type)

export const isImage = file => isContentType(file, 'image/')

export const isPDF = file => isContentType(file, 'application/pdf')

export const isVideo = file =>
  isContentType(file, 'video/') || isContentType(file, 'application/mp4')

export const downloadCSV = (content, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([content], { type: 'text/csv;charset=utf-8;' }),
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const buildPDF = async ({
  origin,
  business_id,
  params,
  dateFormat,
  locale,
  getBusiness,
  getCivPurchase,
  getPledge,
  getBankAccount,
  getEvent,
  getEventAcceptedPledges,
}) => {
  switch (origin) {
    case 'acknowledgement':
      return getBusiness(business_id).then(response => {
        return getCivPurchase(params.id).then(pledge => {
          const { amount, updated_at } = pledge.data
          const fileName = `Civillos_Charitable_${moment(updated_at).format(
            dateFormat,
          )}.pdf`
          const documentPDF = (
            <Acknowledgement
              business={response.data}
              pledge={{
                amount: amount ?? 0,
                transaction_date: updated_at,
              }}
              fileName={fileName}
            />
          )
          return { fileName, documentPDF, returnPath: '/dashboard' }
        })
      })
      break
    case 'admin-fee-receipt':
      return getBusiness(business_id).then(response => {
        if (response.data?.bank_account_id) {
          return getBankAccount(response.data.bank_account_id).then(result => {
            const businessData = {
              ...response.data,
              bank_account: { ...result.data },
            }
            return getCivPurchase(params.id).then(pledge => {
              const fileName = `Civillos_Admin_Fee_${moment(
                pledge.data.updated_at,
              ).format(dateFormat)}.pdf`
              const documentPDF = (
                <AdminFeeReceipt
                  business={businessData}
                  pledge={pledge.data}
                  fileName={fileName}
                />
              )
              return { fileName, documentPDF, returnPath: '/dashboard' }
            })
          })
        }
      })
      break
    case 'pledge-receipt':
      return getBusiness(business_id).then(response => {
        // Has a bank account already
        if (response.data?.bank_account_id) {
          return getBankAccount(response.data.bank_account_id).then(result => {
            const businessData = {
              ...response.data,
              bank_account: { ...result.data },
            }
            return getPledge(params.id).then(pledge => {
              const { created_at } = pledge.data
              const fileName = `Meta_Pledge_Service_Fee_Receipt_${moment(
                created_at,
              ).format(dateFormat)}.pdf`
              const documentPDF = (
                <PledgeReceipt
                  business={businessData}
                  pledge={pledge.data}
                  fileName={fileName}
                />
              )
              return { fileName, documentPDF, returnPath: '/dashboard' }
            })
          })
        }
      })
      break
    case 'eventFlyer':
      return getEvent({ id: params.slug }).then(response => {
        return getEventAcceptedPledges(response.data.id).then(result => {
          return QRCodePNG({
            title: response.data.title,
            value: mobileDeepLink(response.data),
            size: 150,
          }).then(qrResponse => {
            const acceptedPledges = sortObjectsByNumber(result.data, 'amount')
            const fileName = `${params.slug}.pdf`
            const documentPDF = (
              <EventFlyer
                event={response.data}
                acceptedPledges={acceptedPledges}
                fileName={fileName}
                qr={qrResponse}
                locale={params.lang}
              />
            )
            return { fileName, documentPDF, returnPath: `/${params.slug}` }
          })
        })
      })
      break
  }
}
