import { Alert, Card, Col, Modal, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'

import Button from '../button'
import Icon from '../icon'
import { feeKind, feeValue } from '../../lib/numbers'

interface BalanceModalProps {
  onHide: () => void
  onSave: () => void
  show: boolean
  civSaveStatus: any
  civAmount: number
  setCivAmount: (amount?: number) => void
  currentFee: number
  setCurrentFee: (amount?: number) => void
  adminFee: any
  bankAccountNumberLast4: string
}

export default function BalanceModal(props: BalanceModalProps) {
  const { t } = useTranslation()

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto p-0">
          {props.civSaveStatus === null ||
          (!props.civSaveStatus?.success && props.civSaveStatus?.error)
            ? t('civ_wallet.civ_modal.title')
            : t('civ_wallet.civ_modal.success.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.civSaveStatus === null ||
        (!props.civSaveStatus?.success && props.civSaveStatus?.error) ? (
          <>
            {!props.civSaveStatus?.success && props.civSaveStatus?.error ? (
              <p className="text-center mx-auto w-75">
                <Alert key="danger" variant="danger">
                  {t('civ_wallet.civ_modal.failure')}
                </Alert>
              </p>
            ) : null}
            <h5 className="text-center">{t('civ_wallet.civ_modal.heading')}</h5>
            <p className="text-center funding-icon">
              <Icon name="wallet" />
            </p>
            <p className="mx-auto w-75">
              {t('civ_wallet.civ_modal.description')}
            </p>
            <p className="text-center fw-semibold fs-6">
              {t('civ_wallet.civ_modal.amountLabel')}
            </p>
            <div className="row form-row justify-content-md-center">
              <div className="form-group col-md-9">
                <input
                  className="form-control form-control-lg text-center"
                  id="civ_amount"
                  name="civ_amount"
                  type="text"
                  value={props.civAmount}
                  placeholder="0"
                  onChange={e => {
                    props.setCivAmount(
                      e.target.value ? parseInt(e.target.value) : undefined,
                    )
                    props.setCurrentFee(
                      feeValue(parseInt(e.target.value), props.adminFee),
                    )
                  }}
                />
              </div>
            </div>
            <Row className="my-1 mx-auto w-75">
              <Col className="text-start">
                <Trans
                  i18nKey="civ_wallet.civ_modal.transactionFee"
                  values={{
                    fee: feeKind(props.adminFee, true),
                  }}
                />
              </Col>
              <Col className="text-center">
                ${Number(!!props.currentFee ? props.currentFee : 0).toFixed(2)}
              </Col>
            </Row>
            <Row className="mb-2 mx-auto w-75 fw-bold">
              <Col className="text-start">
                {t('civ_wallet.civ_modal.totalCharge')}
              </Col>
              <Col className="text-center">
                $
                {Number(
                  (!!props.civAmount ? props.civAmount : 0) +
                    (!!props.currentFee ? props.currentFee : 0),
                ).toFixed(2)}
              </Col>
            </Row>
            <Card className="shadow-none mx-auto w-75">
              <Card.Body>
                <Card.Text className="fs-6">
                  {t('civ_wallet.civ_modal.payment.title')}
                </Card.Text>
                <Card.Text>
                  <Trans
                    i18nKey="civ_wallet.civ_modal.payment.detail"
                    values={{
                      numbers: props.bankAccountNumberLast4,
                    }}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <p className="text-center success-icon">
              <Icon name="check-circle" />
            </p>
            <p className="text-center">
              {t('civ_wallet.civ_modal.success.description')}
            </p>
            <p className="text-center fw-bold fs-6">
              <Trans
                i18nKey="civ_wallet.civ_modal.success.amount"
                values={{
                  amount: props.civAmount ?? 0,
                }}
              />
            </p>
            <p className="text-center">
              {moment().format(t('config.date_format'))}
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-md-center">
        {props.civSaveStatus === null ||
        (!props.civSaveStatus?.success && props.civSaveStatus?.error) ? (
          <>
            <Row>
              <Col>
                <p className="mx-auto w-75">
                  {t('civ_wallet.civ_modal.agreement')}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={props.onSave}
                  disabled={!props.civAmount}
                >
                  {t('civ_wallet.civ_modal.button')}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Button variant="primary" onClick={props.onHide}>
            {t('civ_wallet.civ_modal.success.button')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
