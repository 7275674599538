import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import Styles from './adminPicker.module.scss'
import useApi from '../../hooks/useApi'
import UserPill from '../user-pill'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function AdminPicker({
  admins,
  groups,
  isOpen,
  onAddAdmin,
  onClose,
  onRemoveAdmin,
  availableSelections,
  showYourself,
  searchTitle,
}) {
  const { getPossibleAdmins } = useApi()
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const [possibleAdmins, setPossibleAdmins] = useState([])
  const [moreAdminsToLoad, setMoreAdminsToLoad] = useState(true)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)

  const searchUsers = useCallback(
    async e => {
      const q = e.target.value
      setQuery(q)
      // Even if there is no search term,
      // we dispatch the request to get all possible results
      const { data: users } = await getPossibleAdmins({
        groups,
        query: q,
        page: 1,
      })
      setPossibleAdmins(users || [])
      setMoreAdminsToLoad(users?.length === 30)
      setPage(1)
    },
    [getPossibleAdmins],
  )

  const onToggleAdmin = useCallback(
    user => {
      if (admins.find(a => a.id === user.id)) {
        onRemoveAdmin(user)
      } else {
        onAddAdmin(user)
      }
    },
    [admins, onAddAdmin, onRemoveAdmin],
  )

  const loadMore = useCallback(async () => {
    setPage(prev => prev + 1)
    const response = await getPossibleAdmins({
      groups,
      query,
      page: page + 1,
    })

    if (response.status === 200) {
      setMoreAdminsToLoad(response.data.length === 30)
      setPossibleAdmins(prev => [...prev, ...response.data])
    }
  }, [query, groups, page])

  const sortedAdmins = useMemo(() => {
    if (admins) {
      return admins.sort((a, b) => {
        if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
          return -1
        }
        if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
    return []
  }, [admins])

  useEffect(() => {
    if (isOpen) {
      searchUsers({ target: { value: ' ' } })
    }
  }, [isOpen])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('event.add_admins_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {admins.length > 0 && (
          <div className={Styles.selectedAdmins}>
            {sortedAdmins.map(admin => (
              <UserPill key={admin.id} user={admin} onRemove={onRemoveAdmin} />
            ))}
          </div>
        )}

        <div>{searchTitle}</div>

        <div className="mb-2">
          <input
            autoFocus
            className={Styles.input}
            onChange={searchUsers}
            placeholder={t('users.edit.search')}
            type="text"
          />
        </div>
        <div>
          <InfiniteScroll
            dataLength={possibleAdmins.length}
            next={loadMore}
            hasMore={moreAdminsToLoad}
            loader={<div>...</div>}
            height={400}
          >
            {possibleAdmins
              .filter(a => showYourself || a.id !== currentUser.id)
              .map(user => (
                <div className="mb-2" key={user.id}>
                  <button
                    className={Styles.container}
                    type="button"
                    onClick={() => onToggleAdmin(user)}
                  >
                    <div
                      className={
                        admins.some(a => a.id === user.id)
                          ? Styles.radioActive
                          : Styles.radio
                      }
                    />
                    <img
                      alt={user.name}
                      className={Styles.avatar}
                      src={
                        user.media_attachment?.direct_upload_url ??
                        '/images/default-user.png'
                      }
                    />
                    <div className={Styles.nameContainer}>
                      <div className={Styles.name}>{user.name}</div>
                      <div className={Styles.nickname}>@{user.nickname}</div>
                    </div>
                  </button>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      </Modal.Body>
    </Modal>
  )
}

AdminPicker.defaultProps = {
  admins: [],
  groups: [],
  showYourself: false,
}

AdminPicker.propTypes = {
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      media_attachment: PropTypes.shape({
        direct_upload_url: PropTypes.string,
      }),
      name: PropTypes.string.isRequired,
    }),
  ),
  showYourself: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  isOpen: PropTypes.bool.isRequired,
  onAddAdmin: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveAdmin: PropTypes.func.isRequired,
  searchTitle: PropTypes.string.isRequired,
}
