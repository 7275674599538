import propTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function EzTooltip({
  outerContent,
  innerContent = 'inner content',
  type = 'primary', //primary or secondary.
  placement = 'top',
  id,
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={id} className={`tooltip_${type}`}>
          {innerContent}
        </Tooltip>
      }
    >
      <span>{outerContent}</span>
    </OverlayTrigger>
  )
}

EzTooltip.propTypes = {
  outerContent: propTypes.node,
  innerContent: propTypes.node,
  type: propTypes.string,
  placement: propTypes.string,
  id: propTypes.string.isRequired,
}
