import React from 'react'

export function stringOfFormattedHashtags(string) {
  return string
    ?.split(' ')
    .map(hashtag => `#${hashtag}`)
    .join(', ')
}

export function formatHashtags(string) {
  return string?.split(' ').map(hashtag => (
    <span key={hashtag} className="hashtag">
      #{hashtag.replaceAll('#', '').replaceAll(',', '')}
    </span>
  ))
}

export default formatHashtags
