import { useDebouncedCallback } from 'use-debounce'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import useApi from '../hooks/useApi'

function LocationSearch(props) {
  const { disabled, onChange, placeholder, value: location } = props

  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(location)

  const { locationAutocomplete, locationPlaceDetails } = useApi()

  const loadOptions = useDebouncedCallback(
    async input => {
      if (input?.length < 2) {
        return []
      }
      setIsLoading(true)
      const { data } = await locationAutocomplete({ input })
      if (data?.error) {
        return []
      }
      const options = data?.map(prediction => ({
        value: prediction.place_id,
        label: prediction.description,
      }))
      setIsLoading(false)
      return options ?? []
    },
    500,
    { leading: true, trailing: true },
  )

  const onSelect = useCallback(
    async newValue => {
      if (newValue) {
        setIsLoading(true)
        const { data } = await locationPlaceDetails({
          placeId: newValue.value,
        })
        onChange?.({ ...data, description: newValue.label })
        setValue(newValue.label)
        setIsLoading(false)
      }
    },
    [locationPlaceDetails],
  )

  const onClear = useCallback(() => {
    onChange?.(null)
    setValue(null)
  }, [onChange])

  return (
    <AsyncSelect
      blurInputOnSelect
      defaultInputValue={value}
      isClearable
      isDisabled={disabled}
      isLoading={isLoading}
      isSearchable
      loadOptions={loadOptions}
      onChange={onSelect}
      onClearIndicatorMouseDown={onClear}
      placeholder={placeholder}
    />
  )
}

export default LocationSearch

LocationSearch.defaultProps = {
  disabled: false,
  onChange: () => {},
  placeholder: '',
  value: '',
}

LocationSearch.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}
