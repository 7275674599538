import { Modal } from 'react-bootstrap'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import useApi from '../../../hooks/useApi'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import Button from '../../../components/button'
import Icon from '../../../components/icon'
import styles from '../../events/sponsorship/sponsorship.module.scss'
import { feeValue, formatAmount } from '../../../lib/numbers'
import AddFundingSourceForm from './AddFundingSourceForm'
import SponsorPaymentForm from './SponsorPaymentForm'
import Badge from '../../../components/badge'

interface SponsorPaymentModalProps {
  pledge?: any
  onClose: () => void
  show: boolean
  setRefresh: () => void
}

type Step = 'addFundingSource' | 'payment' | 'success'

export default function SponsorshipPaymentModal(
  props: SponsorPaymentModalProps,
) {
  const { t } = useTranslation()
  const { business, fetchBusiness } = useCurrentUser()

  const { civPledgeSubmitPayment } = useApi()

  const [step, setStep] = useState<Step>('payment')

  const onSubmit = useCallback(async () => {
    const response = await civPledgeSubmitPayment({
      pledgeId: props.pledge.id,
      amountPaid:
        '' +
        (props.pledge.amount +
          (feeValue(props.pledge?.amount, props.pledge?.civillos_admin_fee) ??
            0) +
          (feeValue(props.pledge?.amount, props.pledge?.meta_admin_fee) ?? 0)),
    })
    const error =
      //@ts-ignore
      response?.error?.response?.data?.error || response?.error?.message
    if (error) {
      alert(error)
    } else {
      // setStep('success')
      // For now it close the modal, no success step
      props.setRefresh()
      props.onClose()
    }
  }, [props.pledge])

  const closeModal = useCallback(() => {
    setStep('payment')
    props.onClose()
  }, [props.onClose])

  return (
    <Modal
      onHide={closeModal}
      show={props.show}
      contentClassName={styles.modalBackground}
    >
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Badge />
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        {step === 'payment' ? (
          <SponsorPaymentForm
            business={business}
            pledge={props.pledge}
            onSubmit={onSubmit}
            addFundingSource={() => setStep('addFundingSource')}
          />
        ) : null}
        {step === 'addFundingSource' ? (
          <AddFundingSourceForm
            businessId={business?.id}
            onSave={() => {
              fetchBusiness()
              setStep('payment')
            }}
            onHide={() => setStep('payment')}
          />
        ) : null}
        {step === 'success' ? (
          <div className={styles.section}>
            <div className="mb-3">
              <h2>
                <b>{t('sponsorship.pledgeModal.successTitle')}</b>
              </h2>
            </div>
            <div className={styles.successIconContainer}>
              <Icon name="check-circle" />
            </div>
            <div className="mb-4 text-center">
              {moment().format('MMMM Do, YYYY')}
            </div>

            <div className="mb-2 text-center">
              {t('sponsorship.pledgeModal.payForwardEvent')}
            </div>
            <div className="mb-2 fw-bold text-center">
              {props.pledge?.event?.title}
            </div>
            <div className="mb-4 text-center">
              {t('sponsorship.pledgeModal.successfullyFunded')}
            </div>

            <div className="mb-4 text-center fw-bold">
              {formatAmount(props.pledge?.amount)} CIV
            </div>

            <div className="mb-5 text-center">
              {t('sponsorship.pledgeModal.receiptsAvailable')}
            </div>

            <div className="mb-3">
              <Button onClick={() => closeModal()} className="w-100 mb-4">
                {t('buttons.close')}
              </Button>
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  )
}
