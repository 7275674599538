import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Icon from './icon'
import Styles from './user-pill.module.scss'

export default function UserPill({
  user,
  onClick = () => {},
  onRemove = () => {},
}) {
  const handleClickRemove = useCallback(
    e => {
      e.preventDefault()
      onRemove(user)
    },
    [onRemove, user],
  )

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      onClick(user)
    },
    [onClick, user],
  )

  return (
    <span
      className={Styles.container}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className={Styles.name}>
        <img
          src={
            user.media_attachment?.direct_upload_url ??
            '/images/default-user.png'
          }
          alt={user?.name}
          className={Styles.avatar}
        />
        {user?.name ?? `@${user.nickname}`}
      </div>

      {!!onRemove && (
        <button
          type="button"
          onClick={handleClickRemove}
          className={Styles.removeButton}
        >
          <Icon name="close" className={Styles.remove} />
        </button>
      )}
    </span>
  )
}

UserPill.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    media_attachment: PropTypes.shape({
      direct_upload_url: PropTypes.string,
    }),
    name: PropTypes.string,
    nickname: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
}
