import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import UseSettings from '../../hooks/useSettings'
import Button from '../button'
import PledgeHeader from './PledgeHeader'
import SponsorAdMessage from '../SponsorAdMessage/SponsorAdMessage'
import styles from '../../pages/events/sponsorship/sponsorship.module.scss'
// import TextInput from '../TextInput/TextInput'
import { formatAmount } from '../../lib/numbers'

interface SponsorMessageFormProps {
  event?: any
  onSubmit: (message: string) => void
  pledgeAmount?: string
  currentFee?: number
}

export default function SponsorMessageForm(props: SponsorMessageFormProps) {
  const { t } = useTranslation()

  const { business } = useCurrentUser()
  const { pledgeExpirationDays } = UseSettings()
  const { updateBusiness } = useApi()

  const [adMessage, setAdMessage] = useState(
    business?.pay_forward_message ?? '',
  )
  const [isEditingAdMessage, setIsEditingAdMessage] = useState(false)
  const [personalMessage, setPersonalMessage] = useState('')

  const handleOnClickSaveAdMessage = useCallback(async () => {
    const params = {
      business: {
        pay_forward_message: adMessage,
      },
    }
    await updateBusiness(business.id, params)
    setIsEditingAdMessage(false)
  }, [updateBusiness, adMessage, business?.id])

  const handleOnClickSubmit = useCallback(async () => {
    props.onSubmit(personalMessage)
  }, [personalMessage, props])

  return (
    <>
      <div className="mb-3">
        <h2>
          <b>{t('sponsorship.pledgeModal.messageTitle')}</b>
        </h2>
      </div>
      <div className="mb-3">
        <div className={styles.section}>
          <PledgeHeader event={props.event} pledgeAmount={props.pledgeAmount} />
        </div>
      </div>
      <div className="text-center mt-2 mb-3">
        <strong>{t('sponsorship.pledgeModal.volunteerPayForward')}</strong>
      </div>
      <div className={styles.section}>
        <div className={styles.adPreviewContainer}>
          <div className="d-flex justify-content-between align-items-center">
            <b className={styles.adPreviewFont}>
              {t('sponsorship.pledgeModal.sponsorAdPreview')}
            </b>
            <Button
              variant="link"
              onClick={() => setIsEditingAdMessage(!isEditingAdMessage)}
              className={styles.adPreviewFont}
            >
              {t('sponsorship.pledgeModal.editSponsorMessageButton')}
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.messageSection}>
          <SponsorAdMessage business={business} adMessage={adMessage} />
        </div>
        {isEditingAdMessage ? (
          <div className={styles.messageSectionEdit}>
            <div className="mt-2 text-small">
              <span className={styles.noteEdit}>
                {t('sponsorship.pledgeModal.editSponsorMessageNote')}
              </span>
            </div>
            <div className="d-flex flex-row mt-2 mb-2">
              <textarea
                className="w-100 rounded p-1"
                onChange={e => setAdMessage(e.target.value)}
                placeholder={business?.pay_forward_message ?? ''}
                value={adMessage}
                rows={2}
              />
              <Button
                className="ms-3 align-self-center"
                onClick={handleOnClickSaveAdMessage}
              >
                {t('users.edit.save')}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.section}>
        <div className="my-3">
          {t('sponsorship.pledgeModal.personalMessage')}
          <textarea
            className="w-100 pb-5"
            onChange={e => setPersonalMessage(e.target.value)}
            value={personalMessage}
          />
        </div>

        <div className={styles.terms}>
          <p>
            <Trans
              i18nKey="sponsorship.pledgeModal.messageFinePrint"
              values={{
                days: pledgeExpirationDays,
                service_fee: formatAmount(props.currentFee, true),
              }}
            />
          </p>
          <p>
            <span>
              {t('sponsorship.pledgeModal.messageFinePrintTerms.text')}
              <a
                href={t('user_mailer.welcome_email.terms_of_service_url')}
                target="_blank"
              >
                {t('sponsorship.pledgeModal.messageFinePrintTerms.link')}
              </a>
            </span>
          </p>
        </div>

        <Button className="w-100 mb-4" onClick={handleOnClickSubmit}>
          {t('sponsorship.pledgeModal.submitPledgeButton')}
        </Button>
      </div>
    </>
  )
}
