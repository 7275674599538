import { Col, Container, Row } from 'react-bootstrap'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/button'
import LargeIcon from '../../../components/LargeIcon'
import SponsorPledgeModal from '../../../components/SponsorPledgeModal/SponsorPledgeModal'
import styles from './sponsorship.module.scss'
import useApi from '../../../hooks/useApi'

export default function EventSponsorshipLandingPage() {
  const { getEvent } = useApi()
  const { slug: eventId } = useParams()
  const { state } = useLocation()
  const { t } = useTranslation()

  const [event, setEvent] = useState(
    () =>
      state?.event ?? {
        id: eventId,
      },
  )
  const [showSponsorPledgeModal, setShowSponsorPledgeModal] = useState(false)

  const eventBackgroundImage = useMemo(() => {
    return {
      style: {
        backgroundImage: `url(${event?.media_attachments?.[0]?.direct_upload_url})`,
      },
    }
  }, [event])

  const fetchEvent = useCallback(async () => {
    const { data } = await getEvent({ id: event?.id })
    setEvent(data)
  }, [event, getEvent])

  useEffect(() => {
    fetchEvent()
  }, [])

  const onClickShowSponsorPledgeModal = useCallback(() => {
    setShowSponsorPledgeModal(true)
  }, [])

  const onClosedSponsorPledgeModal = useCallback(() => {
    setShowSponsorPledgeModal(false)
  }, [])

  return (
    <>
      <section
        className={`bg-image ${styles.section1}`}
        {...eventBackgroundImage}
      >
        <div className={styles.section1_background}>
          <Container>
            <Row>
              <Col className="mx-auto text-center">
                <div className={styles.section1_text}>
                  {t('sponsorship.landingPage.section1.text')}
                </div>
                <h1 className={styles.section1_title}>
                  {t('sponsorship.landingPage.section1.title')}
                </h1>
                <div className={styles.section1_cta}>
                  {t('sponsorship.landingPage.section1.cta')}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.section1_description_container}>
          <div className={styles.overlay}></div>
          <Container>
            <Row>
              <Col>
                <div className={styles.section1_description}>
                  {t('sponsorship.landingPage.section2.text')}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className={styles.section2}>
        <Container>
          <Row>
            <Col className="mt-4 mb-4">
              <h4 className="mt-4 title">
                {t('sponsorship.landingPage.section3.title')}
              </h4>
              <div className="">
                {t('sponsorship.landingPage.section3.text')}
              </div>
              <div className="mt-4">
                <Button
                  variant="secondary"
                  onClick={onClickShowSponsorPledgeModal}
                >
                  {t('sponsorship.landingPage.section3.button')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={styles.section3}>
        <Container>
          <Row>
            <Col>
              <h2 className="mb-4">
                {t('sponsorship.landingPage.section4.title')}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <LargeIcon name="hands-holding-heart" className="mx-auto" />
              <h4 className="mt-4">
                {t('sponsorship.landingPage.section4.title1')}
              </h4>
              <div className="">
                {t('sponsorship.landingPage.section4.text1')}
              </div>
            </Col>
            <Col>
              <LargeIcon name="file-invoice-dollar" className="mx-auto" />
              <h4 className="mt-4">
                {t('sponsorship.landingPage.section4.title2')}
              </h4>
              <div className="">
                {t('sponsorship.landingPage.section4.text2')}
              </div>
            </Col>
            <Col>
              <LargeIcon name="comment-smile" className="mx-auto" />
              <h4 className="mt-4">
                {t('sponsorship.landingPage.section4.title3')}
              </h4>
              <div className="">
                {t('sponsorship.landingPage.section4.text3')}
              </div>
            </Col>
            <Col>
              <LargeIcon name="award" className="mx-auto" />
              <h4 className="mt-4">
                {t('sponsorship.landingPage.section4.title4')}
              </h4>
              <div className="">
                {t('sponsorship.landingPage.section4.text4')}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className={`bg-image ${styles.section4}`}
        style={{
          backgroundImage: 'url(/images/iStock-1016780008-scaled.jpg)',
        }}
      >
        <div className={styles.overlay}></div>
        <Container>
          <Row>
            <Col className="mx-auto text-center">
              <h2>{t('sponsorship.landingPage.section5.title')}</h2>
              <p>{t('sponsorship.landingPage.section5.text')}</p>
              <Button onClick={onClickShowSponsorPledgeModal}>
                {t('sponsorship.landingPage.section5.button')}
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <SponsorPledgeModal
        event={event}
        onClose={onClosedSponsorPledgeModal}
        show={showSponsorPledgeModal}
      />
    </>
  )
}
