import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import UseWallet from '../../hooks/useWallet'
import Icon from '../../components/icon'
import SortTable from '../../components/sortTable/sortTable'
import { feeValue, formatAmount } from '../../lib/numbers'
import { Col, Row } from 'react-bootstrap'

export default function Purchases({ business, setLoading }) {
  const { t } = useTranslation()

  const [civPurchases, setCivPurchases] = useState()
  const [body, setBody] = useState([])
  const [queryParams, setQueryParams] = useState({
    sort_by: 'created_at',
    sort_order: 'DESC',
  })
  const { wallet } = UseWallet(business?.wallet_id, business?.bank_account_id)

  const { getCivPurchases } = useApi()

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCivPurchases(queryParams)
      setCivPurchases(response.data)
      setBody(
        response.data?.map(d => ({
          created_at: moment(d.created_at).format(t('config.date_format')),
          amount: (
            <Row>
              <Col className="col-3">{`${formatAmount(d.amount)} CIV`}</Col>
              <Col>
                <Link to={`acknowledgement/${d.id}/print`}>
                  <Icon name={'download'} className={'info-circle'} />
                </Link>
              </Col>
            </Row>
          ),
          admin_fee_amount: !!d.civillos_admin_fee ? (
            <Row>
              <Col className="col-3">
                {formatAmount(feeValue(d.amount, d.civillos_admin_fee), true)}
              </Col>
              <Col>
                {feeValue(d.amount, d.civillos_admin_fee) > 0 ? (
                  <Link to={`admin-fee-receipt/${d.id}/print`}>
                    <Icon name={'download'} className={'info-circle'} />
                  </Link>
                ) : (
                  <Icon name={'download'} className="defaultText" />
                )}
              </Col>
            </Row>
          ) : (
            ''
          ),
        })),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getCivPurchases, queryParams])

  useEffect(() => {
    fetchData()
  }, [queryParams])

  const headers = [
    {
      title: t('dashboard.purchases.headers.date'),
      prop: 'created_at',
      isSortable: true,
    },
    {
      title: t('dashboard.purchases.headers.amount'),
      prop: 'amount',
      isSortable: true,
    },
    {
      title: t('dashboard.purchases.headers.adminFee'),
      prop: 'admin_fee_amount',
      isSortable: true,
    },
  ]

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  return (
    <>
      <div className="container mt-4">
        <div className="row justify-content-between align-items-end">
          <h1 className="col fs-1">{t('dashboard.purchases.title')}</h1>
          {/* <h4 className="col text-end fw-normal">
            <Trans
              i18nKey="dashboard.purchases.balance"
              values={{
                total: formatAmount(wallet?.balance ?? 0),
              }}
            />
          </h4> */}
        </div>
      </div>
      <div className="container">
        <SortTable
          body={body}
          headers={headers}
          onFetch={s => onFetch(s)}
          showPagination={false}
        />
      </div>
    </>
  )
}
