import { useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../../hooks/useCurrentUser'
import useApi from '../../../hooks/useApi'
import Button from '../../../components/button'

export default function ContactSupportModal(props) {
  const { t } = useTranslation()
  const [message, setMessage] = useState()
  const [submitStatus, setSubmitStatus] = useState(false)

  const { currentUser } = useCurrentUser()
  const { releaseCharityRegistrationEmail } = useApi()

  const onSave = () => {
    const {
      id: registrationId,
      charity: { ein, id, organization_id, organization_name },
    } = props.registrationToSupport
    const payload = {
      name: currentUser.name,
      email: currentUser.email,
      message: [
        message,
        `Charity ID: ${id}`,
        `Organization Name: ${organization_name}`,
        `Organization ID: ${organization_id}`,
        `Charity EIN: ${ein}`,
      ].join(' | '),
    }
    releaseCharityRegistrationEmail(registrationId, payload)
    setSubmitStatus(true)
  }

  const onClose = () => {
    props.onHide()
    setMessage()
    setSubmitStatus(false)
  }

  return (
    <Modal show={props.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto p-0">
          {t('charities.register.contact_support')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!submitStatus ? (
          <>
            <div className="mx-auto w-100">
              {t('charities.register.support_modal.message')}
            </div>
            <div className="row form-row justify-content-md-center">
              <div className="form-group col">
                <textarea
                  className="form-control form-control-lg"
                  id="message"
                  name="message"
                  value={message}
                  rows={3}
                  onChange={e => setMessage(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="text-start">
            {t('charities.register.support_modal.success')}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button variant="secondary" onClick={onClose}>
          {t('buttons.close')}
        </Button>
        {!submitStatus && (
          <Button variant="primary" onClick={onSave} disabled={!message}>
            {t('buttons.send')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
