import { Category } from '../types'

export function categoriesToString(categories: Category[], locale?: string) {
  return categories
    ?.map(item => {
      if (locale === 'es') {
        return item?.es_name
      }
      return item?.en_name
    })
    ?.join(', ')
}
