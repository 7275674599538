import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import Icon from '../../components/icon'
import SortTable from '../../components/sortTable/sortTable'
import { formatAmount } from '../../lib/numbers'

export default function SponsorshipPayments({ setLoading }) {
  const { t } = useTranslation()
  const [payments, setPayments] = useState()
  const [body, setBody] = useState([])
  const [queryParams, setQueryParams] = useState({
    sort_by: 'created_at',
    sort_order: 'DESC',
  })
  const [refresh, setRefresh] = useState(null)
  const [selectedUpload, setSelectedUpload] = useState(null)
  const {
    civPledgeFinalize,
    getSponsorshipPayments,
    uploadSponsorshipPayment,
  } = useApi()

  const hiddenFileInput = useRef(null)
  const handleClickUpload = (event, id) => {
    setSelectedUpload(id)
    hiddenFileInput.current.click()
  }

  const statusCol = item => {
    return (
      <Row>
        <Col className="col-2">
          <Button
            className="btn-sm w-100"
            disabled={item?.payment_status === 'paid'}
            onClick={() => markPaid(item.id)}
          >
            {t(`accounting.payments.status.${item?.payment_status}`)}
          </Button>
        </Col>
        <Col className="col-2">
          {item?.payment_status === 'paid' && !!item?.updated_at
            ? moment(item?.updated_at).format(t('config.date_format'))
            : ''}
        </Col>
        <Col className="col-3 text-end">
          {item?.media_attachments?.length
            ? t('accounting.payments.upload.date', {
                date: moment(item?.media_attachments[0].created_at).format(
                  t('config.date_format'),
                ),
              })
            : ''}
        </Col>
        <Col className="col-5">
          <span className="fw-semibold">
            {t('accounting.payments.upload.label')}
          </span>
          <button
            type="button"
            className="btn btn-link btn-sm p-0 text-decoration-none"
            onClick={event => handleClickUpload(event, item.id)}
          >
            {t('accounting.payments.upload.link')}
            <Icon name="upload" className="ms-1" />
          </button>
        </Col>
      </Row>
    )
  }

  const markPaid = async id => {
    try {
      setLoading(true)
      const response = await civPledgeFinalize(id)
      if (response.status === 200) {
        const updated = payments.findIndex(d => d.id === id)
        const tempPayments = [...payments]
        tempPayments[updated].payment_status = 'paid'
        tempPayments[updated].updated_at = new Date()
        setPayments([...tempPayments])
      } else {
        alert(t('accounting.payments.errors.markPaid'))
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const paymentUploadOnChange = async e => {
    const file = e.target?.files?.[0]
    if (!file || !selectedUpload) {
      return
    }

    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('confirmation', file)
      const uploadResponse = await uploadSponsorshipPayment(
        selectedUpload,
        formData,
      )
      // Validate success upload
      if (uploadResponse.status === 200) {
        const updated = payments.findIndex(d => d.id === selectedUpload)
        const tempPayments = [...payments]
        // We use current date, to avoid a new call
        tempPayments[updated]['media_attachments'].splice(0, 0, {
          created_at: new Date(),
        })
        setPayments([...tempPayments])
      } else {
        alert(t('accounting.payments.errors.uploadFile'))
      }
      setSelectedUpload(null)
      hiddenFileInput.current.value = ''
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getSponsorshipPayments(queryParams)
      setPayments(response.data)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getSponsorshipPayments, queryParams])

  useEffect(() => {
    setBody(
      payments?.map(p => ({
        business_name: p.business_name,
        amount: formatAmount(p.amount, true),
        payment_status: statusCol(p),
      })),
    )
  }, [payments])

  useEffect(() => {
    fetchData()
  }, [queryParams, refresh])

  const headers = [
    {
      title: t('accounting.payments.headers.business'),
      prop: 'business_name',
      isSortable: false,
    },
    {
      title: t('accounting.payments.headers.amount'),
      prop: 'amount',
      isSortable: true,
    },
    {
      title: t('accounting.payments.headers.status'),
      prop: 'payment_status',
      isSortable: false,
    },
  ]

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  return (
    <>
      <input
        id="sponsorshipPayment"
        className="hidden"
        onChange={paymentUploadOnChange}
        ref={hiddenFileInput}
        type="file"
      />
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('accounting.payments.title')}</h1>
        </div>
      </div>
      <div className="container mt-4">
        <SortTable
          body={body}
          headers={headers}
          onFetch={s => onFetch(s)}
          showPagination={false}
          rowStyle="smaller-font"
        />
      </div>
    </>
  )
}
