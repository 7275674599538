import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import UseWallet from '../../hooks/useWallet'
import Block from '../../components/Dashboard/Block'
import EventTitle from '../../components/Dashboard/EventTitle'
import Icon from '../../components/icon'
import SortTable from '../../components/sortTable/sortTable'
import BlockContent from '../../components/Dashboard/BlockContent'
import { currentStatus } from '../../lib/event'
import { feeValue, formatAmount } from '../../lib/numbers'
import { pledgeState } from '../../lib/pledges'
import SponsorshipPaymentModal from './modal'

export default function Sponsorship({ setLoading }) {
  const { t } = useTranslation()
  const [civPledges, setCivPledges] = useState()
  const [body, setBody] = useState([])
  const [queryParams, setQueryParams] = useState({
    sort_by: 'created_at',
    sort_order: 'DESC',
  })
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [pledge, setPledge] = useState()
  const [refresh, setRefresh] = useState(null)
  const { getBusinessCivPledges } = useApi()
  const { business } = useCurrentUser()

  const { wallet } = UseWallet(business?.wallet_id, business?.bank_account_id)

  const serviceFeeCol = item => {
    const serviceFeeValue =
      feeValue(item?.amount, item?.meta_admin_fee) || (item?.service_fee ?? 0)
    return item?.state === 'accepted' && item?.payment_status === 'paid' ? (
      <Row>
        <Col>{formatAmount(serviceFeeValue, true)}</Col>
        <Col>
          {serviceFeeValue > 0 ? (
            <Link to={`pledge-receipt/${item?.id}/print`}>
              <Icon name={'download'} className={'info-circle'} />
            </Link>
          ) : (
            <Icon name={'download'} className="defaultText" />
          )}
        </Col>
      </Row>
    ) : (
      <Row>
        <Col className="defaultText">{formatAmount(serviceFeeValue, true)}</Col>
        <Col>
          <Icon name={'download'} className="defaultText" />
        </Col>
      </Row>
    )
  }

  const paymentCol = item => {
    if (item?.state !== 'accepted' || !item?.payment_status) {
      return (
        <span className="defaultText">
          {t('dashboard.sponsorship.paymentStatus.none')}
        </span>
      )
    }
    return (
      <Button
        variant={`link ${
          item?.payment_status !== 'pending'
            ? 'text-decoration-none text-reset'
            : ''
        }`}
        className="p-0 smaller-font"
        onClick={() => {
          setPledge(item)
          setShowPaymentModal(true)
        }}
      >
        {t(`dashboard.sponsorship.paymentStatus.${item?.payment_status}`)}
      </Button>
    )
  }

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getBusinessCivPledges(business?.id, queryParams)
      setCivPledges(response.data)
      setBody(
        response.data?.pledges.map(d => ({
          created_at: moment(d.created_at).format(t('config.date_format')),
          amount: `${formatAmount(d.amount)} CIV`,
          service_fee: serviceFeeCol(d),
          state: pledgeState(d, 'dashboard.sponsorship'),
          title: <EventTitle event={d.event} />,
          event_start_date: moment(d.event.event_start_date).format(
            t('config.date_format'),
          ),
          event_end_date: t(currentStatus(d.event)),
          payment: paymentCol(d),
        })),
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [getBusinessCivPledges, queryParams, business])

  useEffect(() => {
    if (business?.id) {
      fetchData()
    }
  }, [queryParams, business, refresh])

  const headers = [
    {
      title: t('dashboard.sponsorship.headers.dateSubmitted'),
      prop: 'created_at',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.pledge'),
      prop: 'amount',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.serviceFee'),
      prop: 'service_fee',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.pledgeStatus'),
      prop: 'state',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.event'),
      prop: 'title',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.eventStartDate'),
      prop: 'event_start_date',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.eventStatus'),
      prop: 'event_end_date',
      isSortable: true,
    },
    {
      title: t('dashboard.sponsorship.headers.payment'),
      prop: 'payment',
      isSortable: true,
    },
  ]

  const onFetch = params => {
    if (!!params.sortState.prop) {
      setQueryParams({
        sort_by: params.sortState.prop,
        sort_order: params.sortState.order.toUpperCase(),
      })
    }
  }

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('dashboard.sponsorship.title')}</h1>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          {/* <Block>
            <BlockContent
              stat={`${formatAmount(wallet?.balance ?? 0)} CIV`}
              statLabel={t('dashboard.sponsorship.walletBalance')}
            />
          </Block> */}
          <Block>
            <BlockContent
              stat={`${formatAmount(
                civPledges?.total_pending_amount ?? 0,
              )} CIV`}
              statLabel={t('dashboard.sponsorship.pendingPledge')}
            />
          </Block>
          <Block>
            <BlockContent
              stat={`${formatAmount(
                civPledges?.total_accepted_amount ?? 0,
              )} CIV`}
              statLabel={t('dashboard.sponsorship.sponsored')}
            />
          </Block>
          {/* <Block>
            <BlockContent
              stat={<Icon name={'medal'} />}
              statLabel={'PLACEHOLDER'}
            />
          </Block> */}
        </div>
      </div>
      <div className="container mt-4">
        <SortTable
          body={body}
          headers={headers}
          onFetch={s => onFetch(s)}
          showPagination={false}
          rowStyle="smaller-font"
        />
      </div>
      <SponsorshipPaymentModal
        pledge={pledge}
        onClose={() => setShowPaymentModal(false)}
        setRefresh={() => setRefresh(+new Date())}
        show={showPaymentModal}
      />
    </>
  )
}
