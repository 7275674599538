import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ModalDelete({ show, onHide, onClick }) {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('event.delete_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('event.delete_modal_message')}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('event.delete_modal_cancel')}
        </Button>
        <Button variant="danger" onClick={onClick}>
          {t('event.delete_modal_delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
