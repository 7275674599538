import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function TasksFooter({
  onClear,
  clearDisabled,
  onDone,
  doneDisabled,
  onCancel,
}) {
  const { t } = useTranslation()

  return (
    <div className="task-footer-buttons row">
      <div className="col-8">
        <Button variant="secondary" onClick={onClear} disabled={clearDisabled}>
          {t('buttons.clear')}
        </Button>
      </div>
      <div className="col-4">
        <button className="btn btn-secondary m-2" onClick={onCancel}>
          {t('buttons.cancel')}
        </button>
        <button
          className="btn btn-primary m-2"
          onClick={onDone}
          disabled={doneDisabled}
        >
          {t('buttons.done')}
        </button>
      </div>
    </div>
  )
}
