import { Button, Modal } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'

export default function ModalEnroll({ show, onHide, onClick }) {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('event.enroll_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans i18nKey="event.enroll_modal_message" />
        {/* <p>{t('event.enroll_modal_message.line_1')}</p>
        <p>{t('event.enroll_modal_message.line_2')}</p>
        <p>{t('event.enroll_modal_message.line_3')}</p>
        <p>{t('event.enroll_modal_message.line_4')}</p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={onClick}>
          {t('buttons.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
