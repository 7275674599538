import { FormCheck } from 'react-bootstrap'
import React, { useCallback } from 'react'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import Icon from '../icon'

export interface Cause {
  description?: string
  en_name?: string
  es_name?: string
  icon?: string
  id: string
}

interface CausePickerItemProps {
  cause: Cause
  isSelected: boolean
  onChange: (causeId: string) => void
}

const CausePickerItem: React.FC<CausePickerItemProps> = ({
  cause,
  isSelected,
  onChange,
}) => {
  const { currentUser } = useCurrentUser()

  const handleCausePicker = useCallback(() => {
    onChange(cause.id)
  }, [cause.id, onChange])

  return (
    <FormCheck>
      <FormCheck.Label>
        <FormCheck.Input
          type="checkbox"
          checked={isSelected}
          className={'chk-checkbox'}
          onChange={handleCausePicker}
        />
        <Icon name={cause.icon} />{' '}
        {currentUser?.language === 'es' ||
        localStorage.getItem('i18nextLng') === 'es'
          ? cause.es_name
          : cause.en_name}
      </FormCheck.Label>
    </FormCheck>
  )
}

export default CausePickerItem
