import { Outlet } from 'react-router-dom'
import React from 'react'

import Footer from './footer'
import Navbar from './Navbar/navbar'

export default function Layout() {
  return (
    <>
      <Navbar showAsLoggedOut={false} />
      <Outlet />
      <Footer />
    </>
  )
}
