import React, { useCallback, useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Badge from '../../components/badge'
import Loading from '../../components/loading'
import Icon from '../../components/icon'
import useApi from '../../hooks/useApi'

export default function SponsorshipProgram({ businessData }) {
  const { t } = useTranslation()

  const { updateBusiness } = useApi()

  const [hasSponsorshipChanged, setHasSponsorshipChanged] = useState(false)
  const [sponsorshipLoading, setSponsorshipLoading] = useState(false)
  const [business, setBusiness] = useState()

  useEffect(() => {
    setBusiness(businessData)
  }, [businessData])

  const onSaveSponsorship = useCallback(async () => {
    setSponsorshipLoading(true)
    const params = {
      business: {
        volunteer_pay_forward: business.volunteer_pay_forward,
        donor_match_pay_forward: business.donor_match_pay_forward,
      },
    }
    const response = await updateBusiness(business.id, params)
    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(response.errors?.full_messages?.[0])
    } else {
      setBusiness({ ...business, ...response.data })
      setHasSponsorshipChanged(false)
    }
    setSponsorshipLoading(false)
  }, [business])

  return (
    <>
      <Loading show={sponsorshipLoading} />
      <div className="container">
        <div className="row">
          <h1 className="badge-container">
            <Icon name="hands-usd" className={'business-title-icon'} />
            {t('business.sponsorship.title')}
            <Badge />
          </h1>
          <h6 className="block-description">
            {t('business.sponsorship.description')}
          </h6>
        </div>

        <div className="row form-row space-title">
          <div className="form-group col-md-3">
            <FormCheck>
              <FormCheck.Input
                type="checkbox"
                checked={business?.volunteer_pay_forward}
                className={'chk-checkbox'}
                onChange={e => {
                  setBusiness({
                    ...business,
                    volunteer_pay_forward: e.target.checked,
                  })
                  setHasSponsorshipChanged(true)
                }}
              />
              <FormCheck.Label>
                {t('business.sponsorship.volunteer_pay_forward')}
              </FormCheck.Label>
            </FormCheck>
          </div>
          <div className="form-group col-md-3">
            <FormCheck>
              <FormCheck.Input
                type="checkbox"
                checked={business?.donor_match_pay_forward}
                className={'chk-checkbox'}
                onChange={e => {
                  setBusiness({
                    ...business,
                    donor_match_pay_forward: e.target.checked,
                  })
                  setHasSponsorshipChanged(true)
                }}
              />
              <FormCheck.Label>
                {t('business.sponsorship.donor_match_pay_forward')}
              </FormCheck.Label>
            </FormCheck>
          </div>
        </div>
        <input
          className="btn btn-primary"
          id="settings-form-submit"
          onClick={onSaveSponsorship}
          type="submit"
          value={t('business.edit.save')}
          disabled={!hasSponsorshipChanged}
        />
      </div>
    </>
  )
}
