import React, { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import Badge from '../../components/badge'
import Loading from '../../components/loading'
import Sponsorship from './sponsorship'
import Purchases from './purchases'

export default function Dashboard() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [loadingSponsorship, setLoadingSponsorship] = useState(false)
  const [loadingPurchases, setLoadingPurchases] = useState(false)

  return (
    <div className="mb-5">
      <Loading show={loading || loadingSponsorship || loadingPurchases} />
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1 badge-container">
            {t('dashboard.title')}
            <Badge />
          </h1>
          <h6 className="block-description">
            {t('dashboard.description')}&nbsp;
            {/* <a target="_blank" href={t('dashboard.learnMore.link')}>
              {t('dashboard.learnMore.text')}
            </a> */}
          </h6>
        </div>
      </div>
      <Sponsorship setLoading={setLoadingSponsorship} />
      <Purchases setLoading={setLoadingPurchases} />
    </div>
  )
}
