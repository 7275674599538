import React from 'react'
import Icon from '../icon'

export default function StarRating({ statNum, label, statType = null }) {
  // label is included to make unique keys
  const stArr = []
  for (let i = 0; i < 5; i += 1) {
    if (statNum >= i + 1) {
      stArr.push(
        <Icon name={'star'} className="starIcon" key={`${label} ${i}`} />,
      )
    } else if (statNum >= i + 0.5) {
      // check if full star
      stArr.push(
        <Icon
          name={'star-half-stroke'}
          className="starIcon"
          key={`${label} ${i}`}
        />,
      )
    } else {
      // check if half star
      stArr.push(
        <Icon
          name={'star-regular'}
          className="starIcon"
          key={`${label} ${i}`}
        />,
      )
    } // empty star
  }
  return (
    <>
      <span className="starRating">{stArr}</span>
      {statType && <p className="mt-2">{statType}</p>}
    </>
  )
}
