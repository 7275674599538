import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion } from 'react-bootstrap'

import Loading from '../../components/loading'
import CharityRegistration from './charityRegistration'
import CharityDisbursement from './charityDisbursement'
import CivillosWallet from './civillosWallet'
import MetaWallet from './metaWallet'
import SponsorshipPayments from './sponsorshipPayments'

export default function DashboardOrganizer() {
  const { t } = useTranslation()

  const [loadingCharityRegistration, setLoadingCharityRegistration] =
    useState(false)
  const [loadingCharityDisbursement, setLoadingCharityDisbursement] =
    useState(false)
  const [loadingCivillosWallet, setLoadingCivillosWallet] = useState(false)
  const [loadingMetaWallet, setLoadingMetaWallet] = useState(false)
  const [loadingPayments, setLoadingPayments] = useState(false)

  return (
    <div className="mb-5">
      <Loading
        show={
          loadingCharityRegistration ||
          loadingCharityDisbursement ||
          loadingCivillosWallet ||
          loadingMetaWallet ||
          loadingPayments
        }
      />
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('accounting.title')}</h1>
          <h6 className="block-description">{t('accounting.description')}</h6>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1">{t('accounting.actionRequired')}</h1>
        </div>
      </div>
      <div className="container">
        <Accordion flush>
          <CharityRegistration setLoading={setLoadingCharityRegistration} />
          <CharityDisbursement setLoading={setLoadingCharityDisbursement} />
          <CivillosWallet setLoading={setLoadingCivillosWallet} />
          <MetaWallet setLoading={setLoadingMetaWallet} />
        </Accordion>
      </div>
      <SponsorshipPayments setLoading={setLoadingPayments} />
    </div>
  )
}
