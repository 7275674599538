import './index.scss'

import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import React, { Suspense } from 'react'

import './i18n'
import { initFacebookSdk } from './hooks/useFacebook'
import App from './App'
import reportWebVitals from './reportWebVitals'

const render = () => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </BrowserRouter>,
  )
}

// wait for facebook sdk before startup
initFacebookSdk().then(render)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
