import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import moment from 'moment-timezone'

import useApi from '../../hooks/useApi'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import Button from '../button'
import LoginModal from '../Login/LoginModal'

export default function SignUpButton({ event }) {
  const { t } = useTranslation()
  const {
    eventParticipate,
    eventUnparticipate,
    volunteerCreate,
    volunteerDelete,
  } = useApi()

  const [id, setId] = useState(null)
  const [label, setLabel] = useState(null)
  const [action, setAction] = useState(null)
  const [showLogin, setShowLogin] = useState(false)

  const { currentUser, locale } = useCurrentUser()

  /*
    Hide button reason:
    - Event has tasks & shifts
    - Not an event
    - Current user is the owner
    - Current user is an admin
    - Don't need more volunteers and I'm not already register
  */
  if (
    event?.tasks?.length > 0 ||
    event?.event_type !== 'event' ||
    currentUser?.id === event?.user?.id ||
    event?.current_user_admin ||
    (!event?.participant_signup && event?.volunteers_needed === 0)
  ) {
    return null
  }

  // Past event, no action, just a button disabled
  if (
    !!event?.event_end_date &&
    !!moment(event?.event_end_date).isBefore(moment())
  ) {
    return (
      <div className="m-3 d-flex justify-content-center">
        <Button id="past-event" disabled>
          {t('event_card.past')}
        </Button>
      </div>
    )
  }

  // User already register as participant or volunteer
  const registered =
    event?.current_user_participants || event?.current_user_volunteers
  const loggedIn = currentUser && !!Object.keys(currentUser).length

  useEffect(() => {
    // Volunteer event
    if (!event?.participant_signup) {
      setId('event-volunteer-sign-up')
      setLabel(t('event.volunteer_button'))
    } else {
      // Event
      setId('attending')
      setLabel(t('event.participate_button'))
    }
    moment.locale(locale)
  }, [locale])

  const participate = () => {
    eventParticipate({ id: event?.id })
      .then(response => {
        if (response.success || response.status === 200) {
          window.location.reload()
        } else {
          alert(t('event.error_signup'))
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const unparticipate = () => {
    eventUnparticipate({ id: event?.id })
      .then(response => {
        if (response.success || response.status === 200) {
          window.location.reload()
        } else {
          alert(t('event.error_signup'))
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const volunteerAdd = () => {
    volunteerCreate({ id: event?.id })
      .then(response => {
        if (response.success || response.status === 200) {
          window.location.reload()
        } else {
          alert(t('event.error_signup'))
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const volunteerDel = () => {
    volunteerDelete({
      eventId: event?.id,
      volunteerId: event.current_user_volunteer.id,
    })
      .then(response => {
        if (response.success || response.status === 204) {
          window.location.reload()
        } else {
          alert(t('event.error_signup'))
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const callAction = useCallback(() => {
    if (!loggedIn) {
      setShowLogin(true)
    } else {
      if (!!id) {
        if (id === 'attending') {
          !registered ? participate() : unparticipate()
        } else {
          !registered ? volunteerAdd() : volunteerDel()
        }
      }
    }
  }, [registered, id, loggedIn])

  if (!registered || !loggedIn) {
    return (
      <div className="my-3 d-flex justify-content-center signup-event no-print">
        <Button id={id} onClick={callAction}>
          {label}
        </Button>
        <LoginModal
          show={showLogin}
          onClose={(reload = true) => {
            localStorage.removeItem('return-url')
            setShowLogin(false)
            if (reload) {
              window.location.reload()
            }
          }}
          returnUrl={`/events/${event.slug || event.id}`}
        />
      </div>
    )
  }

  return (
    <div className="my-3 d-flex justify-content-center signup-event">
      <Button id={`register-${id}`} disabled className="mx-2">
        {t('event.registered')}
      </Button>
      <Button
        id={id}
        onClick={callAction}
        variant="link"
        className="p-0 text-decoration-none mx-2"
      >
        {t('buttons.cancel')}
      </Button>
    </div>
  )
}
