import React, { useCallback, useEffect, useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useApi from '../../hooks/useApi'
import Loading from '../../components/loading'
import SponsorAdMessage from '../../components/SponsorAdMessage/SponsorAdMessage'
import businessConstant from '../../constants/business'

export default function SponsorshipAdMessage({ businessData }) {
  const { t } = useTranslation()

  const { updateBusiness } = useApi()

  const [hasSponsorAdChanged, setHasSponsorAdChanged] = useState(false)
  const [sponsorshipAdLoading, setSponsorshipAdLoading] = useState(false)
  const [business, setBusiness] = useState()

  useEffect(() => {
    setBusiness(businessData)
  }, [businessData])

  const onSaveSponsorAdMessage = useCallback(async () => {
    setSponsorshipAdLoading(true)
    const params = {
      business: {
        pay_forward_message: business?.pay_forward_message,
      },
    }
    const response = await updateBusiness(business.id, params)
    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(response.errors?.full_messages?.[0])
    } else {
      setBusiness({ ...business, ...response.data })
      setHasSponsorAdChanged(false)
    }
    setSponsorshipAdLoading(false)
  }, [business])

  return (
    <>
      <Loading show={sponsorshipAdLoading} />
      <div className="container">
        <div className="row">
          <h1>{t('business.sponsor_ad_message.title')}</h1>
          <h6 className="block-description">
            {t('business.sponsor_ad_message.description')}
          </h6>
        </div>
        <div className="row form-row space-title">
          <div className="form-group col-md-6">
            <FormControl
              as="textarea"
              rows={5}
              value={business?.pay_forward_message || ''}
              maxLength={businessConstant.adMessageMaxLength}
              onChange={e => {
                setBusiness({
                  ...business,
                  pay_forward_message: e.target.value,
                })
                setHasSponsorAdChanged(true)
              }}
            />
          </div>
          <div className="form-group col-md-6">
            <h6 className="block-description">
              {t('business.sponsor_ad_message.preview.title')}
            </h6>
            <SponsorAdMessage business={business} />
          </div>
        </div>
        <input
          className="btn btn-primary"
          id="settings-form-submit"
          onClick={onSaveSponsorAdMessage}
          type="submit"
          value={t('business.edit.save')}
          disabled={!hasSponsorAdChanged}
        />
      </div>
    </>
  )
}
