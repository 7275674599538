import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useApi from '../../hooks/useApi'
import civ from '../../constants/civ'
import Icon from '../icon'
import BalanceCard from './BalanceCard'
import FundingCard from './FundingCard'
import BalanceModal from './BalanceModal'
import DonationModal from './DonationModal'
import FundingModal from './FundingModal'
import Badge from '../badge'
import { feeValue } from '../../lib/numbers'

export default function CivWallet({
  data,
  setData,
  locale,
  updateData,
  origin,
}) {
  const { t } = useTranslation()

  const { getAdminFee, getBankAccount, getCivWallet, updateCivWallet } =
    useApi()

  const [bankAccountAttributes, setBankAccountAttributes] = useState()
  const [bankAccount, setBankAccount] = useState()
  const [wallet, setWallet] = useState()
  const [civAmount, setCivAmount] = useState()
  const [civSaveStatus, setCivSaveStatus] = useState(null)
  const [adminFee, setAdminFee] = useState(civ.empty)
  const [currentFee, setCurrentFee] = useState(0)
  const [fundingModal, setFundingModal] = useState(false)
  const [balanceModal, setBalanceModal] = useState(false)
  const [donationModal, setDonationModal] = useState(false)

  useEffect(() => {
    // Has a bank account already
    if (data?.bank_account_id) {
      getBankAccount(data.bank_account_id).then(result => {
        setBankAccount({ ...result.data })
      })
    }
    // Has a wallet
    if (data?.wallet_id) {
      getCivWallet(data.wallet_id).then(result => {
        setWallet({ ...result.data })
      })
      getAdminFee().then(result => {
        const resAdminFee = result.data.current_fees.civillos_fee
        setAdminFee(resAdminFee)
        setCurrentFee(feeValue(civAmount, resAdminFee))
      })
    }
    setBankAccountAttributes({ ...data?.bank_account_attributes })
  }, [data])

  const saveCivAmount = async () => {
    const params = {
      amount: civAmount,
      admin_fee_id: adminFee.id,
      admin_fee_amount: currentFee.toFixed(2),
    }
    const response = await updateCivWallet(data?.wallet_id, params)
    setCivSaveStatus(response)
    // Update balance
    if (response.status === 200) {
      setWallet({ ...wallet, balance: response.data.cached_balance })
    }
  }

  return (
    <>
      <a name="wallet" />
      <div className="container">
        <div className="row">
          <h1 className="badge-container">
            <Icon name="wallet" className="business-title-icon" />
            {t('civ_wallet.title')}
            <Badge />
          </h1>
          <h6 className="block-description">
            <Trans i18nKey="civ_wallet.description" />
          </h6>
        </div>

        <div className="row form-row space-title">
          {origin !== 'user' ? (
            <div className="form-group col-md-4 text-center">
              <FundingCard
                bankAccount={bankAccount}
                data={data}
                onClick={() => setFundingModal(true)}
              />
            </div>
          ) : null}
          {origin === 'user' ? (
            <div className="form-group col-md-4">
              <BalanceCard
                origin={origin}
                wallet={wallet}
                data={data}
                onClick={
                  origin === 'user'
                    ? () => setDonationModal(true)
                    : () => setBalanceModal(true)
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      <FundingModal
        bankAccountAttributes={bankAccountAttributes}
        bankAccountId={data?.bank_account_id}
        civAmount={civAmount}
        data={data}
        locale={locale}
        onHide={() => setFundingModal(false)}
        onSave={setData}
        origin={origin}
        setBankAccountAttributes={setBankAccountAttributes}
        show={fundingModal}
        updateData={updateData}
      />
      <BalanceModal
        show={balanceModal}
        onHide={() => {
          setCivAmount()
          setCivSaveStatus(null)
          setBalanceModal(false)
        }}
        onSave={saveCivAmount}
        civSaveStatus={civSaveStatus}
        civAmount={civAmount}
        setCivAmount={setCivAmount}
        currentFee={currentFee}
        setCurrentFee={setCurrentFee}
        adminFee={adminFee}
        bankAccountNumberLast4={bankAccount?.bank_account_number_last4}
      />
      <DonationModal
        walletId={data?.wallet_id}
        onHide={() => setDonationModal(false)}
        onSave={setWallet}
        show={donationModal}
      />
    </>
  )
}
