import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'

import EngagementCard from '../../components/engagement-card'
import Loading from '../../components/loading'
import MobilePlugs from '../../components/mobile-plugs'
import useApi from '../../hooks/useApi'
import { sortObjectsByDate } from '../../lib/sort'
import { useCurrentUser } from '../../hooks/useCurrentUser'

export default function EngagementsPage() {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { getUserEngagementEvents } = useApi()

  const [engagements, setEngagements] = useState(null)
  const [currentEngagements, setCurrentEngagements] = useState(null)
  const [pastEngagements, setPastEngagements] = useState(null)
  const [fetched, setFetched] = useState(false)

  const fetchData = useCallback(async () => {
    const fetchEngagements = await getUserEngagementEvents(currentUser)
    const fetchEngagementsPast = await getUserEngagementEvents(
      currentUser,
      true,
    )
    setFetched(true)
    setCurrentEngagements(
      sortObjectsByDate([...fetchEngagements.data], 'event_start_date'),
    )
    setPastEngagements(
      sortObjectsByDate([...fetchEngagementsPast.data], 'event_end_date', true),
    )
  }, [currentUser, getUserEngagementEvents])

  useEffect(() => {
    fetchData()
  }, [])

  if (!fetched) return <Loading show />

  if (
    (!currentEngagements && !pastEngagements) ||
    (currentEngagements?.length === 0 && pastEngagements?.length === 0)
  ) {
    return <MobilePlugs />
  }

  return (
    <Container>
      <Col>
        {currentEngagements?.length ? (
          <>
            <h1>{t('engagements.my')}</h1>
            <Row>
              {currentEngagements.map(engagement => {
                return (
                  <EngagementCard
                    key={`engagement card ${engagement.id}`}
                    engagement={engagement}
                  />
                )
              })}
            </Row>
          </>
        ) : null}
        {pastEngagements?.length ? (
          <>
            <h1>{t('engagements.past')}</h1>
            <Row>
              {pastEngagements.map(engagement => {
                return (
                  <EngagementCard
                    key={`engagement past card ${engagement.id}`}
                    engagement={engagement}
                  />
                )
              })}
            </Row>
          </>
        ) : null}
      </Col>
    </Container>
  )
}
