import React from 'react'
import { numK } from '../../lib/numbers'

export default function StatNumber({ statNum, statType }) {
  return (
    <>
      <div className="display-3 numberValue">{numK(statNum)}</div>
      {statType}
    </>
  )
}
