import AccountSidebar from './sidebar'
import NewAccountForm from '../../components/Login/NewAccountForm'

export default function AccountNewPage() {
  return (
    <div className="container" id="loginForm">
      <div className="row" style={{ paddingBottom: '60px' }}>
        <div className="col-sm-6">
          <AccountSidebar />
        </div>
        <div className="col-sm-4 offset-sm-1" style={{ paddingTop: '60px' }}>
          <NewAccountForm />
        </div>
      </div>
    </div>
  )
}
