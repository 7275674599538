import { Modal } from 'react-bootstrap'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// import AddCivModal from '../CivWallet/AddCivModal'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import AddCivForm from '../CivWallet/AddCivForm'
import Button from '../button'
import Icon from '../icon'
import PledgeHeader from './PledgeHeader'
import SponsorMessageForm from './SponsorMessageForm'
import SponsorPledgeForm from './SponsorPledgeForm'
import styles from '../../pages/events/sponsorship/sponsorship.module.scss'
import useApi from '../../hooks/useApi'
import AddFundingSourceForm from '../CivWallet/AddFundingSourceForm'
import civ from '../../constants/civ'
import { feeValue, formatAmount } from '../../lib/numbers'

interface SponsorPledgeModalProps {
  event?: any
  onClose: () => void
  pledgeAmount?: string
  show: boolean
}

type Step = 'pledge' | 'message' | 'success' | 'addCiv' | 'addFundingSource'

export default function SponsorPledgeModal(props: SponsorPledgeModalProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { business, fetchBusiness } = useCurrentUser()
  const { createSponsorshipPledge, getAdminFee } = useApi()

  const [pledgeAmount, setPledgeAmount] = useState<string>(
    props.pledgeAmount as string,
  )
  const [step, setStep] = useState<Step>('pledge')

  const [adminFee, setAdminFee] = useState(civ.empty)
  const [currentFee, setCurrentFee] = useState(0)
  const [civMissing, setCivMissing] = useState('0')

  const onSubmit = useCallback(
    async (message: string) => {
      const amount = parseFloat(pledgeAmount)
      const pledgeData = {
        event_id: props.event?.id,
        civ_pledge: {
          amount,
          message_to_event_owner: message,
        },
      }
      const response = await createSponsorshipPledge(pledgeData)
      const error =
        //@ts-ignore
        response?.error?.response?.data?.error || response?.error?.message
      if (error) {
        alert(error)
      } else {
        setStep('success')
      }
    },
    [pledgeAmount, props.event],
  )

  const closeModal = useCallback(() => {
    setStep('pledge')
    setPledgeAmount('')
    props.onClose()
  }, [props.onClose])

  const handleOnClose = useCallback(() => {
    if (step === 'success') {
      closeModal()
    } else {
      const confirm = window.confirm(
        t('sponsorship.pledgeModal.confirmCloseMessage'),
      )
      if (confirm) {
        closeModal()
      }
    }
  }, [props.onClose, step])

  useEffect(() => {
    if (step === 'pledge') {
      fetchBusiness()
    }
  }, [step])

  useEffect(() => {
    getAdminFee().then(result => {
      setAdminFee(result.data.current_fees.meta_fee)
    })
  }, [])

  useEffect(() => {
    if (!!adminFee) {
      setCurrentFee(feeValue(pledgeAmount, adminFee))
    }
  }, [pledgeAmount, adminFee])

  return (
    <Modal
      onHide={handleOnClose}
      show={props.show}
      contentClassName={styles.modalBackground}
    >
      {/* // style={{ width: '550px', height: '850px' }} */}
      <Modal.Header closeButton style={{ border: 'none' }}></Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        {step === 'pledge' ? (
          <SponsorPledgeForm
            business={business}
            event={props.event}
            onNext={() => setStep('message')}
            onNextPurchaseCiv={() => setStep('addCiv')}
            onNextAddFundingSource={() => setStep('addFundingSource')}
            pledgeAmount={pledgeAmount}
            setPledgeAmount={setPledgeAmount}
            setCivMissing={setCivMissing}
          />
        ) : null}
        {step === 'addFundingSource' ? (
          <AddFundingSourceForm
            // bankAccountId={business?.bank_account_id}
            // walletId={business?.wallet_id}
            businessId={business?.id}
            onSave={() => setStep('pledge')}
            onHide={() => setStep('pledge')}
          />
        ) : null}
        {step === 'addCiv' ? (
          <AddCivForm
            amount={civMissing}
            bankAccountId={business?.bank_account_id}
            onHide={() => setStep('pledge')}
            onSave={() => setStep('pledge')}
            walletId={business?.wallet_id}
          />
        ) : null}
        {step === 'message' ? (
          <SponsorMessageForm
            event={props.event}
            onSubmit={onSubmit}
            pledgeAmount={pledgeAmount}
            currentFee={currentFee}
          />
        ) : null}

        {step === 'success' ? (
          <div className={styles.section}>
            <div className="mb-3">
              <h2>
                <b>{t('sponsorship.pledgeModal.successTitle')}</b>
              </h2>
            </div>
            <div className={styles.successIconContainer}>
              <Icon name="check-circle" />
            </div>
            <div className="mb-3">{moment().format('MMMM Do, YYYY')}</div>
            <div className="mb-3">Sent to: {props.event.user.name}</div>

            <div className="mb-3">
              <PledgeHeader event={props.event} pledgeAmount={pledgeAmount} />
            </div>

            <div className="mb-3">
              <div className={styles.terms}>
                {t('sponsorship.pledgeModal.successFinePrint', {
                  service_fee: formatAmount(currentFee, true),
                })}
              </div>
            </div>

            <div className="mb-3">
              <Button
                onClick={() => {
                  navigate('/dashboard')
                }}
                className="w-100 mb-4"
              >
                {t('sponsorship.pledgeModal.goToDashboard')}
              </Button>
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  )
}
