import React, { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Badge from '../../../components/badge'
import Loading from '../../../components/loading'
import useApi from '../../../hooks/useApi'
import Disbursement from './disbursement'

export default function DashboardCharity() {
  const { t } = useTranslation()

  const { getUserCharityRegistrations } = useApi()

  const [charities, setCharities] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    // Get latest user data
    const { data } = await getUserCharityRegistrations()
    if (data) {
      setCharities(data.filter(d => d.verified && d.status === 'Verified'))
    }
    setLoading(false)
  }, [getUserCharityRegistrations])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="mb-5">
      <Loading show={loading} />
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1 badge-container">
            {t('charities.dashboard.title')}
            <Badge />
          </h1>
          <h6 className="block-description">
            {t('charities.dashboard.description')}&nbsp;
            {/* <a target="_blank" href={t('charities.dashboard.learnMore.link')}>
              {t('charities.dashboard.learnMore.text')}
            </a> */}
          </h6>
        </div>
      </div>
      {charities?.map(charity => (
        <Disbursement charity={charity} key={`charity-${charity.id}`} />
      ))}
    </div>
  )
}
