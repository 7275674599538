import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import GroupCard from '../../components/group-card'
import Loading from '../../components/loading'
import MobilePlugs from '../../components/mobile-plugs'
import useGroups from '../../hooks/useGroups'

export default function GroupsNav() {
  const { allGroups, fetchAllGroups, initialLoad } = useGroups()
  const { t } = useTranslation()

  useEffect(() => {
    fetchAllGroups()
  }, [])

  if (initialLoad) return <Loading show />
  if (!allGroups.length) return <MobilePlugs />

  return (
    <Container>
      <h1>{t('event.associated_groups')}</h1>
      <Row>
        {allGroups?.map(group => (
          <Col md="4" key={group.id}>
            <GroupCard group={group} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}
