import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { removeAllState } from '../../lib/localstorage'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import AccountSidebar from './sidebar'
import Loading from '../../components/loading'
import LoginForm from '../../components/Login/LoginForm'
import useApi from '../../hooks/useApi'
import useFacebook from '../../hooks/useFacebook'

export default function LoginPage({ logout }) {
  const { logout: logoutFromFacebook } = useFacebook()
  const { signOut } = useCurrentUser()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleLogOut = async () => {
      setIsLoading(true)
      logoutFromFacebook()
      await signOut()
      removeAllState()
      window.location = '/login'
      setIsLoading(false)
    }
    if (logout) {
      handleLogOut()
    }
  }, [logout])

  return (
    <div className="container" id="loginForm">
      <div className="row" style={{ paddingBottom: '60px' }}>
        <div className="col-sm-6">
          <AccountSidebar />
        </div>
        <div className="col-sm-4 offset-sm-1" style={{ paddingTop: '60px' }}>
          <LoginForm />
        </div>
      </div>
      {isLoading && <Loading show={isLoading} />}
    </div>
  )
}

LoginPage.propTypes = {
  logout: PropTypes.bool,
}

LoginPage.defaultProps = {
  logout: false,
}
