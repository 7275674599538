import React, { useEffect, useRef, useState } from 'react'

const Recaptcha = ({ sitekey, callback }) => {
  const recaptchaRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const onRecaptchaLoad = () => {
    setIsLoaded(true)
  }
  useEffect(() => {
    window.onRecaptchaLoad = onRecaptchaLoad
    if (!window.grecaptcha) {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    } else if (window.grecaptcha && window.grecaptcha.render) {
      setIsLoaded(true)
    }
    return () => {
      window.onRecaptchaLoad = null
    }
  }, [])

  useEffect(() => {
    if (isLoaded) {
      window.grecaptcha.render(recaptchaRef.current, {
        sitekey,
        callback,
        'expired-callback': callback,
      })
    }
  }, [isLoaded])

  return <div ref={recaptchaRef}></div>
}

export default Recaptcha
