import moment from 'moment'

export function sortObjectsAlphabetically(array, key) {
  return array?.sort((a, b) => a?.[key]?.localeCompare(b?.[key] ?? ''))
}

export function sortCategoriesAlphabetically(array, locale) {
  return array?.sort((a, b) => {
    if (locale === 'es') {
      return a?.es_name?.localeCompare(b?.es_name ?? '')
    }
    return a?.en_name?.localeCompare(b?.en_name ?? '')
  })
}

export function sortObjectsByDate(array, key, desc = false) {
  if (desc) {
    return array?.sort((a, b) => {
      return moment(a?.[key]).isBefore(moment(b?.[key])) ? 1 : -1
    })
  }
  return array?.sort((a, b) => {
    return moment(a?.[key]).isBefore(moment(b?.[key])) ? -1 : 1
  })
}

export function groupIntoBlocks(arr, blockSize) {
  let result = []
  for (let i = 0; i < arr.length; i += blockSize) {
    result.push(arr.slice(i, i + blockSize))
  }
  return result
}

export function sortObjectsByNumber(array, key, desc = false) {
  if (desc) {
    return array?.sort((a, b) => {
      return a?.[key] - b?.[key]
    })
  }
  return array?.sort((a, b) => {
    return b?.[key] - a?.[key]
  })
}

export default {
  sortCategoriesAlphabetically,
  sortObjectsAlphabetically,
  sortObjectsByDate,
  groupIntoBlocks,
  sortObjectsByNumber,
}
