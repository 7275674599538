import React, { useEffect, useState } from 'react'

import FeedPage from './feed'
import MyPosts from './my-posts'
import AddBusinessAccountModal from '../components/AddBusinessAccountModal/AddBusinessAccountModal'
import { useCurrentUser } from '../hooks/useCurrentUser'
import useGroups from '../hooks/useGroups'
import { restoreState } from '../lib/localstorage'

export default function HomePage() {
  const { currentUser } = useCurrentUser()
  const { fetchAllGroups } = useGroups()

  const [
    isAddBusinessAccountModalVisible,
    setIsAddBusinessAccountModalVisible,
  ] = useState(() => {
    return (
      !currentUser?.has_business &&
      !localStorage.getItem('new-account') &&
      restoreState('show-add-business-account-modal', true)
    )
  })

  useEffect(() => {
    // Preload additional data
    fetchAllGroups()
  }, [])

  return (
    <>
      <FeedPage />
      <AddBusinessAccountModal
        onHide={() => setIsAddBusinessAccountModalVisible(false)}
        // show={isAddBusinessAccountModalVisible}
        show={false}
      />
    </>
  )
}
